import './moncss.css';
import React,  {Component} from 'react';
import { Button} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Redirect  } from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import Fade from 'react-reveal/Fade';
import { isBrowser} from "react-device-detect";
import queryString from 'query-string';
import Loader from "react-js-loader";
import * as ls from "local-storage";
import {API_URL} from '../constants';

var errocode = 0;

class Login extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      lienimage: process.env.PUBLIC_URL +'images/',
      selectedFile: null,
      dureesound:'',
      selectedOption: null,
      listsound: [],
      persons: [],
      speakers: [],
      selectedValue: "",
      diarizer: [],
      usertype:0,
      nom: '',
      prenoms: '',
      mail: '',
      motdepasse: '',
      societe: 0,
      telephone: '',
      poste: '',
      errorform: false,
      errormessage:'',
      signin : false,
      tokenuser:[],
      isModalthematique: false,
      isModalspeaker: false,
      speaker1: '',
      speaker2: '',
      idaudioselect: 0,
      thematique:'',
      show_input:'text',
      tokenexist: true,
      tokenvalue: '',
      reload: false,
      loadershow : false,
      inscription:false,
      resetpassword:false,
      resetpasswordsuccess:false,


    };

    this.selectusertype = this.selectusertype.bind(this);
    this.selectsociety = this.selectsociety.bind(this);
        
  }

    componentDidMount() {

      document.title = "Se connecter";

     
  
       
    };

    componentWillMount() {

      var getuser = ls.get('type')

      if(getuser !== null){
        this.setState({signin : true})
      }

      window.scrollTo(0, 0);

    }

   
  

    refreshdata () {

      if (this.state.reload === false){

        window.location.reload();
        this.setState({ reload: true,signin : true,})


      }
            

    }

    verifyuser () {

        if (this.state.mail == "" ) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

              this.setState({ errorform: true, errormessage: 'Mail invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.',loadershow: false  });
                
                return;

            }
            
        }
        
        if (this.state.motdepasse == "") {

          this.setState({ errorform: true, errormessage: 'Mot de passe requis. Veuillez saisir votre mot de passe.',loadershow: false });
                            
                return;

        }

        this.setState({ loadershow: true });

        //this.getdatauser()

        this.connexionuser()

  }; 


  connexionuser(){
      
      fetch(`${API_URL}/utilisateur/login.php`,{                  
    
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          mailutilisateur:this.state.mail,
          motdepasseutilisateur:this.state.motdepasse,
        })

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'Usernotfound'){
      this.setState({ errorform: true, errormessage: 'Utilisateur non reconnu.',loadershow: false  });
      return;
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Errorpassword'){
      this.setState({ errorform: true, errormessage: 'Mot de passe invalide.',loadershow: false  });
      return;
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
      this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.',loadershow: false  });
      return;
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Usernotactive'){
      this.setState({ errorform: true, errormessage: 'Utilisateur en attente d\'activation.',loadershow: false  });
      return;
      }

      const token = JSON.stringify(data).replace(/\"/g, "");

      ls.set('token', token);

      this.setState({
        loadershow: true,
        tokenuser: data,
        errorform: false,
        errormessage:'',             
        signin: true,

      });   
      
      document.location.href = '/Accueil';

      })
      .catch(err => {
        this.setState({ errorform: true, errormessage: err,loadershow: false });
        //alert("Erreur :" + errocode + ", "+err)
        this.setState({ tokenuser: err});
      });  

  }

  selectusertype(e) {
    this.setState({ usertype: e.target.value });
  }

  selectsociety(e) {
    this.setState({ societe: e.target.value });
  }

  inscriptionuser(){
    this.setState({ inscription:true});
  }

  resetpasswordshow(){

    this.setState({
      resetpassword: true,
    });  

  }

  resetpasswordhide(){

    this.setState({
      loadershow: false,
      errorform: false,
      errormessage:'',      
      resetpassword: false,
      resetpasswordsuccess:false,
    });  

  }

  resetpassworduser(){

    this.setState({ loadershow: true});

    if(this.state.mail === ""){
      this.setState({ errorform: true, errormessage: 'Veuillez saisir votre adresse mail.',loadershow: false });
      return;
    }
      
    fetch(`${API_URL}/utilisateur/resetpassword.php`,{                  
            
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        mailutilisateur:this.state.mail,
      })

    })

    .then(response => response.json())
    .then(data => {

    //alert(JSON.stringify(data).replace(/\"/g, ""))

    if(JSON.stringify(data).replace(/"/g, "") === 'User not found'){

      this.setState({ errorform: true, errormessage: 'Utilisateur non reconnu.',loadershow: false });
      return;
      
    }

    

    this.setState({

      loadershow: false,
      errorform: false,
      errormessage:'',             
      resetpasswordsuccess:true,
      resetpassword:false,

    });              

    })
    .catch(error => {
        this.setState({ tokenuser: error});
    });  

  }
   
  render() {

          return (
              
            <div style={{
              backgroundImage: "url(" + this.state.lienimage + "fond-connexion.png" + ")",
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              marginTop:0,
              paddingTop:"100px",
              paddingBottom:"100px"
            }} >
                  <Fade Bottom cascade duration={3000}>
                    
                    <Card style={{ width: isBrowser?'50%':"90%", minHeight: isBrowser ? '50%' : 'auto', borderRadius:'25px', marginLeft:isBrowser?'25%':'5%' , marginRight:isBrowser?'25%':'5%', marginTop:"5%"}} className="cardlistnounou">                                               
                                                                                          
                      <Card.Body >
                                              
                          
                          <p style={{textAlign:"center", color:"#8000FF", fontSize:"16px"}}>CONNEXION</p>

                          <p style={{textAlign:"center", color:"black", fontSize:isBrowser?"36px":"px"}}>Nous sommes heureux de vous revoir !</p>

                          {this.state.resetpasswordsuccess === false ? 

                          
                          <div>

                              <div className="form-group d-flex justify-content-center" style={{marginTop:'20px'}}>
                                <input type="email"  style={{color:"rgba(0, 0, 0, 0.35)", width:isBrowser?"60%":"100%", height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Email" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                              </div>

                              {this.state.resetpassword === false ?

                                <div className="form-group" style={{margin:'5px'}}>
                                 <div className="form-group d-flex justify-content-center" style={{marginTop:'20px'}}>
                                  <input type="password"  style={{color:"rgba(0, 0, 0, 0.35)", width:isBrowser?"60%":"100%", height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Mot de passe" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                                </div>

                                  <div className="container d-flex justify-content-center">

                                    <div className="row ">

                                      <div className="col-sm" onClick={() => this.resetpasswordshow()}>
                                        <a className="d-flex justify-content-center" style={{marginTop:isBrowser?"20%":"20px", color:"#2A83E8"}}>Mot de passe oublié</a>
                                      </div>

                                      <div className="col">
                                        <div className="d-flex justify-content-center">
                                          <Button className="btn" style={{
                                                  background: '#FF7E5A',
                                                  border: 0,
                                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                                  height: 48,
                                                  width:isBrowser?250:"100%",
                                                  borderRadius:'8px',
                                                    marginTop:'20px',
                                                    marginBottom:'50px',
                                                    
                                                    }} onClick={() => this.verifyuser()} >Se connecter</Button>
                                        </div> 
                                      </div>

                                    </div>

                                  </div>

                                </div>

                                :

                                <div className="container d-flex justify-content-center">

                                  <div className="row">

                                    <div className="col " onClick={() => this.resetpasswordhide()}>
                                      <a className="d-flex justify-content-center" style={{marginTop:isBrowser?"55%":"20px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>Annuler</a>
                                    </div>

                                    <div className="col">
                                      <div className="d-flex justify-content-center">
                                        <Button className="btn" style={{
                                                  background: '#FF7E5A',
                                                  border: 0,
                                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                                  height: 48,
                                                  width:250,
                                                  borderRadius:'8px',
                                                    marginTop:'20px',
                                                    marginBottom:'50px',
                                                    
                                                    }} onClick={() => this.resetpassworduser()} >Réinitialiser le mot de passe</Button>
                                      </div> 
                                    </div>
                                  
                                  </div>

                                </div>
                                
                              }
                              
                              {this.state.signin == true ?
                                  

                                  <Redirect push to="/Login"/>
                                    

                                  :

                                  null

                              }
                          
                          </div>

                          

                          :

                          <div>

                              <div>

                                <p className="d-flex justify-content-center" style={{marginTop:"20px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>Votre mot de passe a été réinitialsé. Vous allez recevoir un mail avec le nouveau mot de passe.</p>

                                <div className="d-flex justify-content-center">
                                  <Button className="btn" style={{
                                                  background: '#FF7E5A',
                                                  border: 0,
                                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                                  height: 48,
                                                  width:250,
                                                  borderRadius:'8px',
                                                    marginTop:'20px',
                                                    marginBottom:'50px',
                                                    
                                                    }} onClick={() => this.resetpasswordhide()} >Se connecter</Button>
                                </div>
                                
                              </div> 

                          </div>

                        }
















                          {/*
                          
                          
                          <div className="form-group d-flex justify-content-center" style={{marginTop:'20px'}}>
                            <input type="email"  style={{color:"rgba(0, 0, 0, 0.35)", width:isBrowser?"60%":"100%", height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Email" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                          </div>

                          <div className="form-group d-flex justify-content-center" style={{marginTop:'20px'}}>
                            <input type="password"  style={{color:"rgba(0, 0, 0, 0.35)", width:isBrowser?"60%":"100%", height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Mot de passe" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                          </div>

                          */}

                          {this.state.errorform == true ? 

                          <Alert severity="error" style={{marginTop:'20px', width:isBrowser?"60%":"100%", marginLeft:isBrowser?"20%":"0%",marginRight:isBrowser?"20%":"0%"}}>
                            <AlertTitle>Erreur</AlertTitle>
                            {this.state.errormessage}
                          </Alert>

                          :

                          null

                          }

                          {this.state.signin == true ?
                            

                            <Redirect push to="/Accueil"/>
                              

                            :

                            null

                          }

                          {this.state.inscription == true ?
                            

                            <Redirect push to="/inscription"/>
                              

                            :

                            null

                          }

                          {this.state.loadershow == true ?
                            

                            <Loader type="spinner-cub" bgColor={'#FF7E5A'} title={"Chargement en cours ..."} color={'#FF7E5A'} size={100} />
                              

                            :

                            null

                          }

                          {/*
                          <div className="d-flex justify-content-center">
                          <Button style={{
                                                  background: '#FF7E5A',
                                                  border: 0,
                                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                                  height: 48,
                                                  width:isBrowser?"60%":"100%",
                                                  borderRadius:'8px',
                                                    marginTop:'20px',
                                                    marginBottom:'50px',
                                                    
                                                    }} onClick={() => this.verifyuser()} >Se connecter</Button>

                          

                          </div> 
                          */}

                          <p style={{color:"rgba(0, 0, 0, 0.35)", fontSize:'18px', textAlign:"center"}}>Vous n'avez pas de compte? <a href="/inscription">S'inscrire</a></p>



                              
                          
                        </Card.Body>

                      </Card>   

                      </Fade>  

                </div>

          );

        }
    
}

export default Login;
