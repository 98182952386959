
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './moncss.css';
import { Button,Modal} from 'react-bootstrap';
import { convertToRaw, EditorState, Editor,ContentState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { stateToHTML } from "draft-js-export-html";
import draftToHtmlPuri from "draftjs-to-html";
import * as ls from "local-storage";
import { isExpired, decodeToken}from "react-jwt";
import {API_URL} from '../constants';

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;

class QCMADMIN extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        editorState: EditorState.createEmpty(),
        selectedFile: null,
        selectedOption: null,
        listuser: [],
        persons: [],
        listtypeuser: [],
        listqcm: [],
        idqcmupdate:0,
        idqcmtext: '',
        modalupdate: false,
        modaldelete: false,
        usertype:'',
        societe:'',
        editorContentHtml:'',
      };

      
      this.selectsociety = this.selectsociety.bind(this);
      this.selectusertype = this.selectusertype.bind(this);
        
  }

  componentWillMount() {

    document.title = "Liste des QCM";

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[2] !== 'admin'){

      this.setState({ tokenvalue: ''})
      return
  
    }else{
  
      this.setState({ tokenvalue: userdata[2]})
  
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    document.title = "Liste des QCM";

    const responseqcmlist = await fetch(`${API_URL}/qcm/Selectallqcm.php`);
    const dataqcm = await responseqcmlist.json();
    
    if(JSON.stringify(dataqcm).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listqcm: dataqcm})
    }  

}  

showaddqcm(){

  this.setState({
    idqcmupdate: 0,
    qcmtext:'',
    modaladd: true
  })

}

addqcm(htmlPuri){

  this.testtoken();

  const token = ls.get('token');

    fetch(`${API_URL}/qcm/ajoutqcm.php`,{                  
        
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
        body: queryString.stringify({
          QCM: htmlPuri,
        })

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'NOT'){
      this.setState({ errorform: true, errormessage: 'Utilisateur non reconnu' });
      return;
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
      this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
      return;
      }

      this.setState({
        idqcmupdate: 0,
        qcmtext: '',
        modaladd: false
      })

      this.refreshdata();

      })
      .catch(err => {
        alert("Erreur :" + errocode + ", "+err)
        this.setState({ tokenuser: err});
      }); 

      document.title = "Liste des QCM";

}

showupdateqcm(idqcm,qcmtext){

  const { editorState } = this.state;
    this.onChange(EditorState.push(editorState, convertFromHTML(qcmtext)));

  this.setState({
    idqcmupdate: idqcm,
    qcmtext:qcmtext,
    modalupdate: true
  })

}

updateqcm(htmlPuri){

  this.testtoken();

  const token = ls.get('token');

    fetch(`${API_URL}/qcm/modifqcm.php`,{                  
        
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
        body: queryString.stringify({
          idqcm: this.state.idqcmupdate,
          Qcm: htmlPuri,
        })

      })

      .then(response => response.json())
      .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'NOT'){
      this.setState({ errorform: true, errormessage: 'Utilisateur non reconnu' });
      return;
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
      this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
      return;
      }

      this.setState({
        idqcmupdate: 0,
        qcmtext: '',
        modalupdate: false
      })

      this.refreshdata();

      })
      .catch(error => {
        this.setState({ tokenuser: error});
      });  

      document.title = "Liste des QCM";

}

showdeleteqcm(idqcm,qcmtext){
  
  this.setState({
    idqcmupdate:idqcm,
    qcmtext: qcmtext,
    modaldelete: true
  })

}

deleteqcm(){

  this.testtoken();

  const token = ls.get('token');

  fetch(`${API_URL}/qcm/supprimerqcm.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      body: queryString.stringify({
        idqcm: this.state.idqcmupdate,
      })

    })

    .then(response => response.json())
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'NOT'){
    this.setState({ errorform: true, errormessage: 'QCM non reconnu' });
    return;
    }

    if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
    this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
    return;
    }

    this.setState({
      idqcmupdate: 0,
      qcmtext: '',
      modaldelete: false
    })

    this.refreshdata();

    })
    .catch(error => {
      this.setState({ tokenuser: error});
    });  

    document.title = "Liste des QCM";

}


async refreshdata(){

  this.testtoken();
  
  const responseqcmlist = await fetch(`${API_URL}/qcm/Selectallqcm.php`);
  const dataqcm = await responseqcmlist.json();

  if(JSON.stringify(dataqcm).replace(/\"/g, "") !== 'NOT'){
    this.setState({ listqcm: dataqcm})
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState });
  }else{
    this.setState({ listqcm: []})
  }

  document.title = "Liste des QCM";

}

selectusertype(e) {
  this.setState({ usertype: e.target.value });
}

selectsociety(e) {
  this.setState({ societe: e.target.value });
}

onEditorStateChange = (editorState) => {
    
  this.setState({
    editorState
  });

  this.setState({
    texthtml:convertToHTML(editorState.getCurrentContent()),
  });

};


onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}

testtoken(){
      
  let token = ls.get('token');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    alert("Session expiré! Veuillez vous reconnecter.")
    ls.clear()
    this.setState({ typeuser: null})   
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    alert("Session expiré! Veuillez vous reconnecter.")
    ls.clear()
    this.setState({ typeuser: null}) 
    document.location.href = '/login';
    return;
  }

}

  
  render() {


    const htmlPuri = draftToHtmlPuri(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
  

          return (
              
                <div className="App" >
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }
                    
                    {this.state.modaladd === true ?

                    <Modal

                    show={this.state.modaladd}
                    onHide={ () => this.setState({modaladd:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>

                      <div className="d-flex flex-row justify-content-center">
                                      
                                      <div className="d-flex flex-column" style={{margin:"20px"}}>
                                          
                                          <div className="d-flex flex-column align-items-center">
                                              
                                              <label>Question à Ajouter :</label>
                                              <form>
                                              <div className="form-group" style={{margin:'10px'}}>
                                                
                                                <div className="main">
                                                  <Editor
                                                    ClassName="editorstyle"
                                                    editorState={this.state.editorState}
                                                    onChange={this.onChange}
                                                  />                              
                                                
                                                </div>
              
                                              </div>
               
                                            </form>
              
                                            <label>Question Ajoutée :</label>
                                            <div  style={{maxWidth:"50vh"}} dangerouslySetInnerHTML={{ __html: htmlPuri}}></div>
              
                                            <div className='row d-flex align-items-center'>
                                                          
                                            <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modaladd:false})} variant="primary">Fermer</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.addqcm(htmlPuri)} variant="success">Ajouter</Button>
                                                  
                                            </div>
              
                                          </div>
                                          
                                      </div>
              
                                    </div>    
              
                                  
                      

                    </Modal.Body>
                    </Modal>

                    :

                    null

                    }

                  
                  {this.state.modalupdate === true ?

                  <Modal
                  
                  show={this.state.modalupdate}
                  onHide={ () => this.setState({modalupdate:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column align-items-center">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px" , maxWidth:"50vh"}} >Original : {this.state.qcmtext}&nbsp;</p>
                                <label>Question à modifier :</label>
                                <form>
                                <div className="form-group" style={{margin:'10px'}}>
                                  
                                  <div className="main">
                                    <Editor
                                      ClassName="editorstyle"
                                      editorState={this.state.editorState}
                                      onChange={this.onChange}
                                    />                              
                                  
                                  </div>

                                </div>
 
                              </form>

                              <label>Question modifiée :</label>
                              <div  style={{maxWidth:"50vh"}} dangerouslySetInnerHTML={{ __html: htmlPuri}}></div>

                              <div className='row d-flex align-items-center'>
                                            
                                  <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">Fermer</Button>
                                  <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.updateqcm(htmlPuri)} variant="success">Mettre à jour</Button>
                                    
                              </div>

                            </div>
                            
                        </div>

                      </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Voulez-vous supprimer &nbsp;</p>
                                <div style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}} dangerouslySetInnerHTML={{ __html: this.state.qcmtext}}></div>
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Fermer</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.deleteqcm()} variant="danger">Supprimer</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }
                  
                  <Button className="btn"style={{ margin:"20px", width:'150px',marginTop:'100px', borderRadius:'25px'}} onClick={ () => this.setState({modaladd:true})} variant="primary">Ajouter</Button>

                  <div className='centrervue'>

                  {this.state.listqcm.length>0 ?
                    
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              
                              <TableCell >Question</TableCell>
                              <TableCell >Modifier</TableCell>
                              <TableCell >Supprimer</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.listqcm.map((row) => (

                              <TableRow key={row.idqcm}>
                               
                                <TableCell dangerouslySetInnerHTML={{ __html: row.qcm }}></TableCell>
                                
                                <TableCell ><button type="submit" className="btn btn-success btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showupdateqcm(row.idqcm,row.qcm)}><span><EditIcon /></span></button></TableCell>
                                
                                <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showdeleteqcm(row.idqcm,row.qcm)}><span><DeleteIcon /></span></button></TableCell>
                    
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                
                    :

                      <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                
                                <TableCell >Question</TableCell>
                                
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              

                                <TableRow key={0}>
                                  
                                  <TableCell >Veuillez ajouter des questions ...</TableCell>
                                  
                                </TableRow>
                             
                            </TableBody>
                          </Table>
                        </TableContainer>

                  }
                  
                  </div> 

                

                  <Button className="btn"style={{ margin:"20px", width:'150px',marginTop:'100px', borderRadius:'25px'}} onClick={ () => this.setState({tokenvalue:''})} variant="danger">Se déconnecter</Button>
               
                </div>
                
          );
        }
    
}

export default QCMADMIN;
