
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import queryString from 'query-string';
import './moncss.css';
import {Button,Modal} from 'react-bootstrap';
import * as ls from "local-storage";
import {Table, Thead, Tbody, Tr, Td} from 'react-super-responsive-table'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { isExpired, decodeToken }from "react-jwt";
import {API_URL} from '../constants';

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Listecse extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        dureesound:'',
        selectedOption: null,
        idtype : 0,
        iduserupdate : 0,
        activeuserupdate : 0,
        nomcse: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        listecse: [],
        idcse:0,
        liencse:'',
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        usertype:'',
        societe:'',
        editorContentHtml:'',
        liendocument: 'https://www.nannylou.com/mobilewbs/entreprise/',

      };

      
      this.selectsociety = this.selectsociety.bind(this);
      this.selectusertype = this.selectusertype.bind(this);
        
  }

  componentWillMount() {

    document.title = "Liste des CSE";

    let token = ls.get('token');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");
  
    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[2] !== 'admin'){

      this.setState({ tokenvalue: ''})
      return
  
    }else{
  
      this.setState({ tokenvalue: userdata[2]})
  
    }

    {/* 
    var idtype = ls.get('idtype');

    if(parseInt(idtype) !== 1425){
      this.setState({ tokenvalue: ''})
      return
    }else{

      this.setState({ tokenvalue: idtype})
      //this.reloaddata();
      
    }
    */}

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    document.title = "Liste des CSE";

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const responsecselist = await fetch(`${API_URL}/cse/selectallcse.php`,{                       
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      });
    const datacse= await responsecselist.json();
    
    if(JSON.stringify(datacse).replace(/\"/g, "") !== 'None'){
      this.setState({ listecse: datacse})
    }  

}  


addcse(){

  this.testtoken();

  if (this.state.nomcse === "") {

    this.setState({ errorform: true, errormessage: 'Nom CSE vide' });
                      
    return;

  }

  const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
  var CryptoJS = require("crypto-js");
  var liencsecrypto = CryptoJS.AES.encrypt(this.state.nomcse, key).toString();

  const token = ls.get('token');

  fetch(`${API_URL}/cse/addnewcse.php`,{                  
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      body: queryString.stringify({
        nomcse: this.state.nomcse,
        liencse: liencsecrypto.replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l'),
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'CSEfound'){
    this.setState({ errorform: true, errormessage: 'CSE existant.' });
    return;
    }

    if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
      this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
      return;
      }
  

    if(JSON.stringify(data).replace(/\"/g, "") === 'None'){
      this.setState({ listecse: []})
    }  

    if(JSON.stringify(data).replace(/\"/g, "") !== 'None'){
      this.setState({ listecse: data})
    }  

    this.setState({
      idcse: 0,
      nomcse: "",
      modaladd: false
    })

    this.refreshdata();

    })

    .catch(err => {
        alert("Erreur :" + errocode + ", "+err)
        this.setState({ tokenuser: err});
    });
    
    document.title = "Liste des CSE";

}

showdeletecse(idcse,nomcse){
  
  this.setState({
    idcse:idcse,
    nomcse: nomcse,
    modaldelete: true
  })

}

deletecse(){

  this.testtoken();

  const token = ls.get('token');

  fetch(`${API_URL}/cse/supprimercse.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      body: queryString.stringify({
        idcse: this.state.idcse,
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
    this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
    return;
    }

    if(JSON.stringify(data).replace(/\"/g, "") === 'None'){
      this.setState({ listecse: []})
    }  

    if(JSON.stringify(data).replace(/\"/g, "") !== 'None'){
      this.setState({ listecse: data})
    }  

    this.setState({
      idcse: 0,
      nomcse: "",
      modaldelete: false
    })

    this.refreshdata();

    })
    .catch(err => {
      alert("Erreur :" + errocode + ", "+err)
      this.setState({ tokenuser: err});
  }); 

    document.title = "Liste des CSE";

}


async refreshdata(){

  this.testtoken();
  
  const responseqcmlist = await fetch(`${API_URL}/qcm/Selectallqcm.php`);
  const dataqcm = await responseqcmlist.json();

  if(JSON.stringify(dataqcm).replace(/\"/g, "") !== 'NOT'){
    this.setState({ listqcm: dataqcm})
  }else{
    this.setState({ listqcm: []})
  }

  document.title = "Liste des CSE";

}

selectusertype(e) {
  this.setState({ usertype: e.target.value });
}

selectsociety(e) {
  this.setState({ societe: e.target.value });
}



onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}

testtoken(){
      
  let token = ls.get('token');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    alert("Session expiré! Veuillez vous reconnecter.")
    ls.clear()
    this.setState({ typeuser: null})   
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    alert("Session expiré! Veuillez vous reconnecter.")
    ls.clear()
    this.setState({ typeuser: null}) 
    document.location.href = '/login';
    return;
  }

}

  
  render() {  

          return (
              
                <div className="App" >
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    <Button className="btn"style={{ margin:"20px", width:'150px',marginTop:'120px', borderRadius:'25px'}} onClick={ () => this.setState({modaladd:true})} variant="success">Ajouter un CSE</Button>

                    {this.state.modaladd === true ?

                    <Modal

                    show={this.state.modaladd}
                    onHide={ () => this.setState({modaladd:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"20px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Nom du CSE &nbsp;</p>
                                  <input type="text"  style={{color:"rgba(0, 0, 0, 0.35)", width:"100%", height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Nom du CSE" value={this.state.nomcse} onChange={(e) => this.setState({ nomcse: e.target.value})} />
                                  
                                  {this.state.errorform == true ? 

                                    <Alert severity="error">
                                    <AlertTitle>Erreur</AlertTitle>
                                    {this.state.errormessage}
                                    </Alert>



                                    :

                                    null

                                    }

                                  <form>

                                  <div className='row text-white d-flex align-items-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modaladd:false})} variant="danger">Fermer</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.addcse()} variant="primary">Ajouter</Button>

                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>

                      </div>    

                    </Modal.Body>
                    </Modal>

                    :

                    null

                    }
                    
                   

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Voulez-vous supprimer &nbsp;</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.nomcse}?</p>
                                
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Fermer</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.deletecse()} variant="danger">Supprimer</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }
                 
                  <div className='centrervue' style={{ paddingTop:'20px'}}>

                  {this.state.listecse.length>0 ?
                    
                    
                        <Table>
                          <Thead>
                            <Tr>
                              
                              <Td >Nom</Td>
                              <Td >Lien</Td>
                              <Td style={{textAlign:"center"}}>Supprimer</Td>

                            </Tr>
                          </Thead>
                          <Tbody>

                            {this.state.listecse.map((row) => (

                              <Tr key={row.idcse} className="tableborder">   
                                <Td style={{textAlign:"left"}}>{row.nomcse}</Td>    
                                <Td style={{textAlign:"center"}}><button className="btn btn-primary btn-xl" onClick={() =>  navigator.clipboard.writeText('https://www.nannylou.com/cse/'+row.liencse)}>Copier le lien </button></Td>                     
                                <Td style={{textAlign:"center"}}><button type="submit" className="btn btn-danger btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showdeletecse(row.idcse,row.nomcse)}><span><DeleteIcon /></span></button></Td>
                    
                              </Tr>

                            ))}

                          </Tbody>
                        </Table>
                   
                
                    :

                    null

                  }
                  
                  </div> 

                </div>
                
          );
        }
    
}

export default Listecse;
