import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import { Form} from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Fade from 'react-reveal/Fade';
import { isBrowser} from "react-device-detect";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import queryString from 'query-string';



class Contact extends Component{
    
    constructor(props) {
    super(props);
    this.envoiemail = this.envoiemail.bind(this);
    this.state = {
              
        lienimage: process.env.PUBLIC_URL +'images/',
        nomcontact: '',
        telephonecontact:'',
        mailcontact: '',
        titrecontact: '',
        messagecontact: '',
        captcha:'',
        generatecaptcha:'',
        isModalVisible: false,
        editorState:'',
        montant:0.0,
        lng:'FR',
        titre:'',       
    };
        
  }
    
    componentDidMount() {

      document.title = "Contactez-nous"

      var chiffres = '0123456789';
      var min = "abcdefghijklmnopqrstuvwxyz";
      var maj = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var special = "$#";
      let pass = '';
      var len_chiffres = chiffres.length;
      var len_min = min.length;
      var len_maj = maj.length;
      var len_special = special.length;
    
      for (let i = 0; i < 2; i++ ) {
        pass += chiffres[Math.floor(Math.random() * len_chiffres)];
      }
    
      for (let i = 0; i < 3; i++ ) {
        pass += min[Math.floor(Math.random() * len_min)];
      }
    
      for (let i = 0; i < 3; i++ ) {
        pass += maj[Math.floor(Math.random() * len_maj)];
      }
    
      for (let i = 0; i < 2; i++ ) {
        pass += special[Math.floor(Math.random() * len_special)];
      }
    
      var melanger = pass.split('').sort(function(){return 0.5-Math.random()}).join('');

      this.setState({ generatecaptcha : melanger});
        
        if(this.state.lng==='FR'){
            this.setState({
                titre: 'Allô Nanny',
            });
        }else{
            this.setState({
                titre: 'Allô Nanny',
            });
        }
        
    };

    componentWillMount() {

      window.scrollTo(0, 0)

  };
   
    nouveauresa = () =>{
		this.setState({ isModalVisible: true });
	};
    
    envoiemail(evt) {
        
        evt.preventDefault();
                
        if (!this.refs.Nomsend.value) {

            alert("Veuillez saisir votre nom s'il vous plait.");
            
            return;

        }

        if (!this.refs.Telephonesend.value) {

          alert("Veuillez saisir votre numéro de téléphone s'il vous plait.");
          
          return;

        }

        if (typeof this.refs.Emailsend.value !== "undefined") {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(this.refs.Emailsend.value)) {

            alert("Veuillez saisir un mail valide s'il vous plait.");
            
            return;

        }
            
        }
            
        if (!this.refs.Objetsend.value) {

            alert("Veuillez saisir un objet s'il vous plait.");
            
            return;

        }
            
        if (!this.refs.Messagesend.value === '') {

            alert("Veuillez saisir un messsage s'il vous plait.");
            
            return;

        }

        if (!this.refs.Captchasend.value) {

          alert("Veuillez saisir le captcha s'il vous plait.");
          
          return;

        }


        if (this.state.generatecaptcha !== this.state.captcha) {

          alert("Veuillez corriger le captcha.");
          
          return;

        }
   
        fetch("https://www.nannylou.com/mailer/sendmailcontact.php",{
                            
            method:'post', 
            headers: {'Content-Type':'application/x-www-form-urlencoded'},                   
            body: queryString.stringify({
                receivername: this.refs.Nomsend.value,
                receivermail: 'contact@nannylou.com',
                nom: this.refs.Nomsend.value,
                telephone: this.refs.Telephonesend.value,
                email: this.refs.Emailsend.value,
                titre: this.refs.Objetsend.value,
                message: this.refs.Messagesend.value.replace(/(\r\n|\n)/g, "<br/>"),
            })

        })
            .then(data => data.text())
              .then((text) => { 

                var chiffres = '0123456789';
                var min = "abcdefghijklmnopqrstuvwxyz";
                var maj = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var special = "?!$#";
                let pass = '';
                var len_chiffres = chiffres.length;
                var len_min = min.length;
                var len_maj = maj.length;
                var len_special = special.length;
              
                for (let i = 0; i < 2; i++ ) {
                  pass += chiffres[Math.floor(Math.random() * len_chiffres)];
                }
              
                for (let i = 0; i < 3; i++ ) {
                  pass += min[Math.floor(Math.random() * len_min)];
                }
              
                for (let i = 0; i < 3; i++ ) {
                  pass += maj[Math.floor(Math.random() * len_maj)];
                }
              
                for (let i = 0; i < 2; i++ ) {
                  pass += special[Math.floor(Math.random() * len_special)];
                }
              
                var melanger = pass.split('').sort(function(){return 0.5-Math.random()}).join('');

                     //NotificationManager.success('Message envoyé', 'Succès',3000);


                   this.setState({
                          nomcontact: '',
                          telephonecontact:'',
                          mailcontact: '',
                          titrecontact: '',
                          messagecontact: '',
                          captcha:'',
                          generatecaptcha : melanger,
                        });



              }).catch(function (error) {
                alert(error)
                //NotificationManager.warning(error, 'Erreur',5000);
              });

	}

    onEditorStateChange = (editorState) =>{
        
        this.setState({
                          editorState,
                        });
    }
    
  render() {

          return (
              
                <div className="App">
                                
                        <div style={{
                          backgroundImage: "url(" + this.state.lienimage + "fond-contact.png" + ")",
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          marginTop:0,
                          paddingTop:"100px",
                          paddingBottom:"100px"
                        }}>
                            
                            <Fade bottom>

                            <Card style={{ width: isBrowser ?'80%':"90%", minHeight: isBrowser ? '400px' : '400px', borderRadius:'25px', margin:'25px', marginLeft: isBrowser?"15%":"5%", marginTop:isBrowser?"40px":"5px"}} className="cardlistcontact">                                               
                                                                                          
                                <Card.Body >
                                                        
                                    <Card.Title style={{textAlign:"center", margin:"10px"}}>

                                    <div className="container center">
                                      <div className="row">
                                          <div className="col">

                                            <div className="container">

                                              <div className="row">

                                                <div className="col-sm-2" >

                                                  {isBrowser? 
                                                    
                                                    <div className='traitorangedroitecontact'>
                                                      
                                                    </div>

                                                    :
                                                
                                                    null

                                                  }

                                                      <div className="col">
                                                        
                                                      </div>

                                                </div>

                                                <div className="col">
                                                  <p style={{color:"#003459", fontSize:isBrowser?"30px":"20px",textAlign:isBrowser?"right":"center"}}>CONTACTEZ-NOUS</p>
                                                </div>
                                                
                                              </div>
                                            </div>

                                            <button className='btncontactinfo'style={{marginTop:"0px", width:isBrowser?"90%":"100%"}} onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}><MailOutlineIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>contact@nannylou.com</button>

                                            <button className='btncontactinfo'style={{marginTop:"20px", width:isBrowser?"90%":"100%"}} onClick={(e) => {e.preventDefault(); window.location.href="tel:+330768755054";}}><PhonelinkRingIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>+33 07 68 75 50 54</button>

                                            {/*<button className='btncontactinfo'style={{marginTop:"20px", width:isBrowser?"90%":"100%"}} onClick={(e) => {e.preventDefault(); window.open("https://www.google.com/maps/place/9+Rue+du+11+Novembre+1918,+94460+Valenton,+France/@48.7487185,2.4675598,21z/data=!4m6!3m5!1s0x47e60b3e8c1ed89f:0x659d76024f31d5ba!8m2!3d48.7488576!4d2.4672943!16s%2Fg%2F11c23qdzhr?hl=fr","_blank")}}><PlaceIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>9 rue du 11 novembre 1918,<p style={{paddingLeft:"40px"}}>94460 VALENTON, France</p></button>*/}

                                          </div>

                                          <div className="col">

                                          
                                          <form ref={(el) => this.myFormRef = el}>  
                                            <Form.Group>

                                              <div className="form-group" style={{margin:'5px'}}>
                                                <input type="text"  style={{color:"rgba(0, 0, 0, 0.35)", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder="Nom et prénoms" value={this.state.nomcontact} onChange={(e) => this.setState({ nomcontact: e.target.value})} />
                                              </div>

                                              <div className="form-group" style={{margin:'5px'}}>
                                                <input type="text"  style={{color:"rgba(0, 0, 0, 0.35)", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder="Téléphone avec code pays" ref="Telephonesend" value={this.state.telephonecontact} onChange={(e) => this.setState({ telephonecontact: e.target.value})} />
                                              </div>

                                              <div className="form-group" style={{margin:'5px'}}>
                                                <input type="email"  style={{color:"rgba(0, 0, 0, 0.35)", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder="Email" ref="Emailsend" value={this.state.mailcontact} onChange={(e) => this.setState({ mailcontact: e.target.value})} />
                                              </div>

                                              <div className="form-group" style={{margin:'5px'}}>
                                                <input type="text"  style={{color:"rgba(0, 0, 0, 0.35)", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder="Objet" ref="Objetsend" value={this.state.titrecontact} onChange={(e) => this.setState({ titrecontact: e.target.value})}/>
                                              </div>

                                              <div className="form-group" style={{margin:'5px'}}>
                                                <textarea type="textarea" style={{color:"rgba(0, 0, 0, 0.35)", width:"100%",padding:"10px",fontSize:isBrowser?'18px':"14px"}} rows={3} placeholder="Votre message ..." ref="Messagesend" value={this.state.messagecontact} onChange={(e) => this.setState({ messagecontact: e.target.value})} />
                                              </div>

                                           
                                                <div className="container">
                                                  <div className="row ">
                                                    <div className="col">
                                                      
                                                      <div className="form-group" style={{margin:'10px', height:"40px"}}>
                                                        <input type="text"  style={{width:'140px',height:"40px", fontSize:'18px',textAlign:"center"}} placeholder="CAPTCHA" ref="Captchasend" value={this.state.captcha} onChange={(e) => this.setState({ captcha: e.target.value})} />
                                                      </div>
                                                    
                                                    </div>
                                                    <div className="col d-flex justify-content-center">
                                                      <div style={{background: '#FF7E5A',
                                                              border: 0,
                                                              borderRadius: 8,
                                                              boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)', width:'140px', height:'40px', marginTop:'10px'}}>
                                                        <p class="noselect" style={{fontWeight:'bold', fontSize:'20px',paddingTop:"5px",color:"rgba(0, 0, 0, 0.35)"}}>{this.state.generatecaptcha}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                            </Form.Group>
                                                                                      
                                            <button style={{background: '#FF7E5A',
                                                    marginTop:"10px",
                                                    border: 0,
                                                    borderRadius: 9,
                                                    width:'100%', height:'50px', color:'white', fontSize:"18px"}}  onClick={this.envoiemail}>Envoyer</button>
                                            </form>  



                                              
                                          </div>

                                         
                                      </div>
                                  </div>
    

                                    </Card.Title>
                                    
                                  </Card.Body>
    
                                </Card>   
                                        
                            </Fade>

                        </div>

                </div>

          );
        }
    
}

export default Contact;
