import './App.css';
import './moncss.css';
import React, {Component} from 'react';
import Card from 'react-bootstrap/Card'
import Fade from 'react-reveal/Fade';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import LanguageIcon from '@mui/icons-material/Language';


class Accueil extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lienimage: process.env.PUBLIC_URL + 'images/',
            slidecaroussel: [

                {
                    id: 1,
                    photoslide: 's1.jpg',
                    photoslidemobile: 's1_mobile.jpg',
                    Titre: '',
                    Description: 'Gardes des enfants par des professionel(le)s.',
                },

                {
                    id: 2,
                    photoslide: 's2.jpg',
                    photoslidemobile: 's2_mobile.jpg',
                    Titre: '',
                    Description: 'Un réseau de professionnels expérimentés, agréés, diplômés et/ou certifiés.',
                },

                {
                    id: 3,
                    photoslide: 's3.jpg',
                    photoslidemobile: 's3_mobile.jpg',
                    Titre: '',
                    Description: '1ère plateforme en ligne de garde d\'enfants 24h/24 en France.',
                },

            ],

            slidecarousselnew: [

                {
                    id: 1,
                    photoslide: '01n-b.jpg',
                    photoslidemobile: '01n_mobile.jpg',
                    Titre: '',
                    Description: 'Garde d\'enfants par des professionel(le)s.',
                },

                {
                    id: 2,
                    photoslide: 'reseau.jpg',
                    photoslidemobile: 'reseau_mobile.png',
                    Titre: '',
                    Description: 'Un réseau de professionnels expérimentés, agréés, diplômés et/ou certifiés.',
                },

                {
                    id: 3,
                    photoslide: 'plateforme.png',
                    photoslidemobile: 'plateforme_mobile.png',
                    Titre: '',
                    Description: '1ère plateforme en ligne de garde d\'enfants 24h/24 en France.',
                },

            ],

            nomcontact: '',
            mailcontact: '',
            titrecontact: '',
            messagecontact: '',
            isModalVisible: false,
            editorState: '',
            montant: 0.0,
            lng: 'FR',
            titre: '',
        };

    }

    componentDidMount() {

        document.title = "NannyLou | Nourrice agrée, Nounou, Babysitting près de chez vous"

        if (this.state.lng === 'FR') {
            this.setState({
                titre: 'NannyLou | Nourrice agrée, Nounou, Babysitting près de chez vous',
            });
        } else {
            this.setState({
                titre: 'NannyLou | Nourrice agrée, Nounou, Babysitting près de chez vous',
            });
        }

    };

    nouveauresa = () => {
        this.setState({isModalVisible: true});
    };

    componentWillMount() {
        window.scrollTo(0, 0)
    }


    onEditorStateChange = (editorState) => {

        this.setState({
            editorState,
        });
    }

    render() {

        return (

            <div className="App" style={{marginRight: "0px"}}>
                <div id="accueil">
                    <Carousel autoPlay={true} controls={false} indicators={true} variant="dark"
                              style={{paddingTop: isBrowser ? "60px" : "0px"}}>


                        {this.state.slidecarousselnew.map((slidecontent) => {

                            return (

                                <Carousel.Item fade key={slidecontent.id} interval={5000}>

                                    <BrowserView>
                                        <img
                                            className="d-block w-100" style={{height: "90vh", marginTop: "0px"}}
                                            src={this.state.lienimage + slidecontent.photoslide}
                                        />
                                    </BrowserView>
                                    <MobileView>
                                        <img
                                            className="d-block w-100" style={{height: "90vh", marginTop: "0px"}}
                                            src={this.state.lienimage + slidecontent.photoslidemobile}
                                        />
                                    </MobileView>


                                    {isMobile ?

                                        <Carousel.Caption>

                                            <p className='textdescriptioncaroussel'>{slidecontent.Description}</p>

                                        </Carousel.Caption>

                                        :

                                        null

                                    }


                                </Carousel.Item>

                            );
                        })}


                    </Carousel>
                </div>
                <div className='partenaires'>
                    <p style={{color: "white", fontSize: "32px", textAlign: "center"}}>NOS PARTENAIRES</p>

                    <div className='listepartenaire'>

                        <Card style={{
                            width: '450px',
                            borderRadius: '25px',
                            margin: isBrowser ? '25px' : '0px',
                            marginTop: isMobile ? '25px' : '25px'
                        }}>

                            <Card.Body>

                                <a href="https://www.aubonheurnaissant.fr" target="_blank"><img
                                    className='imagepartenaires'
                                    src={this.state.lienimage + "bonheurnaissant.png"}></img></a>

                                <p style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "45px",
                                    textAlign: "center",
                                    margin: "10px"
                                }}>Au bonheur naissant</p>


                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Accompagnement périnatal et parental grâce à divers ateliers autour du bien-être
                                        de bébé et maman.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Bonheur naissant peut également vous accompagner si vous avez besoin de conseils
                                        pré et post natal.</p>

                                    <p style={{
                                        color: "black",
                                        fontSize: "24px",
                                        textAlign: "center",
                                        margin: '10px'
                                    }}>Valentine Boumansoura</p>

                                    <a style={{color: "#20A4DD", textDecoration: "none"}}
                                       href="tel:+330612143820"><PhonelinkRingIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>+33 06 12 14 38
                                        20</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="mailto:aubonheurnaissant@hotmail.com"><MailOutlineIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>aubonheurnaissant@hotmail.com</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="https://www.aubonheurnaissant.fr" target="_blank"><LanguageIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>https://www.aubonheurnaissant.fr</a>

                                </div>

                            </Card.Body>

                        </Card>

                        <Card style={{
                            width: '450px',
                            borderRadius: '25px',
                            margin: isBrowser ? '25px' : '0px',
                            marginTop: isMobile ? '25px' : '25px'
                        }}>

                            <Card.Body>

                                <a href="https://www.temps-suspendu.com" target="_blank"><img
                                    className='imagepartenaires' src={this.state.lienimage + "tempssuspend.png"}></img></a>

                                <p style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "45px",
                                    textAlign: "center",
                                    margin: "10px"
                                }}>Temps suspendu</p>


                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Thérapeutique Bain Bébé, massage femme enceinte, massage post natal avec
                                        resserrage de bassin, Soin rituel Rebozo à 4 mains, atelier massage bébé,
                                        atelier réflexologie bébé émotionnelle, Consultations empreintes émotionnelles
                                        de la vie prénatale et de la naissance et bientôt des consultations de
                                        traductrice de parole au bébé.</p>

                                    <p style={{
                                        color: "black",
                                        fontSize: "24px",
                                        textAlign: "center",
                                        margin: '10px'
                                    }}>Nathalie Fressard</p>

                                    <a style={{color: "#20A4DD", textDecoration: "none"}}
                                       href="tel:+330778515346"><PhonelinkRingIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>+33 07 78 51 53
                                        46</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="mailto:tsuspendu@gmail.com"><MailOutlineIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>tsuspendu@gmail.com</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="https://www.temps-suspendu.com" target="_blank"><LanguageIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>https://www.temps-suspendu.com</a>

                                </div>

                            </Card.Body>

                        </Card>

                        <Card style={{
                            width: '450px',
                            borderRadius: '25px',
                            margin: isBrowser ? '25px' : '0px',
                            marginTop: isMobile ? '25px' : '25px'
                        }}>

                            <Card.Body>

                                <a href="https://www.coqo.fr" target="_blank"><img className='imagepartenaires'
                                                                                   src={this.state.lienimage + "coqo.png"}></img></a>

                                <p style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "45px",
                                    textAlign: "center",
                                    margin: "10px"
                                }}>Coqo</p>


                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>La première et unique solution pour aider les futurs & jeunes parents à choisir
                                        de manière éclairée les meilleurs produits de puériculture : en toute conscience
                                        et connaissance.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Votre tiers de confiance indépendant qui permet de connaître les impacts
                                        sanitaires et environnementaux des produits de puériculture.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Une application 100 % gratuite.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Une application transparente.</p>

                                    <p style={{
                                        color: "black",
                                        fontSize: "24px",
                                        textAlign: "center",
                                        margin: '10px'
                                    }}>Elsa Fichebin</p>


                                    {/*<a style={{ color:"#20A4DD", textDecoration:"none"}} href="tel:+330610587200"><PhonelinkRingIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>+33 06 10 58 72 00</a>*/}

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="mailto:elsa@coqo.fr"><MailOutlineIcon style={{
                                        fontSize: '20',
                                        marginRight: '20px',
                                        color: "#20A4DD"
                                    }}/>elsa@coqo.fr</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="https://www.coqo.fr" target="_blank"><LanguageIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>https://www.coqo.fr</a>

                                </div>

                            </Card.Body>

                        </Card>

                        <Card style={{
                            width: '450px',
                            borderRadius: '25px',
                            margin: isBrowser ? '25px' : '0px',
                            marginTop: isMobile ? '25px' : '25px'
                        }}>

                            <Card.Body>

                                <a href="https://www.aurorecubier.com" target="_blank"><img className='imagepartenaires'
                                                                                            src={this.state.lienimage + "aurorecubier.png"}></img></a>

                                <p style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "45px",
                                    textAlign: "center",
                                    margin: "10px"
                                }}>Aurore Cubier</p>


                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Aurore a les connaissances approfondies et les outils nécessaires pour vous
                                        guider à travers les défis de la parentalité.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Avec ses consultations, formations et ateliers empreints de bienveillance,
                                        d'empathie et de non-jugement, Aurore peut vous aider à créer une harmonie
                                        familiale sereine et joyeuse, à choisir la parentalité qui vous convient le
                                        mieux, à ajuster les pratiques professionnelles pour permettre aux enfants de
                                        développer leur plein potentiel et à aider les enfants et les adultes à
                                        surmonter les traumatismes grâce à l'EMDR.</p>

                                    <p style={{
                                        color: "black",
                                        fontSize: "24px",
                                        textAlign: "center",
                                        margin: '10px'
                                    }}>Aurore CUBIER</p>

                                    <a style={{color: "#20A4DD", textDecoration: "none"}}
                                       href="tel:+330678349636"><PhonelinkRingIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>+33 06 78 34 96
                                        36</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="mailto:aurore.cubier@gmail.com"><MailOutlineIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>aurore.cubier@gmail.com</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="https://www.aurorecubier.com" target="_blank"><LanguageIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>https://www.aurorecubier.com</a>

                                </div>

                            </Card.Body>

                        </Card>

                        <Card style={{
                            width: '450px',
                            borderRadius: '25px',
                            margin: isBrowser ? '25px' : '0px',
                            marginTop: isMobile ? '25px' : '25px'
                        }}>

                            <Card.Body>

                                <img className='imagepartenaires' src={this.state.lienimage + "etiopathe.png"}></img>

                                <p style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "45px",
                                    textAlign: "center",
                                    margin: "10px"
                                }}>Etiopathe</p>

                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Je suis là pour vous aider à soigner vos troubles de articulations de entorse,
                                        Douleur cervicales tout comme le dos.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Je pratique sur les enfants comme les bébés et adulte.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>N'hésitez pas à venir me voir, je me ferais un plaisir de pouvoir soulager vos
                                        douleurs.</p>

                                    <p style={{
                                        color: "black",
                                        fontSize: "24px",
                                        textAlign: "center",
                                        margin: '10px'
                                    }}>Amandine Held</p>

                                    <a style={{color: "#20A4DD", textDecoration: "none"}}
                                       href="tel:+330646057423"><PhonelinkRingIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>+33 06 46 05 74
                                        23</a>

                                    {/*
                                            <a style={{ marginTop:"20px", color:"#20A4DD", textDecoration:"none"}} href="mailto:aurore.cubier@gmail.com"><MailOutlineIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>aurore.cubier@gmail.com</a>

                                            <a style={{ marginTop:"20px", color:"#20A4DD", textDecoration:"none"}} href="https://www.aurorecubier.com" target="_blank"><LanguageIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>https://www.aurorecubier.com</a>
                                            */}

                                </div>

                            </Card.Body>

                        </Card>

                        <Card style={{
                            width: '450px',
                            borderRadius: '25px',
                            margin: isBrowser ? '25px' : '0px',
                            marginTop: isMobile ? '25px' : '25px'
                        }}>

                            <Card.Body>

                                <img className='imagepartenaires'
                                     src={this.state.lienimage + "courtierenassurances.png"}></img>

                                <p style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "45px",
                                    textAlign: "center",
                                    margin: "10px"
                                }}>Courtier en assurances</p>

                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Courtier tous types d'Assurance depuis 40 ans; je suis à votre service afin de
                                        protéger au mieux votre famille.</p>

                                    <p style={{
                                        color: "#787878",
                                        fontSize: "20px",
                                        textAlign: "left",
                                        margin: '10px'
                                    }}>Alors n'hésitez pas.</p>

                                    {/*
                                                <p style={{color:"black",  fontSize:"24px", textAlign:"center",margin:'10px'}} >Amandine Held</p>
                                            */}

                                    <a style={{color: "#20A4DD", textDecoration: "none"}}
                                       href="tel:+330660260988"><PhonelinkRingIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>+33 06 60 26 09
                                        88</a>

                                    <a style={{marginTop: "20px", color: "#20A4DD", textDecoration: "none"}}
                                       href="mailto:mutuelleetcompagnie@hotmail.fr"><MailOutlineIcon
                                        style={{fontSize: '20', marginRight: '20px', color: "#20A4DD"}}/>mutuelleetcompagnie@hotmail.fr</a>
                                    {/*
                                            <a style={{ marginTop:"20px", color:"#20A4DD", textDecoration:"none"}} href="https://www.aurorecubier.com" target="_blank"><LanguageIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>https://www.aurorecubier.com</a>
                                            */}

                                </div>

                            </Card.Body>

                        </Card>


                    </div>

                </div>

                <div className='missions'>

                    <div className="container center">
                        <div className="row">
                            <div className="col-sm">

                                {isBrowser ?

                                    <div className='traitbleugauche'></div>

                                    :

                                    null

                                }

                            </div>

                            <div className="col-sm">
                                <p style={{color: "#1FA5DD", fontSize: "40px"}}>NOS MISSIONS</p>
                            </div>

                            <div className="col-sm">

                            </div>
                        </div>
                    </div>

                    <div><p style={{color: "#787878", fontSize: "24px"}}>Être au service des :</p></div>

                    <div className="container">

                        <div className="row">

                            <div className="col-sm">
                                <Fade Bottom>

                                    <Card style={{
                                        width: '300px',
                                        height: isBrowser ? '486px' : '486px',
                                        borderRadius: '25px',
                                        marginTop: isMobile ? "25px" : "0px"
                                    }}>
                                        <Card.Body>
                                            <img style={{width: "243px", height: "270px", marginTop: "0px"}}
                                                 src={this.state.lienimage + "Parents-logo.png"}></img>
                                            <p style={{marginTop: "40px", fontSize: "24px"}}>Parents</p>

                                            <button className='btnvoirplus' style={{marginTop: "20px"}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href = "/Parents";
                                                    }}>VOIR PLUS
                                            </button>
                                        </Card.Body>
                                    </Card>

                                </Fade>
                            </div>

                            <div className="col-sm">
                                <Fade Bottom>

                                    <Card style={{
                                        width: '300px',
                                        height: isBrowser ? '486px' : '486px',
                                        borderRadius: '25px',
                                        marginTop: isMobile ? "25px" : "0px"
                                    }}>
                                        <Card.Body>
                                            <img style={{width: "243px", height: "270px", marginTop: "0px"}}
                                                 src={this.state.lienimage + "Partenaires.png"}></img>
                                            <p style={{marginTop: "40px", fontSize: "24px"}}>Partenaires</p>

                                            <button className='btnvoirplus' style={{marginTop: "20px"}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href = "/Entreprises";
                                                    }}>VOIR PLUS
                                            </button>
                                        </Card.Body>
                                    </Card>

                                </Fade>
                            </div>

                            <div className="col-sm">
                                <Fade Bottom>

                                    <Card style={{
                                        width: '300px',
                                        height: isBrowser ? '486px' : '486px',
                                        borderRadius: '25px',
                                        marginTop: isMobile ? "25px" : "0px"
                                    }}>
                                        <Card.Body>
                                            <img style={{width: "243px", height: "270px", marginTop: "0px"}}
                                                 src={this.state.lienimage + "Nannylink.png"}></img>
                                            <p style={{marginTop: "20px", fontSize: "24px"}}>Nannies agrées et
                                                diplômées</p>

                                            <button className='btnvoirplus' style={{marginTop: "4px"}} onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = "/Nannies";
                                            }}>VOIR PLUS
                                            </button>
                                        </Card.Body>
                                    </Card>

                                </Fade>
                            </div>

                        </div>

                    </div>

                </div>


                <div style={{
                    backgroundImage: "url(" + this.state.lienimage + "avantagesbackground.png" + ")",
                    backgroundPosition: 'top',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <Fade Bottom>

                        <div className='avantages'>
                            <div><p style={{
                                color: "#1FA5DD",
                                fontSize: "40px",
                                textAlign: "center",
                                marginTop: isBrowser ? "60px" : "80px"
                            }}>NOS AVANTAGES</p></div>
                        </div>

                        <div className="container" style={{marginBottom: "0px"}}>
                            <div className="row">
                                <div className="col-sm">


                                    <p style={{textAlign: "left", color: "#FF7E5A", fontSize: "16px"}}>Quand on est
                                        parent</p>
                                    <p style={{textAlign: "left", color: "#FF7E5A", fontSize: "40px"}}>Du temps pour
                                        vous</p>
                                    <div className="traitorangegauche"></div>

                                    <MobileView>
                                        <img style={{marginTop: "40px", width: "250px", height: "250px"}}
                                             src={this.state.lienimage + "tempspourvousn.png"}></img>
                                    </MobileView>

                                    <p style={{lineHeight: "28px", color: "#757575", textAlign: "left"}}>Il arrive
                                        souvent que l'on rentre tard le soir ou que l'on travaille en horaires décalés,
                                        voire même la nuit pour certains. Parfois, on a simplement envie de passer du
                                        temps seul(e) ou accompagné(e). C'est pourquoi NannyLou a constitué un large
                                        réseau de professionnels de la petite enfance expérimentés, qualifiés et
                                        disponibles pour former une communauté et vous mettre en relation 24h/24 grâce à
                                        un système de géolocalisation afin de faciliter les contacts de proximité.</p>

                                </div>

                                <div className="col-sm">

                                    <BrowserView>
                                        <img style={{marginTop: "0px", width: "600px", height: "480px"}}
                                             src={this.state.lienimage + "tempspourvousn.png"}></img>
                                    </BrowserView>

                                </div>
                            </div>
                        </div>

                        <div className="container" style={{marginBottom: "0px"}}>
                            <div className="row">

                                <div className="col-sm">

                                    <BrowserView>
                                        <img style={{marginTop: "0px", width: "600px", height: "480px"}}
                                             src={this.state.lienimage + "beautiful-couple.png"}></img>
                                    </BrowserView>

                                </div>

                                <div className="col-sm">

                                    <p style={{textAlign: "left", color: "#7B4EFB", fontSize: "16px"}}>Assistant(e)
                                        maternelle</p>
                                    <p style={{textAlign: "left", color: "#7B4EFB", fontSize: "40px"}}>Large réseau de
                                        professionnel(le)s de la petite enfance</p>
                                    <div className="traitvioletgauche0"></div>

                                    <MobileView>
                                        <img style={{marginTop: "40px", width: "250px", height: "250px"}}
                                             src={this.state.lienimage + "beautiful-couple.png"}></img>
                                    </MobileView>

                                    <p style={{lineHeight: '28px', color: "#757575", textAlign: "left"}}>Vous êtes un(e)
                                        baby-sitter, nounou ou assistant(e) maternel(le) ayant de l’expérience et/ou
                                        titulaire d'un diplôme/certificat dans le domaine de la petite enfance ? Nous
                                        vous invitons à vous inscrire dès maintenant sur NannyLou, la plateforme de
                                        référence pour les services de garde d'enfants. Grâce à notre large réseau de
                                        contacts, vous pourrez facilement trouver une famille à proximité de votre
                                        domicile, en fonction de vos disponibilités et de vos compétences.</p>

                                </div>
                            </div>
                        </div>

                    </Fade>
                </div>

                <div className='fonctionement'>

                    <div className="container center">
                        <div className="row">
                            <div className="col-sm-3">

                                {isBrowser ?

                                    <div className='traitorangedroite'></div>

                                    :

                                    null

                                }

                            </div>

                            <div className="col-sm-6">
                                <p style={{
                                    color: "#FF7E5A",
                                    fontSize: "40px",
                                    paddingTop: isBrowser ? "100px" : "0px",
                                    paddingBottom: isBrowser ? "50px" : "0px"
                                }}>NOTRE FONCTIONEMENT</p>
                            </div>

                            <div className="col-sm-3">

                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                <Fade Bottom>
                                    <img style={{width: "148px", height: "148px", marginTop: isMobile ? "40px" : "0px"}}
                                         src={this.state.lienimage + "application.png"}></img>
                                    <p style={{color: "#FF84EE", fontSize: "20px"}}>Télécharger l'application</p>
                                </Fade>
                            </div>
                            <div class="col-sm">
                                <Fade Bottom>
                                    <img style={{width: "148px", height: "148px", marginTop: isMobile ? "40px" : "0px"}}
                                         src={this.state.lienimage + "profil.png"}></img>
                                    <p style={{color: "#EB3D3B", fontSize: "20px"}}>S'inscrire et compléter son
                                        profil</p>
                                </Fade>
                            </div>
                            <div class="col-sm">
                                <Fade Bottom>
                                    <img style={{width: "148px", height: "148px", marginTop: isMobile ? "40px" : "0px"}}
                                         src={this.state.lienimage + "rejoindrecommunaute.png"}></img>
                                    <p style={{color: "#676CC5", fontSize: "20px"}}>Rejoindre la communauté</p>
                                </Fade>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='client'>

                    <Fade Bottom>

                        <div className="row">

                            <div className="col-sm-4">
                                <img style={{width: "100%", height: "550px", marginTop: isMobile ? "40px" : "0px"}}
                                     src={this.state.lienimage + "clientimage.png"}></img>
                            </div>

                            <div className="col-sm-8">

                                <div className="row">

                                    <div className="col-sm-3">
                                        <p style={{
                                            color: "#1FA5DD",
                                            fontSize: "36px",
                                            textAlign: "left",
                                            marginTop: isMobile ? "20px" : "0px"
                                        }}>Nos clients</p>
                                    </div>
                                    <div className="col-sm-5"
                                         style={{paddingTop: isBrowser ? "30px" : "0px", textAlign: "left"}}>
                                        <div className="traitbleuapres"></div>
                                    </div>

                                </div>

                                <p style={{
                                    color: "#5F5F5F",
                                    fontSize: "20px",
                                    textAlign: "left",
                                    marginBottom: "50px"
                                }}>NannyLou s'adresse à deux types de clients.</p>
                                <p style={{
                                    color: "#5F5F5F",
                                    fontSize: "20px",
                                    textAlign: "left",
                                    marginBottom: "50px"
                                }}>Tout d'abord, nous proposons une large gamme de services de garde d'enfants pour
                                    répondre à tous les besoins des parents, que ce soit pour des horaires de travail
                                    classiques ou décalés, ou pour des besoins occasionnels tels que des sorties en
                                    soirée ou des rendez-vous médicaux. Nous sommes conscients que les parents ont des
                                    besoins variés en matière de garde d'enfants, c'est pourquoi nous mettons tout en
                                    œuvre pour les aider à trouver une solution sur mesure adaptée à leur emploi du
                                    temps chargé.</p>
                                <p style={{
                                    color: "#5F5F5F",
                                    fontSize: "20px",
                                    textAlign: "left",
                                    marginBottom: "50px"
                                }}>Ensuite, nous offrons également nos services aux entreprises en leurs proposant un
                                    service de garde d'enfants immédiat, fiable et en continu. Nous sommes convaincus
                                    que la garde d'enfants est un élément clé pour aider les collaborateurs, partenaires
                                    et/ou clients d’une entreprise à mieux concilier leur vie professionnelle et leur
                                    vie personnelle, tout en favorisant leur bien-être et leur productivité.</p>
                                {/*<p style={{color:"#5F5F5F", fontSize:"20px", textAlign:"left",marginBottom:"50px"}}>Nos clients sont des parents, des Nannies (expérimentées, diplômées, agréées ou certifiées). Leurs motivations ont pour but premier, de faire garder leurs enfants sur la contrainte de leur temps de travail, sans oublier les emplois en horaires décalés.</p>*/}

                            </div>
                        </div>


                    </Fade>
                </div>

            </div>

        );
    }

}

export default Accueil;
