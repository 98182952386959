
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import './moncss.css';
import { Button} from 'react-bootstrap';
import * as ls from "local-storage";
import {Table, Thead, Tbody, Tr, Td} from 'react-super-responsive-table';
import { isExpired, decodeToken}from "react-jwt";
import {API_URL} from '../constants';

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Listepaiement extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        dureesound:'',
        selectedOption: null,
        idtype : 0,
        iduserupdate : 0,
        activeuserupdate : 0,
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        listpaiement: [],
        iduser:0,
        modalupdate: false,
        modaldelete: false,
        usertype:'',
        societe:'',
        editorContentHtml:'',
      };

      
      this.selectsociety = this.selectsociety.bind(this);
      this.selectusertype = this.selectusertype.bind(this);
        
  }

  componentWillMount() {

    document.title = "Liste des paiements";

    let token = ls.get('token');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");
  
    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[2] !== 'admin'){

      this.setState({ tokenvalue: ''})
      return
  
    }else{
  
      this.setState({ tokenvalue: userdata[2]})
  
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const responsepaimentlist = await fetch(`${API_URL}/paiement/selectallpaiement.php`,{                       
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      });
    const datapaiment = await responsepaimentlist.json();
    
    if(JSON.stringify(datapaiment).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listpaiement: datapaiment})
    }  

    document.title = "Liste des paiements";

}  



showdeleteqcm(iduser,nom,prenom){
  
  this.setState({
    iduser:iduser,
    nom: nom,
    prenoms:prenom,
    modaldelete: true
  })

}

deleteqcm(){

  fetch(`${API_URL}/qcm/supprimerqcm.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idqcm: this.state.idqcmupdate,
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'NOT'){
    this.setState({ errorform: true, errormessage: 'QCM non reconnu' });
    return;
    }

    if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
    this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
    return;
    }

    this.setState({
      idqcmupdate: 0,
      qcmtext: '',
      modaldelete: false
    })

    this.refreshdata();

    })
    .catch(err => {
      alert("Erreur :" + errocode + ", "+err)
      this.setState({ tokenuser: err});
    });  

    document.title = "Liste des QCM";

}


async refreshdata(){
  
  const responseqcmlist = await fetch(`${API_URL}/qcm/Selectallqcm.php`);
  const dataqcm = await responseqcmlist.json();

  if(JSON.stringify(dataqcm).replace(/\"/g, "") !== 'NOT'){
    this.setState({ listqcm: dataqcm})
  }else{
    this.setState({ listqcm: []})
  }

  document.title = "Liste des paiements";

}

selectusertype(e) {
  this.setState({ usertype: e.target.value });
}

selectsociety(e) {
  this.setState({ societe: e.target.value });
}



onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}

async expirelist(){

  this.testtoken();

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const responsepaimentlist = await fetch(`${API_URL}/paiement/selectallpaiementexpire.php`,{                       
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      });
    const datapaiment = await responsepaimentlist.json();
    
    if(JSON.stringify(datapaiment).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listpaiement: datapaiment})
    }  

    document.title = "Liste des paiements expiré";

}

async validelist(){

  this.testtoken();

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const responsepaimentlist = await fetch(`${API_URL}/paiement/selectallpaiementvalide.php`,{                       
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      });
    const datapaiment = await responsepaimentlist.json();
    
    if(JSON.stringify(datapaiment).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listpaiement: datapaiment})
    }  

    document.title = "Liste des paiements valide";

}

async alllist(){

  this.testtoken();

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

  const responsepaimentlist = await fetch(`${API_URL}/paiement/selectallpaiement.php`,{                       
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
    });
  const datapaiment = await responsepaimentlist.json();
  
  if(JSON.stringify(datapaiment).replace(/\"/g, "") !== 'NOT'){
    this.setState({ listpaiement: datapaiment})
  }  

  document.title = "Liste des paiements valide";

}

testtoken(){
      
  let token = ls.get('token');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    ls.clear()
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    ls.clear()
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

}

  
  render() {  

          return (
              
                <div className="App" >
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }
                    
                   

                 
                 
                  <div className='centrervue' style={{ paddingTop:'100px'}}>

                    <div className='row d-flex justify-content-center' style={{marginRight:"40px"}}>
                                              
                        <Button className="btn" style={{ backgroundColor:"#FF7E5A",margin:"20px", width:'150px', height:"40px",borderRadius:'25px', border:"0px"}} onClick={() => this.expirelist()} >Expiré</Button>
                        <Button className="btn" style={{ backgroundColor:"white",margin:"20px", width:'150px', height:"40px",borderRadius:'25px', color:"black", borderColor:"black"}} onClick={() => this.validelist()} >Valide</Button>
                        <Button className="btn" style={{margin:"20px",width:'150px', height:"40px",borderRadius:'25px', color:"black"}} onClick={() => this.alllist()} >Tout</Button>

                    </div>

                  {this.state.listpaiement.length>0 ?
                    
               
                        <Table>
                          <Thead>
                            <Tr>

                              <Td >Date de paiement</Td>
                              <Td >Abonnement</Td>
                              <Td >Client</Td>
                              <Td >Montant</Td>
                              <Td >Date de fin d'abonnement</Td>
  
                              {/*<TableCell >Supprimer</TableCell>*/}

                            </Tr>
                          </Thead>
                          <Tbody>

                            {this.state.listpaiement.map((row) => (

                              <Tr key={row.idpaiement} style={{backgroundColor:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getTime()) > parseInt(Date.now()) ?"white":"#FF7E5A"}} className="tableborder">
                               
                                <Td style={{color:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getTime()) > parseInt(Date.now()) ?"black":"white"}}>{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getDate())} - {parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getMonth())+1} - {new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getFullYear()} {parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getHours())}h:{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getMinutes())}m:{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.datepaiement.replace(/[-]/g, '/'))).getSeconds())}s</Td>
                                <Td style={{color:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getTime()) > parseInt(Date.now()) ?"black":"white"}}>{row.description}</Td> 
                                <Td style={{color:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getTime()) > parseInt(Date.now()) ?"black":"white"}}>{row.clientprenom} {row.clientnom} <br/><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.clientemail}>Mail: {row.clientemail}</a> <br/> <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.clientcontact}>Tél: {row.clientcontact}</a></Td>
                                
                                <Td style={{color:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getTime()) > parseInt(Date.now()) ?"black":"white"}}>{row.montantnet} €</Td>   
                                <Td style={{color:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getTime()) > parseInt(Date.now()) ?"black":"white"}}>{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getDate())} - {parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getMonth())+1} - {new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getFullYear()} {parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getHours())}h:{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getMinutes())}m:{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.datefinabonnement.replace(/[-]/g, '/'))).getSeconds())}s</Td>                             
                                {/*<TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showdeleteqcm(row.idutilisateur,row.nomnounou,row.prenomsnounou)}><span><DeleteIcon /></span></button></TableCell>*/}
                    
                              </Tr>

                            ))}

                          </Tbody>
                        </Table>
               
                
                    :

                    null

                  }
                  
                  </div> 

                </div>
                
          );
        }
    
}

export default Listepaiement;
