import './App.css';
import './moncss.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Component} from 'react';
import Flip from 'react-reveal/Flip';
import { Nav,Navbar,NavDropdown} from 'react-bootstrap';
import * as ls from "local-storage";
import {isMobile} from "react-device-detect";
import { isExpired, decodeToken }from "react-jwt";

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var datetoday = new Date();

var datepromo = new Date("2023-04-30");

class Menu extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        typeuser:null,
        showadmin:false,
        mail:'',
        nom:'',
        show:false,
        setShow:false,
        setShowadmin:false,
      };
        
  }

  

  componentWillMount() {

    //let token = ls.get('token');
    //this.setState({ typeuser: typeuser})

  }

  

  cleardatauser = (e)=>{
    ls.clear()
    //window.location.reload(true);
    document.location.href = '/Accueil';
  };
    
   componentDidMount() { 

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    this.setState({ mail: userdata[4]})
    
    if(userdata[2] !== 'admin'){

    this.setState({ typeuser: 'user'})
  
    }else{
  
      this.setState({ typeuser: userdata[2]})
  
    }

    //document.location.href = '/Accueil';

    window.addEventListener("storage", this.showtoken);
    
  }

  showtoken(){

    let token = ls.get('token');
    
    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/Accueil';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/Accueil';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");

    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

  //alert("SEESION ID:" + userdata[0]+ ", IP:" + userdata[1]+", ID TYPE:" + userdata[2]+", ID USER:" + userdata[3]+", MAIL:" + userdata[4].replace(/tirait/g, '-' )+", CARTE:" + userdata[5])

    if(userdata[2] !== 'admin'){

    this.setState({ typeuser: 'user'})
  
    }else{
  
      this.setState({ typeuser: userdata[2]})
  
    }

  }

  showDropdown = (e)=>{
    this.setState({ show: true, setShow:true})
    
  }

  hideDropdown = e => {
    this.setState({ show: false, setShow:false})
  }

  showDropdownadmin = (e)=>{
    this.setState({ showadmin: true, setShowadmin:true})
  }

  hideDropdownadmin = e => {
    this.setState({ showadmin: false, setShowadmin:false})
  }

  render() {

          return (

            <div className='noselectdata'>


              <div style={{position:"fixed", width:"100%", marginTop:"0px", zIndex: '1000'}}>

              <Navbar collapseOnSelect expand="lg" className="navbar-edit navbar-fixed-top menu w-100" >
                  <Navbar.Brand  style={{color:"white"}}>

                      <img src={"https://www.nannylou.com/"+'1haWxAdGFzdC5mZSIsImp0aSI6Im1haWxAdGFzdC5mZSIsImlzcyI6InVuc2FpZCIsImlhdCI6MTY4MzUyNT.png'} style={{width:"60px", height:"60px", borderRadius:"30px",margin:"3px"}} alt="Nanny lou"/>

                  </Navbar.Brand>
                  <p style={{marginTop:"12px", marginLeft:"20px", fontSize:"20px", color:"white"}}>NannyLou</p>
                  {/*<p style={{marginTop:"12px", marginLeft:"20px", fontSize:"20px", color:"white"}}>{this.state.mail}</p>*/}
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav" className="rounded float-right">
                    <Nav className="justify-content-end" style={{ width: "100%", marginRight:"100px" }}>

                        <Nav.Link href="/Accueil" to="/Accueil" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-0} duration={500}>Accueil</Nav.Link>
                        <Nav.Link href="/Quisommesnous" to="/Quisommesnous" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Qui sommes-nous</Nav.Link>
                        
                        
                        <NavDropdown title="Nos tarifs" 
                              id="collasible-nav-dropdown" 
                              show={this.state.show}
                              onMouseEnter={this.state.showDropdown} 
                              onMouseLeave={this.state.hideDropdown}
                              renderMenuOnMount={true}

                              >
                              <NavDropdown.Item className="navDropdownitems" href="/Parents" to="/Parents" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Pour les parents</NavDropdown.Item>
                              <NavDropdown.Item className="navDropdownitems" href="/Entreprises" to="/Entreprises" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Pour les entreprises</NavDropdown.Item>  
                              <NavDropdown.Item className="navDropdownitems" href="/Nannies" to="/Nannies" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Pour les Nannies</NavDropdown.Item>    

                              
                        </NavDropdown>

                        <Nav.Link href="/Contact" to="/Contact" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Contact</Nav.Link> 

                        {this.state.typeuser !== null?

                          <Nav.Link href="/Carte" to="/Carte" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Carte</Nav.Link> 

                          :  
 
                          null

                          }    

                          

                          {this.state.typeuser == 'user'?

                          <Nav.Link className="navDropdownitems" href="/Listecommande" to="/Listecommande" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Listes des commandes</Nav.Link>


                          :  

                          null

                          }   

                        
                          
                        {/*                 
                        {datepromo>datetoday?

                          <Link href="/Promotion" to="/Promotion" activeClass="active" className="nav-link animatennltext" style={{color:"white"}}  spy={true} smooth={true} offset={-100} duration={500}>
                            OBTENEZ VOTRE E-CARTE CADEAU
                          </Link>

                          :

                          null
                      
                        }

                        */}
                        

                        {this.state.typeuser == 'admin'?

                            <NavDropdown title="Dashboard" 
                            id="collasible-nav-dropdown" 
                            show={this.state.showadmin}
                            onMouseEnter={this.state.showDropdownadmin} 
                            onMouseLeave={this.state.hideDropdownadmin}
                            renderMenuOnMount={true}

                            >

                            <NavDropdown.Item className="navDropdownitems" href="/Listenounou" to="/Listenounou" activeClass="active"style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Liste des Nounous</NavDropdown.Item>
                            <NavDropdown.Item className="navDropdownitems" href="/Listeparent" to="/Listeparent" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Liste des Parents</NavDropdown.Item>
                            <NavDropdown.Item className="navDropdownitems" href="/Listeentreprise" to="/Listeentreprise" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Liste des Entreprises</NavDropdown.Item>
                            <NavDropdown.Item className="navDropdownitems" href="/Listepaiement" to="/Listepaiement" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Liste des paiements</NavDropdown.Item>
                            <NavDropdown.Item className="navDropdownitems" href="/Listecommande" to="/Listecommande" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Listes des commandes</NavDropdown.Item>
                            <NavDropdown.Item className="navDropdownitems" href="/Listecse" to="/Listecse" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Liste des CSE</NavDropdown.Item>
                            <NavDropdown.Item className="navDropdownitems" href="/QCMADMIN" to="/QCMADMIN" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Listes des QUIZ</NavDropdown.Item>
                            
                            {/* <NavDropdown.Item className="navDropdownitems" href="/Dev" to="/Dev" activeClass="active" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Dévéloppeur</NavDropdown.Item>*/}  

                            </NavDropdown>

                          :  

                          null

                        }  

                        {this.state.typeuser == 'user'?

                          <Nav.Link href="/Profil" to="/Profil" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-840} duration={500} style={{color:"white"}} >Mon profil</Nav.Link>

                          :  

                          null

                        }             

                        {/* 
                        <Link href="/Profil" to="/Profil" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-100} duration={500} style={{color:"#f65ff3", paddingTop:'0px'}}><span ><AccountCircleIcon fontSize="large"/></span></Link>
                        */}

                        {this.state.typeuser === null?

                          <Flip Bottom>
                            <Nav.Link href="/Inscription" to="/Inscription" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-84} duration={500}>Inscription gratuite</Nav.Link> 
                          </Flip>

                        :  

                          null

                        }                                  

                        {this.state.typeuser !== null?

                        <Nav.Link onClick={() => this.cleardatauser("True")} activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-100} duration={500}>Se déconnecter</Nav.Link>

                        :  
                          <div className="App centerdiv"> 
                              <Nav.Link href="/Login" to="/Login" activeClass="active" className="nav-link" style={{marginLeft:isMobile?"32%":"0%",color:"white", backgroundColor: "#FF7E5A", borderRadius:"8px",width: "148px",height:"46px", textAlign:"center"}}  spy={true} smooth={true} offset={-100} duration={500}>Se connecter</Nav.Link>      
                          </div>
                        

                        }  

                    </Nav>
                  </Navbar.Collapse>
              </Navbar>

              </div>

            </div>
          
          );
        }
    
}

export default Menu;
