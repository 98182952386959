import './moncss.css';
import React,  {Component} from 'react';
import { MapContainer as LeafletMap, TileLayer, Marker} from "react-leaflet";
import L from "leaflet";
import { Redirect } from 'react-router-dom';
import * as ls from "local-storage";
import { Nav,Button,Modal} from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';
import queryString from 'query-string';
import { isExpired, decodeToken  }from "react-jwt";
import DatePicker,{registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import {API_URL} from '../constants';
import dateFormat from "dateformat"

registerLocale('fr', fr)

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;

const positioncenter = [48.564609, 3.301917];
const initialZoom = 8;

const markernounou = new L.icon({
  iconUrl: process.env.PUBLIC_URL + "NOUNOU-MARKER.png",
  iconSize: [50, 82],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const markerparent = new L.icon({
  iconUrl: process.env.PUBLIC_URL + "PARENT-MARKER.png",
  iconSize: [50, 82],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const markerentreprise= new L.icon({
  iconUrl: process.env.PUBLIC_URL + "ENTREPRISE.png",
  iconSize: [50, 82],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

class Carte extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      signin : true,
      modalinfo: false,
      modalcommande:false,
      numeroinscription:0,
      typeuser:'',
      iduserdetails:0,
      nom: '',
      prenoms: '',
      nationalite:'',
      telephone: '',
      mail:'',
      datedenaissance :'',
      experience: '',
      permis: 0,
      vehicule: 0,
      typedegarde:0,
      heuregarde:'',
      heuregardede:0,
      heuregardea:0,
      heuredebutgarde:0,
      heurefingarde:0,
      informationcommande:'',
      listnounou:[],
      listparent:[],
      listentreprise:[],
      typeadmin:false,
      accessdata:false,
      startgarde:'',
      endgarde:'',
    };
        
  }
    
    componentDidMount() {
        
        
    };

    async componentWillMount() {

      document.title = "Carte";

      window.scrollTo(0, 0);

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    this.setState({ accessdata:userdata[5]})

    if(userdata[2] !== 'admin'){

    this.setState({ typeuser: 'user'})
  
    }else{
  
      this.setState({ typeuser: userdata[2]})
  
    }

        if(userdata[2] == 'admin'){
          this.setState({ typeadmin:true})
        }

        const responsenounoulist = await fetch(`${API_URL}/nounou/selectallnounoucarte.php`,{                       
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
          });
        const datanounou = await responsenounoulist.json();
        if(JSON.stringify(datanounou).replace(/\"/g, "") !== 'NOT'){
          this.setState({ listnounou: datanounou})
        }  

        const responseparentlist = await fetch(`${API_URL}/parent/selectallparentcarte.php`,{                       
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
          });
        const dataparent = await responseparentlist.json();
        if(JSON.stringify(dataparent).replace(/\"/g, "") !== 'NOT'){
          this.setState({ listparent: dataparent})
        } 

        const responseentrepriselist = await fetch(`${API_URL}/entreprise/selectallentreprisecarte.php`,{                       
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
          });
        const dataentreprise = await responseentrepriselist.json();
        if(JSON.stringify(dataentreprise).replace(/\"/g, "") !== 'NOT'){
          this.setState({ listentreprise: dataentreprise})
        } 

        this.setState({ signin: true})
        return;
  
    }

    handleClick (data,type,iduserdetails,nom,prenoms,nationalite,contact,mail,datedenaissance,experience,permis,vehicule,garde,heuregarde,infosup) {

      this.setState({ 
        modalinfo: true,
        numeroinscription: data,
        typeuser: type,
        iduserdetails:iduserdetails,
        nom: nom,
        prenoms: prenoms,
        nationalite:nationalite,
        telephone: contact,
        mail:mail,
        datedenaissance : datedenaissance,
        experience: experience,
        permis: permis,
        vehicule: vehicule,
        typedegarde:garde,
        heuregarde:heuregarde,
        infosup:infosup,
      })

    }

    commandemodal(){
      this.setState({ 
        modalinfo: false,
        modalcommande: true,
      })

    }

    testtoken(){
      
      let token = ls.get('token');

      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        alert("Session expiré! Veuillez vous reconnecter.")
        ls.clear()
        this.setState({ typeuser: null})   
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        alert("Session expiré! Veuillez vous reconnecter.")
        ls.clear()
        this.setState({ typeuser: null}) 
        document.location.href = '/login';
        return;
      }

    }

    reservationnounou() {

      if(this.state.startgarde>=this.state.endgarde){

        alert("La date de début est supérieur à la date de fin de garde. Veuillez rectifier les dates.")
        return;

      }

      this.testtoken();

      var datestart = dateFormat(this.state.startgarde, "yyyy-mm-dd HH:MM:SS").toString().replaceAll("thth", "00");

      var dateend = dateFormat(this.state.endgarde, "yyyy-mm-dd HH:MM:SS").toString().replaceAll("thth", "00");

      let token = ls.get('token');

      const myDecodedToken = decodeToken(token);

      const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");
    
      var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
      var originaldata = bytes.toString(CryptoJS.enc.Utf8);
      var userdata  = originaldata.split('-');
      
      //return;

      fetch(`${API_URL}/commandenounou/ajoutcommande.php`,{                  
            mode:'cors',
            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
            body: queryString.stringify({
              typeuser : userdata[2],
              Email: userdata[4],
              idnounou: this.state.iduserdetails,
              mailnounoucommande: this.state.mail,
              nomnounou: this.state.nom + " " + this.state.prenoms,
              Heurede: datestart,
              Heurea: dateend,
              informationcommande: this.state.informationcommande,
            }),
    
        })
        .then(res => {

          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {

            this.setState({ 
              modalcommande: false,
              numeroinscription: "",
              typeuser: "",
              iduserdetails:0,
              informationcommande:'',
              nom: "",
              prenoms: "",
              nationalite:"",
              telephone: "",
              mail:"",
              datedenaissance : "",
              experience: "",
              permis: "",
              vehicule: "",
              typedegarde:"",
              heuregardede:"",
              heuregardea:"",
              heuregarde:"",
            });

            alert("Commande bien reçu. Ne revenons vers vous dans le plus bref délais.")
    
        })
        .catch(error => {
              alert("Erreur :" + errocode + ", "+error)
              this.setState({ listsound: [], soundname :'', idaudioselect :0, isModaldelete : false });
    
        });   
        
    };  
    
    changestartdate(date){

      this.setState({ startgarde: date});
      
    }

    changeenddate(date){

      this.setState({ endgarde: date});
      
    }
   
    
  render() {


          return (
              
                <div className="App" style={{paddingTop:"90px"}}>

                        {this.state.signin == false ?
                            

                            <Redirect push to="/Accueil"/>
                              
                            :

                            null

                        }

                        {this.state.modalinfo === true ?

                        <Modal

                        show={this.state.modalinfo}
                        onHide={ () => this.setState({modalinfo:false})}
                        animation={false}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>

                            <div className="d-flex justify-content-end">
                            <button className="btn btntransparent" onClick={() => this.setState({modalinfo:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                            </div>

                          

                          <div className="d-flex flex-row justify-content-center">

                            {this.state.accessdata == 'no'?
                            
                              <div >
                                <div className="d-flex justify-content-center">
                                  <p>Veuillez choisir votre forfait afin d'accéder aux informations.</p>
                                </div >

                                <div className="d-flex justify-content-center">
                                <Nav.Link href="/Parents" to="/Parents" activeClass="active" className="nav-link" style={{color:"white", backgroundColor: "#FF7E5A", borderRadius:"8px",width: "148px",height:"46px", textAlign:"center"}}  spy={true} smooth={true} offset={-100} duration={500}>Choisir un offre</Nav.Link>      
                                </div>
                                    
                                    
                              </div>

                              :

                              <div className="d-flex flex-column">
                                  
                                  <div className="d-flex flex-column" style={{fontSize:"16px"}}>
                                      {this.state.typeuser === "NOUNOU" ?

                                        <label style={{color:"#e99dcb", fontWeight:'bold',textAlign:"center"}}>{this.state.typeuser}</label>

                                        :
                                        
                                        null
                                    
                                      }

                                      {this.state.typeuser === "PARENT" ?

                                      <label style={{color:"#e67e22", fontWeight:'bold',textAlign:"center"}}>{this.state.typeuser}</label>

                                      :

                                      null

                                      }

                                      {this.state.typeuser === "ENTREPRISE" ?

                                        <label style={{color:"#3498db", fontWeight:'bold',textAlign:"center"}}>{this.state.typeuser}</label>

                                      :

                                      null

                                      }

                                      {this.state.typeuser !== "ENTREPRISE" ?

                                        <div >                                     
                                          <p style={{textAlign:"center", fontWeight:'bold'}}>{this.state.prenoms}  {this.state.nom}</p>
                                          <p style={{textAlign:"left"}}>Nationalité  {this.state.nationalite}</p>
                                        </div>

                                        :

                                        null

                                      }

                                      {this.state.typeuser === "NOUNOU" ?

                                        <div >
                                            <p style={{textAlign:"left"}}>Née le {this.state.datedenaissance}</p>
                                            <p style={{textAlign:"left"}}>Expérience: {parse(this.state.experience)}</p>
                                            <p style={{textAlign:"left"}}>Véhiculé: {this.state.vehicule == "0" ? "oui":"non" }</p>
                                            <p style={{textAlign:"left"}}>Disponibilité: {this.state.heuregarde}</p>
                                        </div>

                                        :

                                        null

                                    }

                                    {this.state.typeuser === "ENTREPRISE" ?

                                      <div className="d-flex flex-column align-items-center">
                                        <p style={{textAlign:"center", fontWeight:'bold'}}>{this.state.nom}</p>
                                      </div>

                                      :

                                      null

                                    }

                                    {this.state.typeadmin == true ? 
                                      
                                      <p>Téléphone: <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + this.state.telephone}>{this.state.telephone}</a></p>

                                      :

                                      null
                                  
                                    }

                                    {this.state.typeadmin == true ? 
                                      
                                      <p>Mail: <a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + this.state.mail}>{this.state.mail}</a></p>

                                      :

                                      null
                                  
                                    }

                                    {this.state.typeuser === "NOUNOU" ?

                                      <div >
                                          <p style={{textAlign:"left"}}>Informations supplémentaires: <br/>{this.state.infosup}</p>
                                      </div>

                                      :

                                      null

                                    }

                                    {this.state.typeuser === "PARENT" ?

                                      <div >
                                          <label>Informations supplémentaires concernant la garde de l'enfant:<br/>{this.state.infosup}</label>
                                      </div>

                                      :

                                      null

                                    }

                                    {this.state.typeuser === "ENTREPRISE" ?

                                    <div >
                                      <label>Informations supplémentaires concernant la garde de l'enfant:<br/>{this.state.infosup}</label>
                                    </div>

                                    :

                                    null

                                    }

                                    {this.state.typeuser === "NOUNOU" ?

                                    <div className='d-flex flex-column align-items-center'>
                                                  
                                      <Button className="btn"style={{ 
                                            background: '#FF7E5A',
                                            border: 0,
                                            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                            height: 48,
                                            width:200,
                                            borderRadius:'8px',fontSize:"22px"}} onClick={ () => this.commandemodal()} variant="primary">Contacter</Button>
                                                                            
                                    </div>

                                    :

                                    null

                                    }

                                  </div>

                                  </div>

                            }
                                          
                              
                            </div>    

                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }

                        {this.state.modalcommande === true ?

                        <Modal

                        show={this.state.modalcommande}
                        onHide={ () => this.setState({modalcommande:false})}
                        animation={false}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>

                            <div className="d-flex justify-content-end">
                            <button className="btn btntransparent" onClick={() => this.setState({modalcommande:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                            </div>

                              <div className="d-flex flex-column align-items-center" style={{fontSize:"22px"}}>

                                  <div className='d-flex flex-column align-items-center' style={{margin:'10px'}}>
                                    <label>Date et heure de début de garde</label>
                                      <DatePicker
                                        showIcon
                                        style={{textAlign:"center"}}
                                        selected={this.state.startgarde}
                                        onChange={(date) => this.changestartdate(date)}
                                        locale="fr"
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                        isClearable
                                      />
                                  </div>

                                  <div className='d-flex flex-column align-items-center' style={{margin:'10px'}}>
                                    <label>Date et heure de fin de garde</label>
                                      <DatePicker
                                        showIcon
                                        selected={this.state.endgarde}
                                        onChange={(date) => this.changeenddate(date)}
                                        locale="fr"
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                        isClearable
                                      />
                                  </div>

                                  <div style={{margin:'10px'}}>
                                    <label>Information supplémentaire</label>
                                    <textarea rows={4} style={{width:"100%",fontSize:"16px", padding:"10px"}} type="text" value={this.state.informationcommande} onChange={(e) => this.setState({ informationcommande: e.target.value})} />
                                  </div>

                                  <div className='row d-flex align-items-center'>
                                                
                                    <Button className="btn"style={{ margin:"20px",
                                          background: '#FF7E5A',
                                          border: 0,
                                          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                          height: 48,
                                          width:200,
                                          borderRadius:'8px',fontSize:"22px"}} onClick={ () => this.reservationnounou()} variant="primary">Valider</Button>
                                                                          
                                  </div>

                              </div>  

                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }
                 
                  <LeafletMap

                        center={positioncenter}
                        zoom={initialZoom}
                        style={{height: "80vh", width: "100%", paddingTop:"50px"}}
                      >
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                       
                        {this.state.listnounou.length >0 ?

                            <div>
                              {this.state.listnounou.map((row) => (
                                <Marker key={row.idnounou} position={JSON.parse('['+ row.latitude +',' +row.longitude+']')} icon={markernounou} data={row.idnounou} eventHandlers={{
                                  click: (e) => {
                                    this.handleClick(e.target.options.data,'NOUNOU',row.idnounou,row.nomnounou,row.prenomsnounou,row.nationalite,row.telephone,row.email,row.datedenaissance,row.experience,row.permis,row.vehicule,row.garde,row.heuregarde,row.infosup);
                                  },
                                }}></Marker>
                                ))}
                            </div>

                          :

                          null

                        }
                        
                        {this.state.listparent.length >0 ?

                          <div>
                            {this.state.listparent.map((row) => (
                              <Marker key={row.idparent} position={JSON.parse('['+ row.latitude +',' +row.longitude+']')} icon={markerparent} data={row.idparent} eventHandlers={{
                                click: (e) => {
                                  this.handleClick(e.target.options.data,'PARENT',row.idparent,row.nomparent,row.prenomparent,row.nationalite,row.telephone,row.email,'','','','','','','',row.infosupparent);
                                },
                              }}></Marker>
                              ))}
                          </div>

                          :

                          null

                        }

                        {this.state.listentreprise.length >0 ?

                          <div>
                            {this.state.listentreprise.map((row) => (
                              <Marker key={row.identreprise} position={JSON.parse('['+ row.latitude +',' +row.longitude+']')} icon={markerentreprise} data={row.idparent} eventHandlers={{
                                click: (e) => {
                                  this.handleClick(e.target.options.data,'ENTREPRISE',row.identreprise,row.nomentreprise,row.numerosiret,'',row.telephone,row.email,'','','','','','','',row.infosupentreprise);
                                },
                              }}></Marker>
                              ))}
                          </div>

                          :

                          null

                        }
                        
                  </LeafletMap>
                 
                    
                </div>
          );
        }
    
}

export default Carte;
