import './App.css';
import './moncss.css';
import React, {Component} from 'react';
import {Button, Form, Row, Container, Col} from 'react-bootstrap';
import axios from 'axios';
import {BrowserView, MobileView, isBrowser} from "react-device-detect";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import queryString from 'query-string';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import Loader from "react-js-loader";
import * as ls from "local-storage";
import {isExpired, decodeToken} from "react-jwt";
import {API_URL} from '../constants';

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;

var dateselect = new Date();
var dateselectenfant = new Date();

class Profil extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lienimage: process.env.PUBLIC_URL + 'images/',
            idreferral: props.match?.params?.referralid ?? 0,
            nomcontact: '',
            mailcontact: '',
            titrecontact: '',
            messagecontact: '',
            isModalVisible: false,
            editorState: '',
            montant: 0.0,
            lng: 'FR',
            titre: '',
            usertype: 0,
            iduser: 0,
            codepostale: '',
            ville: '',
            adresse: '',
            nom: '',
            prenoms: '',
            nationalite: '',
            mail: '',
            motdepasse: '',
            societe: 0,
            telephone: '',
            poste: '',
            diplome: 0,
            datedenaissance: '',
            reference: 0,
            experience: '',
            permis: 0,
            vehicule: 0,
            typedegarde: 0,
            heuregarde: '',
            heuregardede: 0,
            heuregardea: 0,
            ismodalinscription: false,
            errorform: false,
            errormessage: '',
            listconnaissance: [],
            connaisancennl: 0,
            selectedFileCV: null,
            selectedFileCVitale: null,
            selectedFilediplome: null,
            selectedFilepermis: null,
            selectedFilecj: null,
            selectedFilePITJ: null,
            lienCV: '',
            liendiplome: '',
            lienpermis: '',
            liencj: '',
            inscriptioneffectue: false,
            loadershow: false,
            prenomsenfant: '',
            datedenaissanceenfant: '',
            errorformenfant: false,
            errormessageenfant: '',
            idselectenfant: -1,
            signin: false,
            tokenuser: [],
            listtypegarde: [],
            listtypeutilisateur: [],
            cachelistenfant: [],
            listenfant: [],
            idtypeuser: 1,
            show_input: 'text',
            datauserfull: [],
            numerosiret: '',
            selectedFileURSSAF: null,
            selectedFileFichedeposte: null,
            selectedFileKBIS: null,
            lienURSSAF: '',
            lienFichedeposte: '',
            lienKBIS: '',
            lienpitj: '',
            liencvitale: '',
            contratgarde: [],
            listtypegardeenfant: [],
            usercontratgarde: 1,
            userenfantgarde: 1,
        };

        this.selectusertypegarde = this.selectusertypegarde.bind(this);
        this.selectusertypegardenfant = this.selectusertypegardenfant.bind(this);
        this.selectusertypecontratgarde = this.selectusertypecontratgarde.bind(this);
        this.selectusertypeutilisateur = this.selectusertypeutilisateur.bind(this);
        this.selectconnaissance = this.selectconnaissance.bind(this);

    }

    async componentDidMount() {
        document.title = " Mon profil"
        let token = ls.get('token');
        const myDecodedToken = decodeToken(token);
        if (myDecodedToken == null) {
            ls.clear()
            this.setState({typeuser: null})
            document.location.href = '/login';
            return;
        }
        const isMyTokenExpired = isExpired(token);

        if (isMyTokenExpired === true) {
            ls.clear()
            this.setState({typeuser: null})
            document.location.href = '/login';
            return;
        }

        const datauser = JSON.stringify(myDecodedToken.id).replace(/"/g, "");

        var bytes = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+').replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
        var originaldata = bytes.toString(CryptoJS.enc.Utf8);
        var userdata = originaldata.split('-');

        this.setState({idtypeuser: parseInt(userdata[2]), iduser: parseInt(userdata[3])});

        const responselistcontratgarde = await fetch(`${API_URL}/garde/selectallcontratgarde.php`);
        const datalistcontratgarde = await responselistcontratgarde.json();
        this.setState({listcontratgarde: datalistcontratgarde})

        const responselisttypegardeenfant = await fetch(`${API_URL}/garde/selectalltypegarde.php`);
        const datalisttypegardeenfant = await responselisttypegardeenfant.json();
        this.setState({listtypegardeenfant: datalisttypegardeenfant})

        const responsetypegarde = await fetch(`${API_URL}/garde/selectallgarde.php`);
        const datatypegarde = await responsetypegarde.json();
        this.setState({listtypegarde: datatypegarde})

        const datauserselect = await fetch(`${API_URL}/utilisateur/selectutilisateur.php`, {
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: queryString.stringify({
                mailutilisateur: userdata[4].replace(/tirait/g, '-'),
                typeuser: userdata[2],
                iduser: userdata[3],
            })
        })

        const dataupdateuser = await datauserselect.json();

        if (dataupdateuser.length > 0) {
            if (parseInt(userdata[2]) === 2) {
                this.setState({
                    datauserfull: dataupdateuser,
                    idtypeuser: userdata[2],
                    codepostale: JSON.stringify(dataupdateuser[0].codepostale).replace(/"/g, ""),
                    ville: JSON.stringify(dataupdateuser[0].ville).replace(/"/g, ""),
                    adresse: JSON.stringify(dataupdateuser[0].adresse).replace(/"/g, ""),
                    nom: JSON.stringify(dataupdateuser[0].nomnounou).replace(/"/g, ""),
                    prenoms: JSON.stringify(dataupdateuser[0].prenomsnounou).replace(/"/g, ""),
                    nationalite: JSON.stringify(dataupdateuser[0].nationalite).replace(/"/g, ""),
                    telephone: JSON.stringify(dataupdateuser[0].telephone).replace(/"/g, ""),
                    diplome: 0,
                    datedenaissance: JSON.stringify(dataupdateuser[0].datedenaissance).replace(/"/g, ""),
                    reference: parseInt(JSON.stringify(dataupdateuser[0].reference).replace(/"/g, "")),
                    experience: JSON.stringify(dataupdateuser[0].experience).replace(/"/g, ""),
                    permis: 0,
                    vehicule: parseInt(JSON.stringify(dataupdateuser[0].vehicule).replace(/"/g, "")),
                    typedegarde: parseInt(JSON.stringify(dataupdateuser[0].garde).replace(/"/g, "")),
                    heuregarde: JSON.stringify(dataupdateuser[0].heuregarde).replace(/"/g, ""),
                    infosup: JSON.stringify(dataupdateuser[0].infosup).replace(/"/g, ""),
                });

                if (JSON.stringify(dataupdateuser[0].pitj).replace(/"/g, "")) {
                    this.setState({
                        lienpitj: 'https://www.nannylou.com/mobilewbs/nounou/' + JSON.stringify(dataupdateuser[0].pitj).replace(/"/g, ""),
                    })
                }

                if (JSON.stringify(dataupdateuser[0].cvitale).replace(/"/g, "")) {
                    this.setState({
                        liencvitale: 'https://www.nannylou.com/mobilewbs/nounou/' + JSON.stringify(dataupdateuser[0].cvitale).replace(/"/g, ""),
                    });
                }

                if (JSON.stringify(dataupdateuser[0].cv).replace(/"/g, "")) {
                    this.setState({
                        lienCV: 'https://www.nannylou.com/mobilewbs/nounou/' + JSON.stringify(dataupdateuser[0].cv).replace(/"/g, ""),
                    });
                }

                if (JSON.stringify(dataupdateuser[0].diplome).replace(/"/g, "")) {
                    this.setState({
                        liendiplome: 'https://www.nannylou.com/mobilewbs/nounou/' + JSON.stringify(dataupdateuser[0].diplome).replace(/"/g, ""),
                    });
                }

                if (JSON.stringify(dataupdateuser[0].cj).replace(/"/g, "")) {
                    this.setState({
                        liencj: 'https://www.nannylou.com/mobilewbs/nounou/' + JSON.stringify(dataupdateuser[0].cj).replace(/"/g, ""),
                    });
                }

                if (JSON.stringify(dataupdateuser[0].permis).replace(/"/g, "")) {
                    this.setState({
                        lienpermis: 'https://www.nannylou.com/mobilewbs/nounou/' + JSON.stringify(dataupdateuser[0].permis).replace(/"/g, ""),
                    });
                }

                dateselect = new Date(JSON.stringify(dataupdateuser[0].datedenaissance).replace(/"/g, ""));
            }

            if (parseInt(userdata[2]) == 3) {
                this.setState({
                    datauserfull: dataupdateuser,
                    idtypeuser: userdata[2],
                    codepostale: JSON.stringify(dataupdateuser[0].codepostal).replace(/"/g, ""),
                    ville: JSON.stringify(dataupdateuser[0].ville).replace(/"/g, ""),
                    adresse: JSON.stringify(dataupdateuser[0].adresse).replace(/"/g, ""),
                    nom: JSON.stringify(dataupdateuser[0].nomparent).replace(/"/g, ""),
                    prenoms: JSON.stringify(dataupdateuser[0].prenomparent).replace(/"/g, ""),
                    telephone: JSON.stringify(dataupdateuser[0].telephone).replace(/"/g, ""),
                    usercontratgarde: parseInt(JSON.stringify(dataupdateuser[0].contratgarde).replace(/"/g, "")),
                    userenfantgarde: parseInt(JSON.stringify(dataupdateuser[0].gardeenfant).replace(/"/g, "")),
                    infosup: JSON.stringify(dataupdateuser[0].infosupparent).replace(/"/g, ""),
                    nationalite: dataupdateuser[0].nationalite
                });

                const enfants = await fetch(`${API_URL}/parent/selectenfant.php`, {
                    method: 'post',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: queryString.stringify({idparent: userdata[3]})
                }).then(res => res.json());
                if (enfants && enfants.length) {
                    this.setState({
                        listenfant: enfants.map(e => {
                            return {
                                prenomenfant: e.prenom,
                                datenaisanceenfant: e.datenaissance
                            }
                        })
                    });
                }
            }

            if (parseInt(userdata[2]) == 4) {


                if (JSON.stringify(dataupdateuser[0].urssaf).replace(/"/g, "") != '') {

                    this.setState({
                        lienURSSAF: 'https://www.nannylou.com/mobilewbs/entreprise/' + JSON.stringify(dataupdateuser[0].urssaf).replace(/"/g, ""),
                    })

                }

                if (JSON.stringify(dataupdateuser[0].fichedeposte).replace(/"/g, "") != '') {

                    this.setState({
                        lienFichedeposte: 'https://www.nannylou.com/mobilewbs/entreprise/' + JSON.stringify(dataupdateuser[0].fichedeposte).replace(/"/g, ""),
                    })

                }

                if (JSON.stringify(dataupdateuser[0].kbis).replace(/"/g, "") != '') {

                    this.setState({
                        lienKBIS: 'https://www.nannylou.com/mobilewbs/entreprise/' + JSON.stringify(dataupdateuser[0].kbis).replace(/"/g, ""),
                    })

                }

                this.setState({
                    datauserfull: dataupdateuser,
                    idtypeuser: userdata[2],
                    nom: JSON.stringify(dataupdateuser[0].nomentreprise).replace(/"/g, ""),
                    numerosiret: JSON.stringify(dataupdateuser[0].numerosiret).replace(/"/g, ""),
                    codepostale: JSON.stringify(dataupdateuser[0].codepostal).replace(/"/g, ""),
                    ville: JSON.stringify(dataupdateuser[0].ville).replace(/"/g, ""),
                    adresse: JSON.stringify(dataupdateuser[0].adresse).replace(/"/g, ""),
                    telephone: JSON.stringify(dataupdateuser[0].telephone).replace(/"/g, ""),
                    usercontratgarde: parseInt(JSON.stringify(dataupdateuser[0].contratgarde).replace(/"/g, "")),
                    userenfantgarde: parseInt(JSON.stringify(dataupdateuser[0].gardeenfant).replace(/"/g, "")),
                    infosup: JSON.stringify(dataupdateuser[0].infosupentreprise).replace(/"/g, ""),
                })

            }

        }
        registerLocale('fr', fr);
    };

    componentDidUpdate(prevProps) {

        //window.scrollTo(0, 0)

    };

    selectusertypegarde(e) {
        this.setState({typedegarde: e.target.value});
    }

    selectusertypegardenfant(e) {
        this.setState({userenfantgarde: e.target.value});
    }

    selectusertypecontratgarde(e) {
        this.setState({usercontratgarde: e.target.value});
    }

    selectusertypeutilisateur(e) {
        this.setState({idtypeuser: e.target.value});
    }

    selectconnaissance(e) {
        this.setState({connaisancennl: e.target.value});
    }


    selectdate = (datevalue) => {

        dateselect = new Date(datevalue);

        var moisdenaissance = '';

        if (parseInt(datevalue.getMonth()) < 9) {
            moisdenaissance = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
        } else {
            moisdenaissance = (parseInt(datevalue.getMonth()) + 1)
        }

        var jourdenaissance = '';
        if (parseInt(datevalue.getDate()) < 9) {
            jourdenaissance = '0' + (parseInt(datevalue.getDate()))
        } else {
            jourdenaissance = (parseInt(datevalue.getDate()))
        }
        var datenaissance = datevalue.getFullYear() + '-' + moisdenaissance + '-' + jourdenaissance;


        //alert('THIS new date : ' + datenaissance)
        this.setState({datedenaissance: datenaissance})

        //alert(moisdenaissance)
    }

    selectdateenfant = (datevalue) => {

        dateselectenfant = new Date(datevalue);

        var moisdenaissance = '';

        if (parseInt(datevalue.getMonth()) < 9) {
            moisdenaissance = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
        } else {
            moisdenaissance = (parseInt(datevalue.getMonth()) + 1)
        }

        var jourdenaissance = '';
        if (parseInt(datevalue.getDate()) < 9) {
            jourdenaissance = '0' + (parseInt(datevalue.getDate()))
        } else {
            jourdenaissance = (parseInt(datevalue.getDate()))
        }
        var datenaissance = datevalue.getFullYear() + '-' + moisdenaissance + '-' + jourdenaissance;


        //alert('THIS new date : ' + datenaissance)
        this.setState({datedenaissanceenfant: datenaissance})

        //alert(moisdenaissance)
    }


    verifieradresse() {

        if (this.state.nom == "" && this.state.prenoms == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre nom ou prénoms.'});

            return;

        }

        if (this.state.nationalite == "" && this.state.idtypeuser === '3') {

            this.setState({
                errorform: true,
                errormessage: 'Veuillez saisir votre nationalité ou votre titre de séjour français.'
            });

            return;

        }

        if (this.state.datedenaissance == "" && this.state.idtypeuser === '2') {

            this.setState({errorform: true, errormessage: 'Date de naissance invalide.'});

            return;

        }

        if (this.state.telephone == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre numero de téléphone.'});

            return;

        }

        if (this.state.codepostale == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre code postale.'});

            return;

        }

        if (this.state.ville == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre ville.'});

            return;

        }

        if (this.state.adresse == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre adresse.'});

            return;

        }

        if (this.state.idtypeuser === '2') {
            if (!this.state.experience) {

                this.setState({errorform: true, errormessage: 'Veuillez saisir votre expérience.'});

                return;

            }

            if (!this.state.typedegarde) {
                this.setState({errorform: true, errormessage: 'Veuillez saisir votre temps de garde.'});
                return;
            }

            if (this.state.heuregarde.length === 0) {

                this.setState({errorform: true, errormessage: 'Veuillez saisir l\'heure de garde.'});

                return;

            }

            if (this.state.heuregarde.length === 0) {
                this.setState({errorform: true, errormessage: 'Veuillez saisir l\'heure de garde.'});
                return;
            }

            if (!this.state.selectedFilecj) {
                this.setState({errorform: true, errormessage: 'Veuillez fournir votre casier judiciaire.'});
                return;
            }

            if (!this.state.selectedFilePITJ) {
                this.setState({errorform: true, errormessage: 'Veuillez fournir une pièce d\'identité ou votre justification de titre de séjour.'});
                return;
            }

            if (!this.state.selectedFileCV) {
                this.setState({errorform: true, errormessage: 'Veuillez fournir votre CV.'});
                return;
            }
        } else if (this.state.idtypeuser === '3') {
            if (this.state.cachelistenfant.length === 0) {
                this.setState({errorform: true, errormessage: 'Veuillez entrer au moins une enfant.'});
                return;
            }

            if (this.state.infosup.length === 0) {

                this.setState({
                    errorform: true,
                    errormessage: 'Veuillez remplir l\'information supplémtaire sur la garde de l\'enfant.'
                });

                return;

            }

            if (this.state.listenfant.length === 0) {

                this.setState({errorform: true, errormessage: 'Veuillez entrer au moins une enfant.'});

                return;

            }

            if (this.state.userenfantgarde === 1) {

                this.setState({errorform: true, errormessage: 'Veuillez sélectionner le type de garde de l\'enfant.'});

                return;

            }

            if (this.state.usercontratgarde === 1) {

                this.setState({
                    errorform: true,
                    errormessage: 'Veuillez sélectionner le type de contrat de garde de l\'enfant.'
                });

                return;

            }
        }

        if (this.state.usercontratgarde == 1 && this.state.idtypeuser === '4') {

            this.setState({
                errorform: true,
                errormessage: 'Veuillez sélectionner le type de  contrat de garde de l\'enfant.'
            });

            return;

        }

        this.setState({loadershow: true});

        this.verifiecoordonnee();

    }

    async verifiecoordonnee() {

        var adressecomplet = 'https://api-adresse.data.gouv.fr/search/?q=' + this.state.codepostale.replaceAll(' ', '+') + this.state.ville.replaceAll(' ', '+') + this.state.adresse.replaceAll(' ', '+') + '&limit=1&autocomplete=0';

        const responsecoordonnee = await fetch(adressecomplet);
        const datalatlong = await responsecoordonnee.json();

        if (datalatlong.features.length === 0) {

            this.setState({loadershow: false});

            alert("Veuillez rectifier votre adresse, code postale et ville car il n'est pas reconnu.")

            return;

        } else {

            var latlong = JSON.stringify(datalatlong.features[0].geometry.coordinates).replace('[', '').replace(']', '');
            this.modification(latlong);

        }

    }

    testtoken() {

        let token = ls.get('token');

        const myDecodedToken = decodeToken(token);

        if (myDecodedToken == null) {
            alert("Session expiré! Veuillez vous reconnecter.")
            ls.clear()
            this.setState({typeuser: null})
            document.location.href = '/login';
            return;
        }
        const isMyTokenExpired = isExpired(token);

        if (isMyTokenExpired == true) {
            alert("Session expiré! Veuillez vous reconnecter.")
            ls.clear()
            this.setState({typeuser: null})
            document.location.href = '/login';
            return;
        }

    }

    modification(latlong = '') {

        this.testtoken();

        const token = ls.get('token');

        const latlongsplit = latlong ? latlong.split(',') : [];

        var idref = 0;

        if (!this.state.idreferral || this.state.idreferral == '0') {
            idref = 0;
        } else {
            idref = parseInt(this.state.idreferral);
        }

        var dateinscription = new Date();

        var moisinscription = '';

        if (parseInt(dateinscription.getMonth()) < 9) {
            moisinscription = '0' + (parseInt(dateinscription.getMonth()) + parseInt(1))
        } else {
            moisinscription = parseInt(dateinscription.getMonth()) + parseInt(1)
        }

        var jourinscription = '';
        if (parseInt(dateinscription.getDate()) < 9) {
            jourinscription = '0' + (parseInt(dateinscription.getDate()) + parseInt(1))
        } else {
            jourinscription = parseInt(dateinscription.getDate())
        }
        var dateinscriptionsend = dateinscription.getFullYear() + '-' + moisinscription + '-' + jourinscription;

        //var iduser = ls.get('iduser');

        if (this.state.idtypeuser === '2') {

            fetch(`${API_URL}/nounou/modifnounou.php`, {

                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded', 'authentification': 'Bearer ' + token},
                body: queryString.stringify({
                    idnounou: this.state.iduser,
                    Nomnounou: this.state.nom,
                    Prenomsnounou: this.state.prenoms,
                    Nationalite: this.state.nationalite,
                    Telephone: this.state.telephone,
                    Adresse: this.state.adresse,
                    Latitude: latlongsplit[1] ?? null,
                    Longitude: latlongsplit[0] ?? null,
                    Codepostale: this.state.codepostale,
                    Ville: this.state.ville,
                    Datedenaissance: this.state.datedenaissance,
                    Reference: Number(this.state.reference),
                    Experience: this.state.experience,
                    Vehicule: Number(this.state.vehicule),
                    Garde: this.state.typedegarde,
                    heuregarde: this.state.heuregarde,
                    infosup: this.state.infosup,

                })

            })

                .then(res => {

                    errocode = res.status;

                    if (!res.ok) {

                        return res.json().then(json => {
                            throw json;
                        });

                    }

                    return res.json();

                })
                .then(data => {

                    if (JSON.stringify(data).replace(/"/g, "") === 'Not') {
                        this.setState({errorform: true, errormessage: 'Erreur de données.'});

                        return;
                    }

                    this.setState({
                        loadershow: true,
                        errorform: false,
                        errormessage: '',
                        usertype: 0,
                        nom: '',
                        prenoms: '',
                        nationalite: '',
                        mail: '',
                        motdepasse: '',
                        societe: 0,
                        telephone: '',
                        poste: '',
                        adresse: '',
                        codepostale: '',
                        ville: '',
                        permis: 0,
                        diplome: 0,
                        experience: '',
                        reference: 0,
                        vehicule: 0,
                        idtypeuser: 2,
                        typedegarde: '',
                        heuregarde: '',
                        heuregardede: 0,
                        heuregardea: 0,
                        datedenaissance: dateselect,
                        inscriptioneffectue: true,
                        cachelistenfant: [],
                        listenfant: [],
                    });

                    if (this.state.selectedFilePITJ !== null) {

                        // Create an object of formData
                        const formDatapitj = new FormData();

                        // Update the formData object

                        formDatapitj.append(
                            "images",
                            this.state.selectedFilePITJ,
                            this.state.selectedFilePITJ.name,
                        );

                        formDatapitj.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutpitj.php`, formDatapitj)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFilePITJ: null,
                                    });

                                }

                            })

                    }


                    if (this.state.selectedFileCVitale !== null) {

                        // Create an object of formData
                        const formDatacvitale = new FormData();

                        // Update the formData object
                        formDatacvitale.append(
                            "images",
                            this.state.selectedFileCVitale,
                            this.state.selectedFileCVitale.name,
                        );

                        formDatacvitale.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutcvitale.php`, formDatacvitale)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileCVitale: null,
                                    });

                                }

                            })

                        //this.ajoutercvitale();

                    }

                    if (this.state.selectedFileCV !== null) {

                        // Create an object of formData
                        const formDatacv = new FormData();

                        // Update the formData object
                        formDatacv.append(
                            "images",
                            this.state.selectedFileCV,
                            this.state.selectedFileCV.name,
                        );


                        formDatacv.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutcv.php`, formDatacv)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileCV: null,
                                    });

                                }

                            })


                    }

                    if (this.state.selectedFilediplome !== null) {

                        // Create an object of formData
                        const formData = new FormData();

                        // Update the formData object
                        formData.append(
                            "images",
                            this.state.selectedFilediplome,
                            this.state.selectedFilediplome.name,
                        );

                        formData.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutdiplome.php`, formData)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFilediplome: null,
                                    });

                                }

                            })

                    }

                    if (this.state.selectedFilepermis !== null) {

                        // Create an object of formData
                        const formData = new FormData();

                        // Update the formData object
                        formData.append(
                            "images",
                            this.state.selectedFilepermis,
                            this.state.selectedFilepermis.name,
                        );

                        formData.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutpermis.php`, formData)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFilepermis: null,
                                    });

                                }

                            })

                    }

                    if (this.state.selectedFilecj !== null) {

                        // Create an object of formData
                        const formData = new FormData();

                        // Update the formData object
                        formData.append(
                            "images",
                            this.state.selectedFilecj,
                            this.state.selectedFilecj.name,
                        );

                        formData.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutcj.php`, formData)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {
                                    this.setState({
                                        selectedFilecj: null,
                                    });

                                }

                            })

                    }

                    this.fermermodalinscription();

                })
                .catch(err => {
                    alert("Erreur :" + errocode + ", " + err)
                    this.setState({tokenuser: err});
                });

        } else if (this.state.idtypeuser === '3') {

            fetch(`${API_URL}/parent/modifparent.php`, {
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded', 'authentification': 'Bearer ' + token},
                body: queryString.stringify({
                    idparent: this.state.iduser,
                    Nomparent: this.state.nom,
                    Prenomparent: this.state.prenoms,
                    Nationalite: this.state.nationalite,
                    Telephone: this.state.telephone,
                    Adresse: this.state.adresse,
                    Latitude: latlongsplit[1] ?? null,
                    Longitude: latlongsplit[0] ?? null,
                    Codepostal: this.state.codepostale,
                    Ville: this.state.ville,
                    Contrat: this.state.usercontratgarde,
                    Garde: this.state.userenfantgarde,
                    infosup: this.state.infosup,
                })

            })

                .then(res => {

                    errocode = res.status;

                    if (!res.ok) {

                        return res.json().then(json => {
                            throw json;
                        });

                    }

                    return res.json();

                })
                .then(data => {

                    if (JSON.stringify(data).replace(/"/g, "") === 'Not') {
                        this.setState({errorform: true, errormessage: 'Erreur de données.'});
                        return;
                    }

                    if (this.state.cachelistenfant.length > 0) {

                        for (var i = 0; i < this.state.cachelistenfant.length; i++) {

                            fetch(`${API_URL}/parent/ajoutenfant.php`, {

                                method: 'post',
                                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                body: queryString.stringify({

                                    Typeparent: 'Parent',
                                    Idpar: this.state.iduser,
                                    Prenom: this.state.cachelistenfant[i].prenomenfant,
                                    Datenaissance: this.state.cachelistenfant[i].datenaisanceenfant,
                                    Dateinscription: dateinscriptionsend,

                                })

                            })

                        }

                    }

                    this.setState({
                        errorform: false,
                        errormessage: '',
                        usertype: 0,
                        nom: '',
                        prenoms: '',
                        mail: '',
                        motdepasse: '',
                        societe: 0,
                        telephone: '',
                        poste: '',
                        cachelistenfant: [],
                        listenfant: [],
                        adresse: '',
                        codepostale: '',
                        ville: '',
                        idtypeuser: 1,
                        loadershow: false,
                        signin: true,
                        inscriptioneffectue: true,
                    });

                })
                .catch(err => {
                    alert("Erreur :" + errocode + ", " + err)
                    this.setState({tokenuser: err});
                });

        } else if (this.state.idtypeuser === '4') {
            fetch(`${API_URL}/entreprise/modifentreprise.php`, {

                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded', 'authentification': 'Bearer ' + token},
                body: queryString.stringify({

                    identreprise: this.state.iduser,
                    Nomentreprise: this.state.nom,
                    Numerosiret: this.state.numerosiret,
                    Telephone: this.state.telephone,
                    Adresse: this.state.adresse,
                    Latitude: latlongsplit[1],
                    Longitude: latlongsplit[0],
                    Codepostal: this.state.codepostale,
                    Ville: this.state.ville,
                    Contrat: this.state.usercontratgarde,
                    Garde: 1,
                    infosup: this.state.infosup,

                })

            })

                .then(res => {

                    errocode = res.status;

                    if (!res.ok) {

                        return res.json().then(json => {
                            throw json;
                        });

                    }

                    return res.json();

                })
                .then(data => {

                    if (JSON.stringify(data).replace(/"/g, "") === 'Not') {
                        this.setState({errorform: true, errormessage: 'Erreur de données.'});
                        return;
                    }

                    this.setState({
                        errorform: false,
                        errormessage: '',
                        usertype: 0,
                        nom: '',
                        numerosiret: '',
                        mail: '',
                        motdepasse: '',
                        societe: 0,
                        telephone: '',
                        poste: '',
                        cachelistenfant: [],
                        listenfant: [],
                        adresse: '',
                        codepostale: '',
                        ville: '',
                        idtypeuser: 1,
                        loadershow: false,
                        signin: true,
                        iduser: this.state.iduser,
                        inscriptioneffectue: true,
                    });

                    if (this.state.selectedFileKBIS !== null) {

                        // Create an object of formData
                        const formDatakbis = new FormData();

                        // Update the formData object
                        formDatakbis.append(
                            "images",
                            this.state.selectedFileKBIS,
                            this.state.selectedFileKBIS.name,
                        );

                        formDatakbis.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/entreprise/ajoutKBIS.php`, formDatakbis)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileKBIS: null,
                                    });

                                }

                            })

                        //this.ajouterKBIS();

                    }

                    if (this.state.selectedFileURSSAF !== null) {

                        // Create an object of formData
                        const formDataurssaf = new FormData();

                        // Update the formData object
                        formDataurssaf.append(
                            "images",
                            this.state.selectedFileURSSAF,
                            this.state.selectedFileURSSAF.name,
                        );

                        formDataurssaf.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/entreprise/ajoutURSSAF.php`, formDataurssaf)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileURSSAF: null,
                                    });

                                }

                            })

                        //this.ajouterURSSAF();

                    }

                    if (this.state.selectedFileFichedeposte !== null) {

                        // Create an object of formData
                        const formDatafdp = new FormData();

                        // Update the formData object
                        formDatafdp.append(
                            "images",
                            this.state.selectedFileFichedeposte,
                            this.state.selectedFileFichedeposte.name,
                        );

                        formDatafdp.append("iduser", this.state.iduser);

                        // Send formData object
                        axios.post(`${API_URL}/entreprise/ajoutFichedeposte.php`, formDatafdp)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileFichedeposte: null,
                                    });


                                }

                            })

                        //this.ajouterFichedeposte();

                    }

                    this.fermermodalinscription();

                })
                .catch(err => {
                    alert("Erreur :" + errocode + ", " + err)
                    this.setState({tokenuser: err});
                });
        }
    };

    onFileChangecv = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileCV: event.target.files[0],
            });

        }

    };

    onFileChangePITJ = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilePITJ: event.target.files[0],
            });

        }

    };

    onFileChangeCVitale = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileCVitale: event.target.files[0],
            });

        }

    };

    onFileChangediplome = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilediplome: event.target.files[0],
            });

        }

    };

    onFileChangepermis = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilepermis: event.target.files[0],
            });

        }

    };

    onFileChangecj = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilecj: event.target.files[0],
            });

        }

    };

    onFileChangeFichedeposte = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileFichedeposte: event.target.files[0],
            });

        }

    };

    onFileChangeKBIS = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileKBIS: event.target.files[0],
            });

        }

    };

    fermermodalinscription() {

        this.setState({
            loadershow: false,
            signin: true,
        })

    }

    selectson(prenomenfantupdate, datedenaissanceenfantselect) {

        var indexson = this.state.cachelistenfant.map(function (o) {
            return o.prenomenfant;
        }).indexOf(prenomenfantupdate);

        if (indexson > -1) {

            this.setState({
                prenomsenfant: prenomenfantupdate,
                datenaisanceenfant: datedenaissanceenfantselect,
                idselectenfant: indexson
            });

            dateselectenfant = new Date(datedenaissanceenfantselect);

        }

    }

    addson() {

        if (this.state.prenomsenfant == '') {
            this.setState({errorformenfant: true, errormessageenfant: 'Veuillez saisir le nom de l\'enfant.'});
            return
        }

        if (this.state.datedenaissanceenfant == '') {
            this.setState({
                errorformenfant: true,
                errormessageenfant: 'Veuillez selectionner la date de naissance de l\'enfant.'
            });
            return
        }

        for (var i = 0; i < this.state.cachelistenfant.length; i++) {

            if (this.state.cachelistenfant[i].prenomenfant === this.state.prenomsenfant && this.state.cachelistenfant[i].datenaisanceenfant === this.state.datedenaissanceenfant) {

                this.setState({errorformenfant: true, errormessageenfant: 'Enfant déja ajouté.'});
                return

            }

        }

        this.state.cachelistenfant.push({
            prenomenfant: this.state.prenomsenfant,
            datenaisanceenfant: this.state.datedenaissanceenfant
        })

        this.setState({errorformenfant: false, errormessageenfant: ''});

        this.refreshlistenfant();

    }

    deleteson(prenomenfantfind, datenaissanceenfantfind) {

        for (var i = 0; i < this.state.cachelistenfant.length; i++) {

            if (this.state.cachelistenfant[i].prenomenfant === prenomenfantfind && this.state.cachelistenfant[i].datenaisanceenfant === datenaissanceenfantfind) {

                this.state.cachelistenfant.splice(i, 1);
            }

        }

        this.refreshlistenfant();

    }

    updateson() {

        this.state.cachelistenfant[this.state.idselectenfant].prenomenfant = this.state.prenomsenfant;
        this.state.cachelistenfant[this.state.idselectenfant].datenaisanceenfant = this.state.datedenaissanceenfant;

        this.refreshlistenfant();

    }

    refreshlistenfant() {

        dateselectenfant = new Date();

        this.setState({
            listenfant: this.state.cachelistenfant,
            prenomsenfant: '',
            datenaisanceenfant: '',
            idselectenfant: -1
        });

    }

    validation() {

        document.location.href = '/Accueil';

    }

    render() {
        return (
            <div style={{paddingTop: "100px", marginRight: "10px", marginBottom: "80px"}}>
                {this.state.inscriptioneffectue === false ?
                    <div>
                        <BrowserView>
                            <Container>
                                <Row>
                                    <Col>
                                        {parseInt(this.state.idtypeuser) === 2 ?
                                            <div>
                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Nom</label>
                                                    <input type="text" className="form-control" value={this.state.nom}
                                                           onChange={(e) => this.setState({nom: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Prénom(s)</label>
                                                    <input type="text" className="form-control"
                                                           value={this.state.prenoms}
                                                           onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        {parseInt(this.state.idtypeuser) === 3 ?

                                            <div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Nom</label>
                                                    <input type="text" className="form-control" value={this.state.nom}
                                                           onChange={(e) => this.setState({nom: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Prénom(s)</label>
                                                    <input type="text" className="form-control"
                                                           value={this.state.prenoms}
                                                           onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Nationalité ou titre de séjour français *</label>
                                                    <input type="text" className="form-control"
                                                           value={this.state.nationalite}
                                                           onChange={(e) => this.setState({nationalite: e.target.value})}/>
                                                </div>

                                            </div>

                                            :

                                            null

                                        }

                                        {parseInt(this.state.idtypeuser) === 4 ?

                                            <div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Nom</label>
                                                    <input type="text" className="form-control" value={this.state.nom}
                                                           onChange={(e) => this.setState({nom: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Numero SIRET</label>
                                                    <input type="text" className="form-control"
                                                           value={this.state.numerosiret}
                                                           onChange={(e) => this.setState({numerosiret: e.target.value})}/>
                                                </div>

                                            </div>

                                            :

                                            null

                                        }

                                        {this.state.idtypeuser === '2' ?

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Date de naissance * </label>
                                                <DatePicker
                                                    maxDate={new Date()}
                                                    selected={new Date(this.state.datedenaissance)}
                                                    onChange={(date) => this.selectdate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    locale="fr"
                                                    dateFormat="dd/MM/yyyy"
                                                    dropdownMode="select"
                                                    withPortal
                                                />
                                            </div>

                                            :

                                            null

                                        }


                                        <div className="form-group" style={{margin: '10px'}}>
                                            <label>Téléphone</label>
                                            <input type="text" className="form-control" value={this.state.telephone}
                                                   onChange={(e) => this.setState({telephone: e.target.value})}/>
                                        </div>

                                        <div className="form-group" style={{margin: '10px'}}>
                                            <label>Adresse *</label>
                                            <textarea rows={2} type="text" className="form-control"
                                                      value={this.state.adresse}
                                                      onChange={(e) => this.setState({adresse: e.target.value})}/>
                                        </div>

                                        <div className="form-group" style={{margin: '10px'}}>
                                            <label>Code postale *</label>
                                            <input type="text" className="form-control" value={this.state.codepostale}
                                                   onChange={(e) => this.setState({codepostale: e.target.value})}/>
                                        </div>

                                        <div className="form-group" style={{margin: '10px'}}>
                                            <label>Ville *</label>
                                            <input type="text" className="form-control" value={this.state.ville}
                                                   onChange={(e) => this.setState({ville: e.target.value})}/>
                                        </div>

                                    </Col>

                                    <Col>


                                        {this.state.idtypeuser === '3' ?

                                            <div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Prénom(s) de l'enfant *</label>
                                                    <input type="text" className="form-control"
                                                           value={this.state.prenomsenfant}
                                                           onChange={(e) => this.setState({prenomsenfant: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Date de naissance enfant *</label>
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        selected={dateselectenfant}
                                                        onChange={(date) => this.selectdateenfant(date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        locale="fr"
                                                        dateFormat="dd/MM/yyyy"
                                                        dropdownMode="select"
                                                        withPortal
                                                    />
                                                </div>

                                                {this.state.errorformenfant == true ?
                                                    <Alert severity="error">
                                                        <AlertTitle>Erreur</AlertTitle>
                                                        {this.state.errormessageenfant}
                                                    </Alert>
                                                    :
                                                    null
                                                }


                                                {this.state.idselectenfant !== -1 ?

                                                    <div className="d-flex justify-content-center">
                                                        <Button className="btn btn-success" style={{
                                                            margin: '10px',
                                                            width: '150px',
                                                            borderRadius: '25px'
                                                        }} onClick={() => this.updateson()}>Modifier</Button>
                                                    </div>

                                                    :

                                                    <div className="d-flex justify-content-center">
                                                        <Button className="btn btn-success" style={{
                                                            margin: '10px',
                                                            width: '150px',
                                                            borderRadius: '25px'
                                                        }} onClick={() => this.addson()}>Ajouter enfant</Button>
                                                    </div>

                                                }

                                                {this.state.listenfant.length > 0 ?
                                                    <div className="table">
                                                        <table className="table-responsive">
                                                            <thead>
                                                            <tr>
                                                                <th style={{textAlign: 'left'}}>Prénoms</th>
                                                                <th style={{textAlign: 'center'}}>Date de naissance</th>
                                                                <th style={{textAlign: 'center'}}>Modifier</th>
                                                                <th style={{textAlign: 'center'}}>Supprimer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.listenfant.map((row) => (

                                                                <tr>

                                                                    <td style={{textAlign: 'left'}}>{row.prenomenfant}</td>
                                                                    <td style={{textAlign: 'center'}}>{row.datenaisanceenfant}</td>
                                                                    <td style={{textAlign: 'center'}}>
                                                                        <button type="submit"
                                                                                className="btn btn-primary btn-circle btn-xl"
                                                                                style={{borderRadius: '25px'}}
                                                                                onClick={() => this.selectson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                            <span><EditIcon/></span></button>
                                                                    </td>
                                                                    <td style={{textAlign: 'center'}}>
                                                                        <button type="submit"
                                                                                className="btn btn-danger btn-circle btn-xl"
                                                                                style={{borderRadius: '25px'}}
                                                                                onClick={() => this.deleteson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                            <span><DeleteIcon/></span></button>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>


                                            :

                                            null

                                        }
                                        {parseInt(this.state.idtypeuser) === 4 ?

                                            <div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>Attestation de compte à jour
                                                        (URSSAF) {this.state.lienURSSAF != '' ?
                                                            <a href={this.state.lienURSSAF}
                                                               target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control" onChange={this.onFileChangeURSSAF}/>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>Fiche de poste {this.state.lienFichedeposte != '' ?
                                                        <a href={this.state.lienFichedeposte}
                                                           target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control"
                                                           onChange={this.onFileChangeFichedeposte}/>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>KBIS {this.state.lienKBIS != '' ?
                                                        <a href={this.state.lienKBIS}
                                                           target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control" onChange={this.onFileChangeKBIS}/>
                                                </div>

                                            </div>

                                            :

                                            null

                                        }

                                        {this.state.idtypeuser === '2' ?

                                            <div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>
                                                        Piece d'identité ou titre de séjour *
                                                        {this.state.lienpitj != '' ?
                                                            <a href={this.state.lienpitj} target="_blank">Télécharger</a> : null}
                                                    </label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control" onChange={this.onFileChangePITJ}/>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>Carte vitale {this.state.liencvitale != '' ?
                                                        <a href={this.state.liencvitale}
                                                           target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control"
                                                           onChange={this.onFileChangeCVitale}/>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>CV * {this.state.liencv != '' ? <a href={this.state.lienCV}
                                                                                            target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control" onChange={this.onFileChangecv}/>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>Diplôme {this.state.liendiplome != '' ?
                                                        <a href={this.state.liendiplome}
                                                           target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control"
                                                           onChange={this.onFileChangediplome}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Expériences *</label>
                                                    <textarea rows={2} type="text" className="form-control"
                                                              value={this.state.experience}
                                                              onChange={(e) => this.setState({experience: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <Form.Group>
                                                        <Form.Check type={"checkbox"}>
                                                            <Form.Check.Label>Référence</Form.Check.Label>
                                                            <Form.Check.Input
                                                                type={"checkbox"}
                                                                defaultChecked={this.state.reference}
                                                                onClick={(e) => {
                                                                    this.setState({reference: e.target.checked});
                                                                }}
                                                            />
                                                        </Form.Check>
                                                    </Form.Group>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '25px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>Permis de conduire {this.state.lienpermis != '' ?
                                                        <a href={this.state.lienpermis}
                                                           target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control" onChange={this.onFileChangepermis}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <Form.Group>
                                                        <Form.Check type={"checkbox"}>
                                                            <Form.Check.Label>Véhicule</Form.Check.Label>
                                                            <Form.Check.Input
                                                                type={"checkbox"}
                                                                defaultChecked={this.state.vehicule}
                                                                onClick={(e) => {
                                                                    this.setState({vehicule: e.target.checked});
                                                                }}
                                                            />
                                                        </Form.Check>
                                                    </Form.Group>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Garde *</label>
                                                    <div>
                                                        <Form.Select style={{
                                                            height: '30px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '10px'
                                                        }} value={this.state.typedegarde}
                                                                     onChange={this.selectusertypegarde}>
                                                            {this.state.listtypegarde.map((typ) => (
                                                                <option value={typ.idgarde}>{typ.typegarde}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Heure de garde * </label>
                                                    <textarea rows={2} type="text" className="form-control"
                                                              value={this.state.heuregarde}
                                                              onChange={(e) => this.setState({heuregarde: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                    <label>Casier judiciaire * {this.state.liencj != '' ?
                                                        <a href={this.state.liencj}
                                                           target="_blank">Télécharger</a> : null}</label>
                                                    <input type="file"
                                                           accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                           className="form-control" onChange={this.onFileChangecj}/>
                                                    <a className="texte-clignote"
                                                       href="https://casier-judiciaire.justice.gouv.fr/"
                                                       target="_blank">Cliquez ici pour faire une demande de casier
                                                        judiciaire</a>
                                                </div>

                                            </div>

                                            :

                                            null

                                        }

                                        {this.state.idtypeuser === '3' ?

                                            <div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Type de garde de l'enfant *</label>
                                                    <div>
                                                        <Form.Select style={{
                                                            height: '30px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '10px'
                                                        }} value={this.state.userenfantgarde}
                                                                     onChange={this.selectusertypegardenfant}>
                                                            {this.state.listtypegardeenfant.map((tpe) => (
                                                                <option
                                                                    value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Type de contrat de garde de l'enfant *</label>
                                                    <div>
                                                        <Form.Select style={{
                                                            height: '30px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '10px'
                                                        }} value={this.state.usercontratgarde}
                                                                     onChange={this.selectusertypecontratgarde}>
                                                            {this.state.listcontratgarde.map((tce) => (
                                                                <option
                                                                    value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </div>


                                            :

                                            null

                                        }

                                        {this.state.idtypeuser === '4' ?

                                            <div>

                                                {/*
                                  <div className="form-group" style={{margin:'10px'}}>
                                    <label >Type de garde de l'enfant</label>
                                      <div>
                                        <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.userenfantgarde} onChange={this.selectusertypegardenfant}>
                                          {this.state.listtypegardeenfant.map((tpe) => (
                                            <option value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                          ))}
                                        </Form.Select>
                                      </div>
                                  </div>
                                  */}

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Type de contrat de garde de l'enfant</label>
                                                    <div>
                                                        <Form.Select style={{
                                                            height: '30px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '10px'
                                                        }} value={this.state.usercontratgarde}
                                                                     onChange={this.selectusertypecontratgarde}>
                                                            {this.state.listcontratgarde.map((tce) => (
                                                                <option
                                                                    value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>


                                            </div>


                                            :

                                            null

                                        }

                                        <div className="form-group" style={{margin: '10px'}}>
                                            <label>Informations
                                                supplémentaires {parseInt(this.state.idtypeuser) !== 2 ? " concernant la garde de l'enfant *" : ""}</label>
                                            <textarea rows={2} type="text" className="form-control"
                                                      value={this.state.infosup}
                                                      onChange={(e) => this.setState({infosup: e.target.value})}/>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>

                        </BrowserView>

                        <MobileView>

                            {parseInt(this.state.idtypeuser) === 2 ?

                                <div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Nom</label>
                                        <input type="text" className="form-control" value={this.state.nom}
                                               onChange={(e) => this.setState({nom: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Prénom(s)</label>
                                        <input type="text" className="form-control" value={this.state.prenoms}
                                               onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                    </div>

                                </div>

                                :

                                null

                            }

                            {parseInt(this.state.idtypeuser) === 3 ?

                                <div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Nom</label>
                                        <input type="text" className="form-control" value={this.state.nom}
                                               onChange={(e) => this.setState({nom: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Prénom(s)</label>
                                        <input type="text" className="form-control" value={this.state.prenoms}
                                               onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Nationalité ou titre de séjour français *</label>
                                        <input type="text" className="form-control" value={this.state.nationalite}
                                               onChange={(e) => this.setState({nationalite: e.target.value})}/>
                                    </div>

                                </div>

                                :

                                null

                            }

                            {parseInt(this.state.idtypeuser) === 4 ?

                                <div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Nom</label>
                                        <input type="text" className="form-control" value={this.state.nom}
                                               onChange={(e) => this.setState({nom: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Numero SIRET</label>
                                        <input type="text" className="form-control" value={this.state.numerosiret}
                                               onChange={(e) => this.setState({numerosiret: e.target.value})}/>
                                    </div>

                                </div>

                                :

                                null

                            }

                            {this.state.idtypeuser === '2' ?

                                <div className="form-group" style={{margin: '10px'}}>
                                    <label>Date de naissance * </label>
                                    <DatePicker
                                        maxDate={new Date()}
                                        selected={dateselect}
                                        onChange={(date) => this.selectdate(date)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        locale="fr"
                                        dateFormat="dd/MM/yyyy"
                                        dropdownMode="select"
                                        withPortal
                                    />
                                </div>

                                :

                                null

                            }

                            <div className="form-group" style={{margin: '10px'}}>
                                <label>Téléphone</label>
                                <input type="text" className="form-control" value={this.state.telephone}
                                       onChange={(e) => this.setState({telephone: e.target.value})}/>
                            </div>

                            <div className="form-group" style={{margin: '10px'}}>
                                <label>Adresse *</label>
                                <textarea rows={2} type="text" className="form-control" value={this.state.adresse}
                                          onChange={(e) => this.setState({adresse: e.target.value})}/>
                            </div>

                            <div className="form-group" style={{margin: '10px'}}>
                                <label>Code postale *</label>
                                <input type="text" className="form-control" value={this.state.codepostale}
                                       onChange={(e) => this.setState({codepostale: e.target.value})}/>
                            </div>

                            <div className="form-group" style={{margin: '10px'}}>
                                <label>Ville *</label>
                                <input type="text" className="form-control" value={this.state.ville}
                                       onChange={(e) => this.setState({ville: e.target.value})}/>
                            </div>

                            {parseInt(this.state.idtypeuser) === 4 ?

                                <div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>Attestation de compte à jour (URSSAF) {this.state.lienURSSAF != '' ?
                                            <a href={this.state.lienURSSAF}
                                               target="_blank">Télécharger</a> : null}</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangeURSSAF}/>
                                    </div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>Fiche de poste {this.state.lienFichedeposte != '' ?
                                            <a href={this.state.lienFichedeposte}
                                               target="_blank">Télécharger</a> : null}</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangeFichedeposte}/>
                                    </div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>KBIS {this.state.lienKBIS != '' ? <a href={this.state.lienKBIS}
                                                                                    target="_blank">Télécharger</a> : null}</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangeKBIS}/>
                                    </div>

                                </div>

                                :

                                null

                            }

                            {this.state.idtypeuser === '2' ?

                                <div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>Piece d'identité ou titre de séjour * {this.state.lienpitj != '' ?
                                            <a href={this.state.lienpitj}
                                               target="_blank">Télécharger</a> : null}</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangePITJ}/>
                                    </div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>Carte vitale {this.state.liencvitale != '' ?
                                            <a href={this.state.liencvitale}
                                               target="_blank">Télécharger</a> : null}</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangeCVitale}/>
                                    </div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>CV *</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangecv}/>
                                    </div>

                                    <div className="form-group"
                                         style={{marginLeft: '10px', marginRight: '10px', marginBottom: '10px'}}>
                                        <label>Diplôme</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangediplome}/>

                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Expériences *</label>
                                        <textarea rows={2} type="text" className="form-control"
                                                  value={this.state.experience}
                                                  onChange={(e) => this.setState({experience: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                                <Form.Check.Label>Référence</Form.Check.Label>
                                                <Form.Check.Input
                                                    type={"checkbox"}
                                                    defaultChecked={this.state.reference}
                                                    onClick={(e) => {
                                                        this.setState({reference: e.target.checked});
                                                    }}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                                <Form.Check.Label>Permis</Form.Check.Label>
                                                <Form.Check.Input
                                                    type={"checkbox"}
                                                    defaultChecked={this.state.permis}
                                                    onClick={(e) => {
                                                        this.setState({permis: e.target.checked});
                                                    }}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    </div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '25px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>Permis de conduire</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangepermis}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                                <Form.Check.Label>Véhicule</Form.Check.Label>
                                                <Form.Check.Input
                                                    type={"checkbox"}
                                                    defaultChecked={this.state.vehicule}
                                                    onClick={(e) => {
                                                        this.setState({vehicule: e.target.checked});
                                                    }}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Garde *</label>
                                        <div>
                                            <Form.Select
                                                style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                value={this.state.typedegarde} onChange={this.selectusertypegarde}>
                                                {this.state.listtypegarde.map((typ) => (
                                                    <option value={typ.idgarde}>{typ.typegarde}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Heure de garde * </label>
                                        <textarea rows={2} type="text" className="form-control"
                                                  value={this.state.heuregarde}
                                                  onChange={(e) => this.setState({heuregarde: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <label>Casier judiciaire *</label>
                                        <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                               className="form-control" onChange={this.onFileChangecj}/>
                                        <a href="https://casier-judiciaire.justice.gouv.fr/" target="_blank">Cliquez ici
                                            pour faire une demande de casier judiciaire</a>
                                    </div>

                                </div>

                                :

                                null

                            }

                            {this.state.idtypeuser === '3' ?

                                <div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Prénom(s) de l'enfant *</label>
                                        <input type="text" className="form-control" value={this.state.prenomsenfant}
                                               onChange={(e) => this.setState({prenomsenfant: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Date de naissance enfant *</label>
                                        <DatePicker
                                            maxDate={new Date()}
                                            selected={dateselectenfant}
                                            onChange={(date) => this.selectdateenfant(date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            locale="fr"
                                            dateFormat="dd/MM/yyyy"
                                            dropdownMode="select"
                                            withPortal
                                        />
                                    </div>

                                    {this.state.errorformenfant == true ?

                                        <Alert severity="error">
                                            <AlertTitle>Erreur</AlertTitle>
                                            {this.state.errormessageenfant}
                                        </Alert>

                                        :

                                        null

                                    }


                                    {this.state.idselectenfant !== -1 ?

                                        <div className="d-flex justify-content-center">
                                            <Button className="btn btn-success"
                                                    style={{margin: '10px', width: '150px', borderRadius: '25px'}}
                                                    onClick={() => this.updateson()}>Modifier</Button>
                                        </div>
                                        :
                                        <div className="d-flex justify-content-center">
                                            <Button className="btn btn-success"
                                                    style={{margin: '10px', width: '150px', borderRadius: '25px'}}
                                                    onClick={() => this.addson()}>Ajouter enfant</Button>
                                        </div>
                                    }

                                    {this.state.listenfant.length > 0 ?
                                        <div className="table">
                                            <table className="table-responsive">
                                                <thead>
                                                <tr>
                                                    <th style={{textAlign: 'left'}}>Prénoms</th>
                                                    <th style={{textAlign: 'center'}}>Date de naissance</th>
                                                    <th style={{textAlign: 'center'}}>Modifier</th>
                                                    <th style={{textAlign: 'center'}}>Supprimer</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.listenfant.map((row) => (

                                                    <tr>

                                                        <td style={{textAlign: 'left'}}>{row.prenomenfant}</td>
                                                        <td style={{textAlign: 'center'}}>{row.datenaisanceenfant}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <button type="submit"
                                                                    className="btn btn-primary btn-circle btn-xl"
                                                                    style={{borderRadius: '25px'}}
                                                                    onClick={() => this.selectson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                <span><EditIcon/></span></button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <button type="submit"
                                                                    className="btn btn-danger btn-circle btn-xl"
                                                                    style={{borderRadius: '25px'}}
                                                                    onClick={() => this.deleteson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                <span><DeleteIcon/></span></button>
                                                        </td>

                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }

                            {this.state.idtypeuser === '3' ?
                                <div>
                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Type de garde de l'enfant *</label>
                                        <div>
                                            <Form.Select
                                                style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                value={this.state.userenfantgarde}
                                                onChange={this.selectusertypegardenfant}>
                                                {this.state.listtypegardeenfant.map((tpe) => (
                                                    <option value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Type de contrat de garde de l'enfant *</label>
                                        <div>
                                            <Form.Select
                                                style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                value={this.state.usercontratgarde}
                                                onChange={this.selectusertypecontratgarde}>
                                                {this.state.listcontratgarde.map((tce) => (
                                                    <option value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                            {this.state.idtypeuser === '4' ?
                                <div>

                                    {/* 
                                    <div className="form-group" style={{margin:'10px'}}>
                                      <label >Type de garde de l'enfant</label>
                                        <div>
                                          <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.userenfantgarde} onChange={this.selectusertypegardenfant}>
                                            {this.state.listtypegardeenfant.map((tpe) => (
                                              <option value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                            ))}
                                          </Form.Select>
                                        </div>
                                    </div>
                                    */}

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Type de contrat de garde de l'enfant</label>
                                        <div>
                                            <Form.Select
                                                style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                value={this.state.usercontratgarde}
                                                onChange={this.selectusertypecontratgarde}>
                                                {this.state.listcontratgarde.map((tce) => (
                                                    <option value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>


                                </div>
                                :
                                null
                            }

                            <div className="form-group" style={{margin: '10px'}}>
                                <label>Informations
                                    supplémentaires {parseInt(this.state.idtypeuser) !== 2 ? " concernant la garde de l'enfant *" : ""}</label>
                                <textarea rows={2} type="text" className="form-control" value={this.state.infosup}
                                          onChange={(e) => this.setState({infosup: e.target.value})}/>
                            </div>

                        </MobileView>
                    </div>
                    :
                    null
                }

                {this.state.errorform == true ?
                    <Alert severity="error">
                        <AlertTitle>Erreur</AlertTitle>
                        {this.state.errormessage}
                    </Alert>
                    :
                    null
                }

                {this.state.loadershow == true ?
                    <Loader type="spinner-cub" bgColor={'#FF7E5A'} title={"Modification en cours ..."} color={'#FF7E5A'}
                            size={100}/>
                    :
                    null
                }

                {this.state.signin === false ?
                    <div className="d-flex justify-content-center">
                        <Button className="btn" style={{
                            backgroundColor: "#FF7E5A", borderRadius: "8px",
                            height: 48,
                            border: 0,
                            marginTop: '20px',
                            marginBottom: '50px', margin: '10px', width: '150px'
                        }} onClick={() => this.verifieradresse()}>Mettre à jour</Button>
                    </div>
                    :
                    <div className='App'>
                        <p style={{fontSize: isBrowser ? '3rem' : '2rem'}}>Mise à jour effectué.</p>
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <Button className="btn" style={{
                            backgroundColor: "#FF7E5A", borderRadius: "8px",
                            height: 48,
                            width: 200,
                            border: 0,
                        }} onClick={() => this.validation()} variant="success">OK</Button>
                    </div>
                }
            </div>
        );
    }

}

export default Profil;
