import './App.css';
import './moncss.css';
import React,  {Component} from 'react';


class CGU extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
        lienimage: 'https://www.nannylou.com/images/',
        nomcontact: '',
        mailcontact: '',
        titrecontact: '',
        messagecontact: '',
        isModalVisible: false,
        editorState:'',
        montant:0.0,
        lng:'FR',
        titre:'',       
    };
        
  }
    
    componentDidMount() {

        document.title = "Conditions générales d'utilisation";
        
    };

    componentDidUpdate(prevProps) {

            window.scrollTo(0, 0)

      };
   
   
    
  render() {

          return (
              
                <div >

                    <div style={{paddingTop:"100px"}}></div>
                    
                    <div className="titregris" >Mentions légales et conditions d'utilisation du site internet</div>
                    <div className="textcgu">
                    <p>La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui suivent.</p>
                    <p>La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.</p>
                    <p>Les Parties déclarent et reconnaissent que la négociation ayant précédé la conclusion du présent accord a été conduite de bonne foi et avoir bénéficié, pendant la phase précontractuelle de négociation, de toutes les informations nécessaires et utiles pour leur permettre de s&rsquo;engager en toute connaissance de cause et s&rsquo;être mutuellement communiqué toute information susceptible de déterminer leur consentement et qu&rsquo;elles pouvaient légitimement ignorer.</p>
                    <p>Dans le cadre de sa politique de communication le Client exploite un site web accessible à l'adresse « <a href="https://www.nannylou.com/">https://www.nannylou.com/</a>« , ci-après le Site, dédié à « Indiquer le domaine d'application du Site ».</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 1 &#8211; Informations légales</div>
                    <div className="textcgu">
                        
                        <p className="listearticle"><b>1.1 </b>Site (ci-après « le site ») : (<a href="https://www.nannylou.com/">https://www.nannylou.com/</a>)</p>
                        <p className="listearticle"><b>1.2 </b>Éditeur (ci-après « l&rsquo;éditeur ») : NannyLou, SARL au capital de 5 000 euros, dont le siège social est situé au 9 rue du 11 novembre 1918 à Valenton 94460, RCS Créteil, numéro SIREN 849 292 750 représentée par Lilas GILBERT, en sa qualité de Gérante,</p>
                        <p className="listearticle">Numéro de téléphone : 0145694255</p>
                        <p className="listearticle">Adresse de courrier électronique : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a></p>
                        <p className="listearticle">Directeur de la publication : Lilas GILBERT</p>
                        <p className="listearticle"><b>1.3 </b>Conception et réalisation : Parcus digital solution, SASU au capital de 2000 euros, dont le siège social est situé 26 rue commandant René Mouchotte 75014 Paris, RCS 823 564 034, représentée par Aurore Chourouk MZAHI, en sa qualité de présidente.</p>
                        <p className="listearticle">Numéro de téléphone : 06 51 67 34 20</p>
                        <p className="listearticle">Adresse de courrier électronique : info@parcus.fr</p>
                        <p className="listearticle"><b>1.</b><b>4 </b>Hébergeur (ci-après « HOSTINGER ») :</p>

                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 2 &#8211; Accès au site</div>
                    <div className="textcgu">
                        <p>L&rsquo;accès au site est réservé.</p>
                        <p>L&rsquo;éditeur se réserve le droit de demander toute justification de votre âge notamment si vous utilisez des services de commande en ligne.</p>
                        <p>L&rsquo;accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l&rsquo;envoi de courriers électroniques non sollicités.</p>

                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 3 &#8211; Contenu du site</div>
                    <div className="textcgu">
                        <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>
                        <p>Ils sont la propriété pleine et entière de l&rsquo;éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l&rsquo;accord préalable et écrit de l&rsquo;éditeur, sont strictement interdites. Le fait pour l&rsquo;éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 4 &#8211; Gestion du site</div>
                    <div className="textcgu">
                        <p>Pour la bonne gestion du site, l&rsquo;éditeur pourra à tout moment :</p>
                        <p>&#8211;  suspendre, interrompre ou de limiter l&rsquo;accès à tout ou partie du site, réserver l&rsquo;accès au site, ou à certaines parties du site, à une catégorie déterminée d&rsquo;internaute ;</p>
                        <p>&#8211;  supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette ;</p>
                        <p>&#8211;  suspendre le site afin de procéder à des mises à jour.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 5 &#8211; Responsabilités</div>
                    <div className="textcgu">
                        <p>La responsabilité de l&rsquo;éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l&rsquo;accès au site ou à une de ses fonctionnalités.</p>
                        <p>Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d&rsquo;attaques virales par Internet. Vous êtes par ailleurs le seul responsable des sites et données que vous consultez.</p>
                        <p>L&rsquo;éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :</p>
                        <p>&#8211;  du fait de l&rsquo;usage du site ou de tout service accessible viaInternet ;</p>
                        <p>&#8211;  du fait du non-respect par vous des présentes conditions générales.</p>
                        <p>L&rsquo;éditeur n&rsquo;est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.</p>
                        <p>Si l&rsquo;éditeur venait à faire l&rsquo;objet d&rsquo;une procédure amiable ou judiciaire à raison de votre utilisation du site, il pourra retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 6 &#8211; Liens hypertextes</div>
                    <div className="textcgu">
                        <p>La mise en place par vous de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf autorisation préalable et écrite de l&rsquo;éditeur, sollicitée par courriel à l&rsquo;adresse suivante : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a> et au 9 rue du 11 novembre 1918 à Valenton 94460.</p>
                        <p>L&rsquo;éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision. Dans le cas où l&rsquo;éditeur accorderait son autorisation, celle-ci n&rsquo;est dans tous les cas que temporaire et pourra être retirée à tout moment, sans obligation de justification à la charge de l&rsquo;éditeur.</p>
                        <p>Dans tous les cas, tout lien devra être retiré sur simple demande de l&rsquo;éditeur.</p>
                        <p>Toute information accessible viaun lien vers d&rsquo;autres sites n&rsquo;est pas sous le contrôle de l&rsquo;éditeur qui décline toute responsabilité quant à leur contenu.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 7 &#8211; Protection des données à caractère personnel</div>
                    <div className="textcgu">
                        <div className="listearticle">

                            <p><b>7.1</b> Collecte des données personnelles</p>
                            <p>La mise en place par vous de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf autorisation préalable et écrite de l&rsquo;éditeur, sollicitée par courriel à l&rsquo;adresse suivante : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a> et au 9 rue du 11 novembre 1918 à Valenton 94460.</p>
                            <p>L&rsquo;éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision. Dans le cas où l&rsquo;éditeur accorderait son autorisation, celle-ci n&rsquo;est dans tous les cas que temporaire et pourra être retirée à tout moment, sans obligation de justification à la charge de l&rsquo;éditeur.</p>
                            <p>Dans tous les cas, tout lien devra être retiré sur simple demande de l&rsquo;éditeur.</p>
                            <p>Toute information accessible viaun lien vers d&rsquo;autres sites n&rsquo;est pas sous le contrôle de l&rsquo;éditeur qui décline toute responsabilité quant à leur contenu.</p>
                            <p><b>7.2 </b>Connexion</p>
                            <p>Lors de la connexion de l&rsquo;utilisateur à la Plateforme, celle-ci enregistre, notamment, ses nom, prénom, données de connexion, d&rsquo;utilisation, de localisation et ses données relatives au paiement.</p>
                            <p><b>7.3 </b>Profil</p>
                            <p>L&rsquo;utilisation des prestations prévues sur la Plateforme permet de renseigner un profil, pouvant comprendre une adresse et un numéro de téléphone.</p>
                            <p><b>7.4 </b>Paiement</p>
                            <p>Dans le cadre du paiement des produits et prestations proposés sur la Plateforme, celle-ci enregistre des données financières relatives au compte bancaire ou à la carte de crédit de l&rsquo;utilisateur.</p>
                            <p><b>7.5 </b>Communication</p>
                            <p>Lorsque la Plateforme est utilisée pour communiquer avec d&rsquo;autres membres, les données concernant les communications de l&rsquo;utilisateur font l&rsquo;objet d&rsquo;une conservation temporaire.</p>
                            <p><b>7.6 </b>Cookies</p>
                            <p>Les cookies sont utilisés, dans le cadre de l&rsquo;utilisation du site. L&rsquo;utilisateur a la possibilité de désactiver les cookies à partir des paramètres de son navigateur.</p>
                            <p><b>7.7 </b>Utilisation des données personnelles</p>
                            <p>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de la Plateforme, leur amélioration et le maintien d&rsquo;un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :</p>
                            <p>&#8211;  accès et utilisation de la Plateforme par l&rsquo;utilisateur ;</p>
                            <p>&#8211;  gestion du fonctionnement et optimisation de la Plateforme ;</p>
                            <p>&#8211; organisation des conditions d&rsquo;utilisation des Services de paiement ;</p>
                            <p>&#8211; vérification, identification et authentification des données transmises par l&rsquo;utilisateur ;</p>
                            <p>&#8211; proposition à l&rsquo;utilisateur de la possibilité de communiquer avec d&rsquo;autres utilisateurs de la Plateforme ;</p>
                            <p>&#8211;  mise en œuvre d&rsquo;une assistance utilisateurs ;</p>
                            <p>&#8211; personnalisation des services en affichant des publicités en fonction de l&rsquo;historique de navigation de l&rsquo;utilisateur, selon ses préférences ;</p>
                            <p>&#8211; prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents de sécurité ;</p>
                            <p>&#8211;  gestion des éventuels litiges avec les utilisateurs ;</p>
                            <p>&#8211;  envoi d&rsquo;informations commerciales et publicitaires, en fonction des préférences de l&rsquo;utilisateur.</p>
                            <p><b>7.8 </b>Partage des données personnelles avec des tiers</p>
                            <p>Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :</p>
                            <p>&#8211;  quand l&rsquo;utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, la Plateforme est en relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats ;</p>
                            <p>&#8211;  lorsque l&rsquo;utilisateur publie, dans les zones de commentaires libres de la Plateforme, des informations accessibles au public ;</p>
                            <p>&#8211;  quand l&rsquo;utilisateur autorise le site web d&rsquo;un tiers à accéder à ses données ;</p>
                            <p>&#8211;  quand la Plateforme recourt aux services de prestataires pour fournir l&rsquo;assistance utilisateurs, la publicité et les services de paiement. Ces prestataires disposent d&rsquo;un accès limité aux données de l&rsquo;utilisateur, dans le cadre de l&rsquo;exécution de ces prestations, et ont une obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation applicable en matière protection des données à caractère personnel ;</p>
                            <p>&#8211;  si la loi l&rsquo;exige, la Plateforme peut effectuer la transmission de données pour donner suite aux réclamations présentées contre la Plateforme et se conformer aux procédures administratives et judiciaires ;</p>
                            <p>&#8211;  si la Plateforme est impliquée dans une opération de fusion, acquisition, cession d&rsquo;actifs ou procédure de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient transférées à une tierce partie.</p>
                            <p><b>7.9 </b>Sécurité et confidentialité</p>
                            <p>La Plateforme met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois, il est à signaler qu&rsquo;internet n&rsquo;est pas un environnement complètement sécurisé et la Plateforme ne peut pas garantir la sécurité de la transmission ou du stockage des informations sur internet.</p>
                            <p><b>7.10 </b>Mise en œuvre des droits des utilisateurs</p>
                            <p>En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants :</p>
                            <p>&#8211;  ils peuvent mettre à jour ou supprimer les données qui les concernent en se connectant à leur compte et en configurant les paramètres de ce compte ;</p>
                            <p>&#8211;  ils peuvent supprimer leur compte, en écrivant à l&rsquo;adresse électronique suivante : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a>. Il est à noter que les informations partagées avec d&rsquo;autres utilisateurs, comme les publications sur les forums, peuvent rester visibles du public sur la Plateforme, même après la suppression de leur compte ;</p>
                            <p>&#8211;  ils peuvent exercer leur droit d&rsquo;accès, pour connaître les données personnelles les concernant, en écrivant à l&rsquo;adresse électronique suivante <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a> Dans ce cas, avant la mise en œuvre de ce droit, la Plateforme peut demander une preuve de l&rsquo;identité de l&rsquo;utilisateur afin d&rsquo;en vérifier l&rsquo;exactitude ;</p>
                            <p>&#8211;  si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent demander la mise à jour des informations, en écrivant à l&rsquo;adresse électronique suivante : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a></p>
                            <p>&#8211;  les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données, en écrivant à l&rsquo;adresse électronique suivante : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a></p>
                            <p><b>7.11 </b>Évolution de la présente clause</p>
                            <p>La Plateforme se réserve le droit d&rsquo;apporter toute modification à la présente clause relative à la protection des données à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection des données à caractère personnel, la Plateforme s&rsquo;engage à publier la nouvelle version sur son site. La Plateforme informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum de 15 jours avant la date d&rsquo;effet. Si l&rsquo;utilisateur n&rsquo;est pas d&rsquo;accord avec les termes de la nouvelle rédaction de la clause de protection des données à caractère personnel, il a la possibilité de supprimer son compte.</p>
                            <p><b>7.12 </b>Si vous ne souhaitez pas que vos données soient utilisées par les partenaires de l&rsquo;éditeur à des fins de prospection commerciale, veuillez barrer la mention adéquate : Oui &#8211; Non.</p>

                        </div>
   
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 8 &#8211; Cookies </div>
                    <div className="textcgu">
                        <p>Le site peut collecter automatiquement des informations standards. Toutes les informations collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration du trafic utilisant ce site, pour en développer la conception et l&rsquo;agencement et à d&rsquo;autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous offrons.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 9 &#8211; Photographies et représentation des produits</div>
                    <div className="textcgu">
                        <p>Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n&rsquo;engagent pas l&rsquo;éditeur.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 10 &#8211; Loi applicable</div>
                    <div className="textcgu">
                        <p>Les présentes conditions d&rsquo;utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux du siège social de l&rsquo;éditeur, sous réserve d&rsquo;une attribution de compétence spécifique découlant d&rsquo;un texte de loi ou réglementaire particulier.</p>
                    </div>

                    <p>&nbsp;</p>
                    <div className="titregris">Article 11 &#8211; Contactez-nous</div>
                    <div className="textcgu">
                        <p>Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous pouvez laisser un message à l&rsquo;adresse suivante : <a onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}>contact@nannylou.com</a></p>
                    </div>
                
                    

                </div>
          );
        }
    
}

export default CGU;
