import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import Fade from 'react-reveal/Fade';
import { BrowserView, MobileView, isBrowser} from "react-device-detect";
import * as ls from "local-storage";

class Quisommesnous extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
      paypalpay:false,
      tarif:0.00,
      listtarif:[],
      lienimage: process.env.PUBLIC_URL +'images/',
    };
        
  }
    
  async componentDidMount() {

      document.title = "Qui sommes-nous"
        
    };
   
    
  render() {

          return (
              
                <div className="App" style={{paddingTop:isBrowser?"90px":"90px",overFlowX: "hidden", backgroundColor:"#FFF4FB"}}>

                    {/*

                    <Fade bottom>
                    <div className='align-items-center'>                         
                        <p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2em"}}>Pour les parents</p>

                    </div>
                    </Fade>

                    */}

                    <img src={this.state.lienimage + "about-header.png"} style={{width:"100%", borderRadius:"0% 0% -50% -50%"}} className="imageparentavantage"></img>

                    <div className="titlegardeparent" style={{marginTop:"-20px"}}>Garde d'enfants 24h/24</div>

                    <div style={{color:"black",fontSize:"40px", margin:"20px"}}>Nannylou</div>

                    <div  style={{color:"#6C6C6C", fontSize: "24px",textAlign:"left", marginLeft:"10%", marginRight:"10%", width:"80%"}}>
                        <p>Le manque de places dans les crèches collectives, la difficulté à trouver la Nanny idéale à proximité, les horaires décalés ou de nuit, l'éloignement familial rendent difficile la tâche des parents pour faire garder leurs enfants.</p>
                        <p>NannyLou vient en aide à ces familles grâce à son application mobile et son site web de mise en relation disponible 24h/24. Elle met en contact les parents et des Nannies (des professionnelles passionnées, expérimentées, agréées, diplômées et/ou certifiées dans le domaine de la petite enfance), grâce à un système de géolocalisation en temps réel.</p>
                        <p>Les Nannies peuvent accueillir les enfants à leur domicile ou se rendre à celui des parents, en journée, en soirée ou pour la nuit complète. Les parents et les entreprises partenaires ont la possibilité de réserver une journée entière ou une ½ selon le tarif appliqué par nos professionnels.</p>
                    </div>

                    <div className="fondblurbleu">

                    </div>

                    <div className="fondblurrose">

                    </div>

                    <div className={isBrowser?"cadreavantageparents":""} >

                      <Fade bottom>

                      <div className="avantagesparents" >
                          <div className="container">

                          <div style={{color:"black",fontSize:"40px", margin:"20px", textAlign:"center"}}>Nos valeurs</div>

                          <div className="container">
                            <div className="row">
                              <div className="col-sm">

                                <div className="row" style={{marginTop:"0px"}}>
                                  <div>    
                                    <div className="rondorange">1
                                    </div>
                                  </div>
                                  <div >
                                      <div style={{minHeight:isBrowser?"20px":"0px", marginTop:"-40px", marginLeft:"30px"}}>
                                      <p style={{fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>L'éthique managériale</p>
                                      
                                      <span></span><br/>
                                      </div>
                                  </div>
                                </div>
                                <p style={{ fontSize:"20px", paddingLeft:"0px", color:"#9A9A9A", marginTop:"-20px"}}>Être juste, bienveillant et transparent pour offrir le meilleur service à tous.</p>

                              </div>

                              <div className="col-sm">

                                <div className="row" style={{marginTop:"0px"}}>
                                    <div>    
                                      <div className="rondorange">2
                                    
                                      </div>
                                    </div>
                                    <div >
                                        <div style={{minHeight:isBrowser?"20px":"0px", marginTop:"-40px", marginLeft:"30px"}}>
                                        <p style={{fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>La qualification</p>
                                        
                                        <span></span><br/>
                                        </div>
                                    </div>
                                  </div>
                                  <p style={{ fontSize:"20px", paddingLeft:"0px", color:"#9A9A9A", marginTop:"-20px"}}>NannyLou recrute uniquement des professionnel(le)s agréé(e)s, diplômé(e)s, expérimenté(e)s, certifié(e)s.</p>
                                </div>

                              <div className="col-sm">
                                <div className="row" style={{marginTop:"0px"}}>
                                    <div>    
                                      <div className="rondorange">3
                                    
                                      </div>
                                    </div>
                                    <div >
                                        <div style={{minHeight:isBrowser?"20px":"0px", marginTop:"-40px", marginLeft:"30px"}}>
                                        <p style={{fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>La transparence</p>
                                        
                                        <span></span><br/>
                                        </div>
                                    </div>
                                  </div>
                                  <p style={{ fontSize:"20px", paddingLeft:"0px", color:"#9A9A9A", marginTop:"-20px"}}>Les systèmes de tarifications et de notations des Nannies sont détaillés sur notre site.</p>
                                </div>

                            </div>

                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-sm">

                              <div className="row" style={{marginTop:"0px"}}>
                                  <div>    
                                    <div className="rondorange">4
                                    </div>
                                  </div>
                                  <div >
                                      <div style={{minHeight:isBrowser?"20px":"0px", marginTop:"-40px", marginLeft:"30px"}}>
                                      <p style={{fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Le bien-être de l'enfant</p>
                                      
                                      <span></span><br/>
                                      </div>
                                  </div>
                                </div>
                                <p style={{ fontSize:"20px", paddingLeft:"0px", color:"#9A9A9A", marginTop:"-20px"}}>C'est prendre soin de lui en assurant sa sécurité et son éveil.</p>
                              </div>

                              <div className="col-sm">

                              <div className="row" style={{marginTop:"0px"}}>
                                    <div>    
                                      <div className="rondorange">5
                                      </div>
                                    </div>
                                    <div >
                                        <div style={{minHeight:isBrowser?"20px":"0px", marginTop:"-40px", marginLeft:"30px"}}>
                                        <p style={{fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>La réactivité</p>
                                        
                                        <span></span><br/>
                                        </div>
                                    </div>
                                  </div>
                                  <p style={{ fontSize:"20px", paddingLeft:"0px", color:"#9A9A9A", marginTop:"-20px"}}>Le système de géolocalisation permet de trouver en un rien de temps la Nanny qu'il vous faut.</p>
                                </div>

                              <div className="col-sm">
                              <div className="row" style={{marginTop:"0px"}}>
                                      <div>    
                                        <div className="rondorange">6
                                        </div>
                                      </div>
                                      <div >
                                          <div style={{minHeight:isBrowser?"20px":"0px", marginTop:"-40px", marginLeft:"30px"}}>
                                          <p style={{fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>La politesse</p>
                                          
                                          <span></span><br/>
                                          </div>
                                      </div>
                                    </div>
                                    <p style={{ fontSize:"20px", paddingLeft:"0px", color:"#9A9A9A", marginTop:"-20px"}}>Chez NannyLou, la courtoisie envers les uns et les autres est de mise à chaque instant !</p>
                                </div>

                            </div>
                            
                          </div>

                          </div>

                          

                      </div>
                      </Fade>

                    </div>

                    <div style={{backgroundColor: "#E2F6FF",paddingBottom:"100px"}}>

                      <div className="container" style={{paddingTop:"50px"}}>

                        <div className="row">

                          <div class="col-sm-7">
                            <p style={{fontSize:"16px",color:"#8000FF", textAlign:"center"}}>Pour vos besoins de garde d'enfants !</p>
                            <p style={{fontSize:"40px",color:"black", textAlign:"center"}}>Qui se cache derrière NannyLou ?</p>
                            <MobileView>
                              <img  style={{marginBottom:"20px", width:"100%", height:"auto", borderRadius:"30px"}} src={this.state.lienimage + "equipeimage.png"} ></img>
                            </MobileView>
                            <p style={{fontSize:"20px",color:"#737373", textAlign:"left"}}>Rassemblés par la volonté de faciliter le quotidien des familles, les fondateurs Lilas, Adeline, Stacy, Mélissa et Franck ont décidé de mettre sur pied ce projet et de créer la société.</p>
                            <p style={{fontSize:"20px",fontWeight:"bold",color:"#737373", textAlign:"left"}}>NannyLou</p>
                            <p style={{fontSize:"20px",color:"#737373", textAlign:"left", marginTop:"-20px"}}>Nous sommes une équipe pleine de conviction, engagée, motivée et passionnée, avec un même objectif : vous aider dans le quotidien en vous proposant des nounous et en vous accompagnant pour vous faciliter la vie, tout en nous adaptant à vos emplois du temps parfois surchargés !</p>
                            <p style={{fontSize:"20px",color:"#737373", textAlign:"left"}}>Nous avons à cœur de développer une relation de confiance. Être proche de vous nous permet de comprendre vos attentes et d'y répondre au mieux pour trouver des solutions de garde à domicile rapidement ou dans l'urgence.</p>
                            <p style={{fontSize:"20px",color:"#737373", textAlign:"left"}}>Vos enfants ont besoin de repères simples et réguliers, soucieux de garantir un savoir-faire de qualité nécessaire aux métiers de la petite enfance, nous plaçons vos enfants au cœur de nos préoccupations. Parce qu'ils sont uniques, nous vous proposons un service sur-mesure qui respecte vos choix éducatifs et une collaboration dans la durée.</p>
                            <p style={{fontSize:"20px",color:"#737373", textAlign:"left"}}>Plus que jamais porté par des valeurs humaines, NannyLou est le fruit de notre vision, de notre mission et de notre ambition.</p>
                            <p style={{fontSize:"20px",fontWeight:"bold", color:"#737373", textAlign:"center"}}>Alors, êtes-vous prêt(e) à vous lancer?</p>
                            <button className='btncontact' style={{marginTop:"20px"}} onClick={(e) => {e.preventDefault(); window.location.href="/Contact";}}>Contacter NannyLou</button>
                          </div>

                          <div class="col-sm-5">
                            <BrowserView>
                              <img  style={{marginTop:"0px", width:"100%", height:"auto", borderRadius:"30px"}} src={this.state.lienimage + "equipeimage.png"} ></img>
                            </BrowserView>
                          </div>

                        </div>
                    
                      </div>

                    </div>

                                      

                </div>
                
          );
        }
    
}

export default Quisommesnous;
