/*eslint-disable no-unused-vars, ,no-console */
import React, {Component} from 'react';
import './App.css';
import Menu from './components/Menu';
import Accueil from './components/Accueil';
import Quisommesnous from './components/Quisommesnous';
import CGU from './components/CGU';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Parents from './components/Parents';
import Entreprises from './components/Entreprises';
import Nannies from './components/Nannies';
import Inscription from './components/Inscription';
import Login from './components/Login';
import Promotion from './components/Promotion';
import Profil from './components/Profil';
import TESTQCM from './components/TESTQCM';
import Paiementresult from './components/Paiementresult';
import QCMADMIN from './components/QCMADMIN';
import Listenounou from './components/Listenounou';
import Listeparent from './components/Listeparent';
import Listepaiement from './components/Listepaiement';
import Listecse from './components/Listecse';
import Carte from './components/Carte';
import Listeentreprise from './components/Listeentreprise';
import Listecommande from './components/Listecommande';
import Cse from './components/Cse';
import Dev from './components/Dev';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {isBrowser} from "react-device-detect";
import Zoom from 'react-reveal/Zoom';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            setShow: false,
            tokenvalue: '',
            reload: false,
            username: '',
            tokenid: '',
            notfound: false,
        };

    }


    render() {

        return (

            <div className='noselectdata'>
                <Router>
                    <div style={{position: "fixed", width: "100%", top: 0, zIndex: '1000'}}>
                        <Menu/>
                    </div>

                    <Switch>
                        <Route path="/Quisommesnous" >
                            <Quisommesnous />
                        </Route>
                        <Route path="/Parents" >
                            <Parents />
                        </Route>
                        <Route path="/Entreprises" >
                            <Entreprises />
                        </Route>
                        <Route path="/Nannies" >
                            <Nannies />
                        </Route>
                        <Route path="/CGU" >
                            <CGU />
                        </Route>
                        <Route path="/Contact" handler={Contact} >
                            <Contact />
                        </Route>
                        <Route path="/Inscription/:referralid">
                            <Inscription />
                        </Route>
                        <Route path="/Inscription" >
                            <Inscription />
                        </Route>
                        <Route path="/QUIZ/:mailuser" >
                            <TESTQCM />
                        </Route>
                        <Route path="/QCMADMIN" >
                            <QCMADMIN />
                        </Route>
                        <Route path="/Login" >
                            <Login />
                        </Route>
                        <Route path="/Promotion" >
                            <Promotion />
                        </Route>
                        <Route path="/Profil" >
                            <Profil />
                        </Route>
                        <Route path="/Paiementresult/:datapaiement">
                            <Paiementresult />
                        </Route>
                        <Route path="/Listenounou" >
                            <Listenounou />
                        </Route>
                        <Route path="/Listeparent" >
                            <Listeparent />
                        </Route>
                        <Route path="/Listeentreprise">
                            <Listeentreprise />
                        </Route>
                        <Route path="/Listepaiement">
                            <Listepaiement />
                        </Route>
                        <Route path="/Listecse" >
                            <Listecse />
                        </Route>
                        <Route path="/Listecommande">
                            <Listecommande />
                        </Route>
                        <Route path="/Carte" >
                            <Carte />
                        </Route>
                        <Route path="/Cse" >
                            <Cse />
                        </Route>
                        <Route path="/Cse/:cseid" >
                            <Cse />
                        </Route>
                        <Route path="/Dev" >
                            <Dev />
                        </Route>
                        <Route path="/Accueil">
                            <Accueil />
                        </Route>
                        <Route path="/" exact>
                            <Redirect to="/Accueil"/>
                        </Route>
                        <Route path="*">
                            <div className="div404" style={{
                                backgroundImage: "url(" + process.env.PUBLIC_URL + './images/404.png' + ")",
                                backgroundPosition: 'center',
                                backgroundSize: isBrowser ? 'cover' : 'cover',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: isBrowser ? '65vh' : '65vh'
                            }}>

                                <Zoom left>
                                    <p style={{fontSize: isBrowser ? "5rem" : "2.5rem", marginTop: "100px"}}>La page est
                                        introuvable.</p>
                                </Zoom>

                            </div>
                        </Route>
                    </Switch>
                </Router>
                <Footer/>
            </div>
        );
    }
}

export default App;

