import './App.css';
import './moncss.css';
import React, {Component} from 'react';
import {Button, Form, Row, Col, Container} from 'react-bootstrap';
import axios from 'axios';
import {BrowserView, MobileView, isBrowser} from "react-device-detect";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import queryString from 'query-string';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import Loader from "react-js-loader";
import PhoneInput from 'react-phone-number-input';
import {API_URL} from '../constants';
import {isValidPhoneNumber} from 'react-phone-number-input'

var errocode = 0;

var dateselect = new Date();
var dateselectenfant = new Date();

class Inscription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lienimage: process.env.PUBLIC_URL + 'images/',
            idreferral: props.match?.params.referralid,
            nomcontact: '',
            mailcontact: '',
            titrecontact: '',
            messagecontact: '',
            isModalVisible: false,
            editorState: '',
            montant: 0.0,
            lng: 'FR',
            titre: '',
            usertype: 0,
            iduser: 0,
            codepostale: '',
            ville: '',
            adresse: '',
            nom: '',
            prenoms: '',
            nationalite: '',
            mail: '',
            motdepasse: '',
            societe: 0,
            telephone: '',
            poste: '',
            diplome: 0,
            datedenaissance: '',
            reference: 0,
            experience: '',
            permis: 0,
            vehicule: 0,
            typedegarde: 0,
            heuregarde: '',
            heuregardede: 0,
            heuregardea: 0,
            ismodalinscription: false,
            idtypeuser: 0,
            errorform: false,
            errormessage: '',
            listconnaissance: [],
            connaisancennl: 0,
            selectedFileCV: null,
            selectedFileCVitale: null,
            selectedFilediplome: null,
            selectedFilepermis: null,
            selectedFilecj: null,
            selectedFilePITJ: null,
            inscriptioneffectue: false,
            loadershow: false,
            prenomsenfant: '',
            datedenaissanceenfant: '',
            errorformenfant: false,
            errormessageenfant: '',
            idselectenfant: -1,
            signin: false,
            tokenuser: [],
            listtypegarde: [],
            listtypeutilisateur: [],
            cachelistenfant: [],
            listenfant: [],
            contratgarde: [],
            listtypegardeenfant: [],
            usercontratgarde: 1,
            userenfantgarde: 1,
            show_input: 'text',
            numerosiret: '',
            selectedFileURSSAF: null,
            selectedFileFichedeposte: null,
            selectedFileKBIS: null,
            offre: 0,
            newsletters: 0,
            validationformulaire: 0,
            cgu: 0,
            infosup: '',
        };

        this.selectusertypegarde = this.selectusertypegarde.bind(this);
        this.selectusertypegardenfant = this.selectusertypegardenfant.bind(this);
        this.selectusertypecontratgarde = this.selectusertypecontratgarde.bind(this);
        this.selectusertypeutilisateur = this.selectusertypeutilisateur.bind(this);
        this.selectconnaissance = this.selectconnaissance.bind(this);
        this.phonenumber = this.phonenumber.bind(this);

    }

    async componentDidMount() {

        registerLocale('fr', fr)

        document.title = "Inscription"

        if (this.state.lng === 'FR') {
            this.setState({
                titre: 'Allô Nanny',
            });
        } else {
            this.setState({
                titre: 'Allô Nanny',
            });
        }

        const responsetypegarde = await fetch(`${API_URL}/garde/selectallgarde.php`);
        const datatypegarde = await responsetypegarde.json();
        this.setState({listtypegarde: datatypegarde})

        const responsetypeutilisateur = await fetch(`${API_URL}/typeutilisateur/selectalltypeutilisateur.php`);
        const datatypeutilisateur = await responsetypeutilisateur.json();
        this.setState({listtypeutilisateur: datatypeutilisateur})

        const responselistconnaissanse = await fetch(`${API_URL}/connaissance/selectallconnaissance.php`);
        const datalistconnaissanse = await responselistconnaissanse.json();
        this.setState({listconnaissance: datalistconnaissanse})

        const responselistcontratgarde = await fetch(`${API_URL}/garde/selectallcontratgarde.php`);
        const datalistcontratgarde = await responselistcontratgarde.json();
        this.setState({listcontratgarde: datalistcontratgarde})

        const responselisttypegardeenfant = await fetch(`${API_URL}/garde/selectalltypegarde.php`);
        const datalisttypegardeenfant = await responselisttypegardeenfant.json();
        this.setState({listtypegardeenfant: datalisttypegardeenfant})

    };

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    phonenumber() {
        var x = document.getElementById("phonenumber").value;
        this.setState({telephone: x})
    }

    selectusertypegarde(e) {
        this.setState({typedegarde: e.target.value});
    }

    selectusertypegardenfant(e) {
        this.setState({userenfantgarde: e.target.value});
    }

    selectusertypecontratgarde(e) {
        this.setState({usercontratgarde: e.target.value});
    }

    selectusertypeutilisateur(e) {
        this.setState({idtypeuser: e.target.value});
    }

    selectconnaissance(e) {
        this.setState({connaisancennl: e.target.value});
    }


    selectdate = (datevalue) => {

        dateselect = new Date(datevalue);

        var moisdenaissance = '';

        if (parseInt(datevalue.getMonth()) < 9) {
            moisdenaissance = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
        } else {
            moisdenaissance = (parseInt(datevalue.getMonth()) + 1)
        }

        var jourdenaissance = '';
        if (parseInt(datevalue.getDate()) < 9) {
            jourdenaissance = '0' + (parseInt(datevalue.getDate()))
        } else {
            jourdenaissance = (parseInt(datevalue.getDate()))
        }
        var datenaissance = datevalue.getFullYear() + '-' + moisdenaissance + '-' + jourdenaissance;


        //alert('THIS new date : ' + datenaissance)
        this.setState({datedenaissance: datenaissance})

        //alert(moisdenaissance)
    }

    selectdateenfant = (datevalue) => {

        dateselectenfant = new Date(datevalue);

        var moisdenaissance = '';

        if (parseInt(datevalue.getMonth()) < 9) {
            moisdenaissance = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
        } else {
            moisdenaissance = (parseInt(datevalue.getMonth()) + 1)
        }

        var jourdenaissance = '';
        if (parseInt(datevalue.getDate()) < 9) {
            jourdenaissance = '0' + (parseInt(datevalue.getDate()))
        } else {
            jourdenaissance = (parseInt(datevalue.getDate()))
        }
        var datenaissance = datevalue.getFullYear() + '-' + moisdenaissance + '-' + jourdenaissance;


        this.setState({datedenaissanceenfant: datenaissance})
    }


    async verifieradresse() {
        if (parseInt(this.state.idtypeuser) < 2) {
            this.setState({errorform: true, errormessage: 'Veuillez sélectionner votre type d\'utilisateur.'});
            return;
        }

        if (this.state.nom == "" && this.state.prenoms == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre nom ou prénoms.'});

            return;

        }

        if (this.state.nationalite == "" && this.state.idtypeuser === '3') {

            this.setState({
                errorform: true,
                errormessage: 'Veuillez saisir votre nationalité ou votre titre de séjour français.'
            });

            return;
        }

        if (this.state.mail == "") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

                this.setState({
                    errorform: true,
                    errormessage: 'Email invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.'
                });

                return;

            }

        }

        if (this.state.motdepasse == "") {

            this.setState({errorform: true, errormessage: 'Mot de passe requis. Veuillez saisir votre mot de passe.'});

            return;

        }

        if (isValidPhoneNumber(this.state.telephone) === false) {

            this.setState({errorform: true, errormessage: 'Veuillez rectifier votre numéro de téléphone.'});

            return;
        }


        if (this.state.motdepasse.length < 8) {

            this.setState({errorform: true, errormessage: 'Le mot de passe devrait être au moins 8 caractères.'});

            return;

        }

        if (this.state.datedenaissance == "" && this.state.idtypeuser === '2') {
            this.setState({errorform: true, errormessage: 'Date de naissance invalide.'});
            return;
        }

        if (this.state.telephone == "") {
            this.setState({errorform: true, errormessage: 'Veuillez saisir votre numero de téléphone.'});
            return;
        }

        if (this.state.codepostale == "") {
            this.setState({errorform: true, errormessage: 'Veuillez saisir votre code postale.'});
            return;
        }

        if (this.state.ville == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre ville.'});

            return;

        }

        if (this.state.adresse == "") {

            this.setState({errorform: true, errormessage: 'Veuillez saisir votre adresse.'});

            return;

        }

        if (this.state.idtypeuser === '2') {
            if (!this.state.experience) {

                this.setState({errorform: true, errormessage: 'Veuillez saisir votre expérience.'});

                return;

            }

            if (!this.state.typedegarde) {
                this.setState({errorform: true, errormessage: 'Veuillez saisir votre temps de garde.'});
                return;
            }

            if (this.state.heuregarde.length === 0) {

                this.setState({errorform: true, errormessage: 'Veuillez saisir l\'heure de garde.'});

                return;

            }

            if (this.state.heuregarde.length === 0) {
                this.setState({errorform: true, errormessage: 'Veuillez saisir l\'heure de garde.'});
                return;
            }

            if (!this.state.selectedFilecj) {
                this.setState({errorform: true, errormessage: 'Veuillez fournir votre casier judiciaire.'});
                return;
            }

            if (!this.state.selectedFilePITJ) {
                this.setState({errorform: true, errormessage: 'Veuillez fournir une pièce d\'identité ou votre justification de titre de séjour.'});
                return;
            }

            if (!this.state.selectedFileCV) {
                this.setState({errorform: true, errormessage: 'Veuillez fournir votre CV.'});
                return;
            }
        } else if (this.state.idtypeuser === '3') {
            if (this.state.cachelistenfant.length === 0) {
                this.setState({errorform: true, errormessage: 'Veuillez entrer au moins une enfant.'});
                return;
            }

            if (this.state.infosup.length === 0) {

                this.setState({
                    errorform: true,
                    errormessage: 'Veuillez remplir l\'information supplémtaire sur la garde de l\'enfant.'
                });

                return;

            }

            if (this.state.listenfant.length === 0) {
                this.setState({errorform: true, errormessage: 'Veuillez entrer au moins une enfant.'});
                return;
            }

            if (this.state.userenfantgarde === 1) {

                this.setState({errorform: true, errormessage: 'Veuillez sélectionner le type de garde de l\'enfant.'});

                return;

            }

            if (this.state.usercontratgarde === 1) {

                this.setState({
                    errorform: true,
                    errormessage: 'Veuillez sélectionner le type de contrat de garde de l\'enfant.'
                });

                return;

            }
        }

        if (this.state.usercontratgarde == 1 && this.state.idtypeuser === '4') {

            this.setState({
                errorform: true,
                errormessage: 'Veuillez sélectionner le type de  contrat de garde de l\'enfant.'
            });

            return;

        }

        if (this.state.connaisancennl == 0) {

            this.setState({
                errorform: true,
                errormessage: 'Veuillez sélectionner parmi la liste de réseau dont vous nous avez connu.'
            });

            return;

        }

        if (Number(this.state.validationformulaire) == 0) {
            this.setState({errorform: true, errormessage: 'Veuillez accepter la validation du formulaire.'});
            return;
        }

        if (Number(this.state.newsletters) == 0) {
            this.setState({errorform: true, errormessage: 'Veuillez accepter la récéption des newsletters.'});
            return;
        }

        if (Number(this.state.cgu) == 0) {
            alert('Veuillez accepter les conditions générales d\'utilisation.');
            return;
        }

        this.setState({loadershow: true});

        await this.verifiecoordonnee();
    }

    async verifiecoordonnee() {

        var adressecomplet = 'https://api-adresse.data.gouv.fr/search/?q=' + this.state.codepostale.replaceAll(' ', '+') + this.state.ville.replaceAll(' ', '+') + this.state.adresse.replaceAll(' ', '+') + '&limit=1&autocomplete=0';

        try {
            const responsecoordonnee = await fetch(adressecomplet);
            const datalatlong = await responsecoordonnee.json();

            if (datalatlong.features.length === 0) {
                this.setState({loadershow: false});
                alert("Veuillez rectifier votre adresse, code postale et ville car il n'est pas reconnu.")
            } else {
                var latlong = JSON.stringify(datalatlong.features[0].geometry.coordinates).replace('[', '').replace(']', '');
                this.inscription(latlong);
            }
        } catch (e) {
            this.inscription();
        }
    }

    inscription(latlong = '') {
        const latlongsplit = latlong ? latlong.split(',') : [];
        var idref = 0;

        if (!this.state.idreferral || this.state.idreferral == '0') {
            idref = 0;
        } else {
            idref = parseInt(this.state.idreferral);
        }

        var dateinscription = new Date();

        var moisinscription = '';

        if (parseInt(dateinscription.getMonth()) < 9) {
            moisinscription = '0' + (parseInt(dateinscription.getMonth()) + parseInt(1))
        } else {
            moisinscription = parseInt(dateinscription.getMonth()) + parseInt(1)
        }

        var jourinscription = '';
        if (parseInt(dateinscription.getDate()) < 9) {
            jourinscription = '0' + (parseInt(dateinscription.getDate()) + parseInt(1))
        } else {
            jourinscription = parseInt(dateinscription.getDate())
        }
        var dateinscriptionsend = dateinscription.getFullYear() + '-' + moisinscription + '-' + jourinscription;

        if (this.state.idtypeuser === '2') {

            fetch(`${API_URL}/nounou/ajoutnounou.php`, {

                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: queryString.stringify({
                    Dateinscription: dateinscriptionsend,
                    Nomnounou: this.state.nom,
                    Prenomsnounou: this.state.prenoms,
                    Nationalite: this.state.nationalite,
                    Telephone: this.state.telephone,
                    Email: this.state.mail,
                    Adresse: this.state.adresse,
                    Latitude: latlongsplit[1] ?? null,
                    Longitude: latlongsplit[0] ?? null,
                    Codepostale: this.state.codepostale,
                    Ville: this.state.ville,
                    Datedenaissance: this.state.datedenaissance,
                    Diplome: Number(this.state.diplome),
                    Reference: Number(this.state.reference),
                    Experience: this.state.experience,
                    Permis: Number(this.state.permis),
                    Vehicule: Number(this.state.vehicule),
                    Garde: this.state.typedegarde,
                    Heurede: this.state.heuregardede,
                    Heurea: this.state.heuregardea,
                    Heuregarde: this.state.heuregarde,
                    Motdepassenounou: this.state.motdepasse,
                    connu: this.state.connaisancennl,
                    Idreferral: idref,
                    offre: Number(this.state.offre),
                    newsletters: Number(this.state.newsletters),
                    infosup: this.state.infosup,
                })

            })

                .then(res => {

                    errocode = res.status;

                    if (!res.ok) {

                        return res.json().then(json => {
                            throw json;
                        });

                    }

                    return res.json();

                })
                .then(data => {

                    if (JSON.stringify(data).replace(/\"/g, "") === 'Not') {
                        this.setState({errorform: true, errormessage: 'Erreur de données.'});

                        return;
                    }

                    if (JSON.stringify(data).replace(/\"/g, "") === 'Userfound') {
                        this.setState({
                            errorform: true,
                            errormessage: 'Utilisateur déjà enregistré.',
                            loadershow: false
                        });

                        return;
                    }

                    this.setState({
                        errorform: false,
                        errormessage: '',
                        usertype: 0,
                        loadershow: true,
                        iduser: parseInt(JSON.stringify(data).replace(/\"/g, "")),
                        nom: '',
                        prenoms: '',
                        nationalite: '',
                        mail: '',
                        motdepasse: '',
                        societe: 0,
                        telephone: '',
                        poste: '',
                        adresse: '',
                        codepostale: '',
                        ville: '',
                        permis: 0,
                        diplome: 0,
                        experience: '',
                        reference: 0,
                        vehicule: 0,
                        typedegarde: '',
                        heuregarde: '',
                        heuregardede: 0,
                        heuregardea: 0,
                        datedenaissance: dateselect,
                        inscriptioneffectue: false,
                        cachelistenfant: [],
                        listenfant: [],
                        idtypeuser: 0,
                    });

                    let iduser = parseInt(JSON.stringify(data).replace(/\"/g, ""));

                    if (this.state.selectedFilePITJ !== null) {
                        // Create an object of formData
                        const formDatapitj = new FormData();

                        // Update the formData object

                        formDatapitj.append(
                            "images",
                            this.state.selectedFilePITJ,
                            this.state.selectedFilePITJ.name,
                        );

                        formDatapitj.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutpitj.php`, formDatapitj)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFilePITJ: null,
                                    });

                                }

                            })

                    }


                    if (this.state.selectedFileCVitale !== null) {

                        // Create an object of formData
                        const formDatacvitale = new FormData();

                        // Update the formData object
                        formDatacvitale.append(
                            "images",
                            this.state.selectedFileCVitale,
                            this.state.selectedFileCVitale.name,
                        );

                        formDatacvitale.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutcvitale.php`, formDatacvitale)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileCVitale: null,
                                    });

                                }

                            })

                        //this.ajoutercvitale();

                    }

                    if (this.state.selectedFileCV !== null) {

                        // Create an object of formData
                        const formDatacv = new FormData();

                        // Update the formData object
                        formDatacv.append(
                            "images",
                            this.state.selectedFileCV,
                            this.state.selectedFileCV.name,
                        );


                        formDatacv.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutcv.php`, formDatacv)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileCV: null,
                                    });

                                }

                            })


                    }

                    if (this.state.selectedFilediplome !== null) {

                        // Create an object of formData
                        const formData = new FormData();

                        // Update the formData object
                        formData.append(
                            "images",
                            this.state.selectedFilediplome,
                            this.state.selectedFilediplome.name,
                        );

                        formData.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutdiplome.php`, formData)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFilediplome: null,
                                    });

                                }

                            })

                    }

                    if (this.state.selectedFilepermis !== null) {

                        // Create an object of formData
                        const formData = new FormData();

                        // Update the formData object
                        formData.append(
                            "images",
                            this.state.selectedFilepermis,
                            this.state.selectedFilepermis.name,
                        );

                        formData.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutpermis.php`, formData)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFilepermis: null,
                                    });

                                }

                            })

                    }

                    if (this.state.selectedFilecj !== null) {

                        // Create an object of formData
                        const formData = new FormData();

                        // Update the formData object
                        formData.append(
                            "images",
                            this.state.selectedFilecj,
                            this.state.selectedFilecj.name,
                        );

                        formData.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/nounou/ajoutcj.php`, formData)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {
                                    this.setState({
                                        selectedFilecj: null,
                                    });

                                }

                            })

                    }

                    this.fermermodalinscription();

                })

                .catch(err => {
                    alert("Erreur :" + errocode + ", " + err)
                    this.setState({tokenuser: err});
                });

        }

        if (this.state.idtypeuser === '3') {

            fetch(`${API_URL}/parent/ajoutparent.php`, {

                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: queryString.stringify({

                    Dateinscription: dateinscriptionsend,
                    Nomparent: this.state.nom,
                    Prenomparent: this.state.prenoms,
                    Nationalite: this.state.nationalite,
                    Telephone: this.state.telephone,
                    Email: this.state.mail,
                    Adresse: this.state.adresse,
                    Latitude: latlongsplit[0],
                    Longitude: latlongsplit[1],
                    Codepostal: this.state.codepostale,
                    Ville: this.state.ville,
                    Motdepasseparent: this.state.motdepasse,
                    Idreferral: idref,
                    connu: this.state.connaisancennl,
                    Contrat: this.state.usercontratgarde,
                    Garde: this.state.userenfantgarde,
                    offre: Number(this.state.offre),
                    newsletters: Number(this.state.newsletters),
                    infosup: this.state.infosup,
                })

            })

                .then(res => {

                    errocode = res.status;

                    if (!res.ok) {

                        return res.json().then(json => {
                            throw json;
                        });

                    }

                    return res.json();

                })
                .then(data => {

                    if (JSON.stringify(data).replace(/\"/g, "") === 'Userfound') {
                        this.setState({
                            errorform: true,
                            errormessage: 'Utilisateur déjà enregistré.',
                            loadershow: false
                        });

                        return;
                    }

                    if (JSON.stringify(data).replace(/\"/g, "") === 'Not') {
                        this.setState({errorform: true, errormessage: 'Erreur de données.'});
                        return;
                    }

                    if (this.state.cachelistenfant.length > 0) {

                        for (var i = 0; i < this.state.cachelistenfant.length; i++) {

                            fetch(`${API_URL}/parent/ajoutenfant.php`, {

                                method: 'post',
                                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                body: queryString.stringify({

                                    Typeparent: 'Parent',
                                    Idpar: parseInt(JSON.stringify(data).replace(/\"/g, "")),
                                    Prenom: this.state.cachelistenfant[i].prenomenfant,
                                    Datenaissance: this.state.cachelistenfant[i].datenaisanceenfant,
                                    Dateinscription: dateinscriptionsend,

                                })

                            })

                        }

                    }

                    this.setState({
                        errorform: false,
                        errormessage: '',
                        usertype: 0,
                        nom: '',
                        prenoms: '',
                        mail: '',
                        motdepasse: '',
                        societe: 0,
                        telephone: '',
                        poste: '',
                        cachelistenfant: [],
                        listenfant: [],
                        adresse: '',
                        codepostale: '',
                        ville: '',
                        idtypeuser: 0,
                        loadershow: false,
                        signin: true,
                        inscriptioneffectue: false,
                    });

                })
                .catch(err => {
                    alert("Erreur :" + errocode + ", " + err)
                    this.setState({tokenuser: err});
                });

        }

        if (this.state.idtypeuser === '4') {

            fetch(`${API_URL}/entreprise/ajoutentreprise.php`, {

                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: queryString.stringify({

                    Dateinscription: dateinscriptionsend,
                    Nomentreprise: this.state.nom,
                    Numerosiret: this.state.numerosiret,
                    Telephone: this.state.telephone,
                    Email: this.state.mail,
                    Adresse: this.state.adresse,
                    Latitude: latlongsplit[0],
                    Longitude: latlongsplit[1],
                    Codepostal: this.state.codepostale,
                    Ville: this.state.ville,
                    Motdepasseentreprise: this.state.motdepasse,
                    Idreferral: idref,
                    connu: this.state.connaisancennl,
                    Contrat: this.state.usercontratgarde,
                    Garde: this.state.userenfantgarde,
                    offre: Number(this.state.offre),
                    newsletters: Number(this.state.newsletters),
                    infosup: this.state.infosup,
                })

            })

                .then(res => {

                    errocode = res.status;

                    if (!res.ok) {

                        return res.json().then(json => {
                            throw json;
                        });

                    }

                    return res.json();

                })
                .then(data => {

                    if (JSON.stringify(data).replace(/\"/g, "") === 'Userfound') {
                        this.setState({
                            errorform: true,
                            errormessage: 'Utilisateur déjà enregistré.',
                            loadershow: false
                        });

                        return;
                    }

                    if (JSON.stringify(data).replace(/\"/g, "") === 'Not') {
                        this.setState({errorform: true, errormessage: 'Erreur de données.'});
                        return;
                    }

                    window.scrollTo(0, 0)

                    this.setState({
                        errorform: false,
                        errormessage: '',
                        usertype: 0,
                        nom: '',
                        numerosiret: '',
                        mail: '',
                        motdepasse: '',
                        societe: 0,
                        telephone: '',
                        poste: '',
                        cachelistenfant: [],
                        listenfant: [],
                        adresse: '',
                        codepostale: '',
                        ville: '',
                        idtypeuser: 0,
                        inscriptioneffectue: false,
                        iduser: parseInt(JSON.stringify(data).replace(/\"/g, "")),
                    });

                    let iduser = parseInt(JSON.stringify(data).replace(/\"/g, ""));

                    if (this.state.selectedFileKBIS !== null) {

                        // Create an object of formData
                        const formDatakbis = new FormData();

                        // Update the formData object
                        formDatakbis.append(
                            "images",
                            this.state.selectedFileKBIS,
                            this.state.selectedFileKBIS.name,
                        );

                        formDatakbis.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/entreprise/ajoutKBIS.php`, formDatakbis)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileKBIS: null,
                                    });

                                }

                            })

                        //this.ajouterKBIS();

                    }

                    if (this.state.selectedFileURSSAF !== null) {

                        // Create an object of formData
                        const formDataurssaf = new FormData();

                        // Update the formData object
                        formDataurssaf.append(
                            "images",
                            this.state.selectedFileURSSAF,
                            this.state.selectedFileURSSAF.name,
                        );

                        formDataurssaf.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/entreprise/ajoutURSSAF.php`, formDataurssaf)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileURSSAF: null,
                                    });

                                }

                            })

                        //this.ajouterURSSAF();

                    }

                    if (this.state.selectedFileFichedeposte !== null) {

                        // Create an object of formData
                        const formDatafdp = new FormData();

                        // Update the formData object
                        formDatafdp.append(
                            "images",
                            this.state.selectedFileFichedeposte,
                            this.state.selectedFileFichedeposte.name,
                        );

                        formDatafdp.append("iduser", iduser);

                        // Send formData object
                        axios.post(`${API_URL}/entreprise/ajoutFichedeposte.php`, formDatafdp)
                            .then(res => {

                                if (JSON.stringify(res.data).replace(/\"/g, "") !== 'OK') {
                                    alert(JSON.stringify(res.data));
                                    return;
                                } else {

                                    this.setState({
                                        selectedFileFichedeposte: null,
                                    });


                                }

                            })

                        //this.ajouterFichedeposte();

                    }

                    this.fermermodalinscription();

                })
                .catch(err => {
                    alert("Erreur :" + errocode + ", " + err)
                    this.setState({tokenuser: err});
                });

        }


    };

    onFileChangePITJ = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilePITJ: event.target.files[0],
            });

        }

    };

    onFileChangeCVitale = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileCVitale: event.target.files[0],
            });

        }

    };

    onFileChangecv = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileCV: event.target.files[0],
            });

        }

    };

    onFileChangediplome = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilediplome: event.target.files[0],
            });

        }

    };

    onFileChangepermis = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilepermis: event.target.files[0],
            });

        }

    };

    onFileChangecj = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFilecj: event.target.files[0],
            });

        }

    };

    onFileChangeURSSAF = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileURSSAF: event.target.files[0],
            });

        }

    };

    onFileChangeFichedeposte = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileFichedeposte: event.target.files[0],
            });

        }

    };

    onFileChangeKBIS = event => {

        if (event.target.files[0] !== null) {

            this.setState({
                selectedFileKBIS: event.target.files[0],
            });

        }

    };


    fermermodalinscription() {

        this.setState({
            loadershow: false,
            signin: true,
        })

    }

    selectson(prenomenfantupdate, datedenaissanceenfantselect) {

        var indexson = this.state.cachelistenfant.map(function (o) {
            return o.prenomenfant;
        }).indexOf(prenomenfantupdate);

        if (indexson > -1) {

            this.setState({
                prenomsenfant: prenomenfantupdate,
                datenaisanceenfant: datedenaissanceenfantselect,
                idselectenfant: indexson
            });

            dateselectenfant = new Date(datedenaissanceenfantselect);

        }

    }

    addson() {

        if (this.state.prenomsenfant == '') {
            this.setState({errorformenfant: true, errormessageenfant: 'Veuillez saisir le nom de l\'enfant.'});
            return
        }

        if (this.state.datedenaissanceenfant == '') {
            this.setState({
                errorformenfant: true,
                errormessageenfant: 'Veuillez selectionner la date de naissance de l\'enfant.'
            });
            return
        }

        for (var i = 0; i < this.state.cachelistenfant.length; i++) {

            if (this.state.cachelistenfant[i].prenomenfant === this.state.prenomsenfant && this.state.cachelistenfant[i].datenaisanceenfant === this.state.datedenaissanceenfant) {

                this.setState({errorformenfant: true, errormessageenfant: 'Enfant déja ajouté.'});
                return

            }

        }

        this.state.cachelistenfant.push({
            prenomenfant: this.state.prenomsenfant,
            datenaisanceenfant: this.state.datedenaissanceenfant
        })

        this.setState({errorformenfant: false, errormessageenfant: ''});

        this.refreshlistenfant();

    }

    deleteson(prenomenfantfind, datenaissanceenfantfind) {

        for (var i = 0; i < this.state.cachelistenfant.length; i++) {

            if (this.state.cachelistenfant[i].prenomenfant === prenomenfantfind && this.state.cachelistenfant[i].datenaisanceenfant === datenaissanceenfantfind) {

                this.state.cachelistenfant.splice(i, 1);
            }

        }

        this.refreshlistenfant();

    }

    updateson() {

        this.state.cachelistenfant[this.state.idselectenfant].prenomenfant = this.state.prenomsenfant;
        this.state.cachelistenfant[this.state.idselectenfant].datenaisanceenfant = this.state.datedenaissanceenfant;

        this.refreshlistenfant();

    }

    refreshlistenfant() {

        dateselectenfant = new Date();

        this.setState({
            listenfant: this.state.cachelistenfant,
            prenomsenfant: '',
            datenaisanceenfant: '',
            idselectenfant: -1
        });

    }

    validation() {
        document.location.href = '/Login';
    }


    render() {
        return (
            <div style={{paddingTop: "100px", marginRight: "10px", paddingBottom: "60px"}}>
                {this.state.signin === false ?
                    <div>
                        {this.state.inscriptioneffectue === false ?
                            <div>
                                <BrowserView>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Vous êtes *</label>
                                                    <div>
                                                        <Form.Select style={{
                                                            height: '30px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '10px'
                                                        }} value={this.state.idtypeuser}
                                                                     onChange={this.selectusertypeutilisateur}>
                                                            {this.state.listtypeutilisateur.map((typ) => (
                                                                <option
                                                                    value={typ.idtypeutilisateur}>{typ.nomtypeutilisateur}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>

                                                {parseInt(this.state.idtypeuser) === 2 ?

                                                    <div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Nom</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.nom}
                                                                   onChange={(e) => this.setState({nom: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Prénom(s)</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.prenoms}
                                                                   onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                                        </div>

                                                    </div>

                                                    :

                                                    null

                                                }

                                                {parseInt(this.state.idtypeuser) === 3 ?

                                                    <div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Nom</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.nom}
                                                                   onChange={(e) => this.setState({nom: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Prénom(s)</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.prenoms}
                                                                   onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Nationalité ou titre de séjour français *</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.nationalite}
                                                                   onChange={(e) => this.setState({nationalite: e.target.value})}/>
                                                        </div>

                                                    </div>

                                                    :

                                                    null

                                                }

                                                {parseInt(this.state.idtypeuser) === 4 ?

                                                    <div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Nom</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.nom}
                                                                   onChange={(e) => this.setState({nom: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Numero SIRET</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.numerosiret}
                                                                   onChange={(e) => this.setState({numerosiret: e.target.value})}/>
                                                        </div>

                                                    </div>

                                                    :

                                                    null

                                                }


                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Email *</label>
                                                    <input type="email" className="form-control"
                                                           aria-describedby="emailHelp" value={this.state.mail}
                                                           onChange={(e) => this.setState({mail: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Mot de passe *</label>
                                                    <input type="password" className="form-control"
                                                           value={this.state.motdepasse}
                                                           onChange={(e) => this.setState({motdepasse: e.target.value})}/>
                                                </div>

                                                {this.state.idtypeuser === '2' ?

                                                    <div className="form-group" style={{margin: '10px'}}>
                                                        <label>Date de naissance * </label>
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            selected={dateselect}
                                                            onChange={(date) => this.selectdate(date)}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            locale="fr"
                                                            dateFormat="dd/MM/yyyy"
                                                            dropdownMode="select"
                                                            withPortal
                                                        />
                                                    </div>

                                                    :

                                                    null

                                                }


                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Téléphone</label>

                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="FR"
                                                        value={this.state.telephone}
                                                        onChange={this.phonenumber}
                                                        id="phonenumber"
                                                    />

                                                    {/*<input type="text" className="form-control" value={this.state.telephone} onChange={(e) => this.setState({ telephone: e.target.value})} />*/}
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Adresse *</label>
                                                    <textarea rows={2} type="text" className="form-control"
                                                              value={this.state.adresse}
                                                              onChange={(e) => this.setState({adresse: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Code postale *</label>
                                                    <input type="text" className="form-control"
                                                           value={this.state.codepostale}
                                                           onChange={(e) => this.setState({codepostale: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Ville *</label>
                                                    <input type="text" className="form-control" value={this.state.ville}
                                                           onChange={(e) => this.setState({ville: e.target.value})}/>
                                                </div>

                                            </Col>

                                            <Col>
                                                {this.state.idtypeuser === '3' ?
                                                    <div>
                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Prénom(s) de l'enfant *</label>
                                                            <input type="text" className="form-control"
                                                                   value={this.state.prenomsenfant}
                                                                   onChange={(e) => this.setState({prenomsenfant: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Date de naissance enfant *</label>
                                                            <DatePicker
                                                                maxDate={new Date()}
                                                                selected={dateselectenfant}
                                                                onChange={(date) => this.selectdateenfant(date)}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                locale="fr"
                                                                dateFormat="dd/MM/yyyy"
                                                                dropdownMode="select"
                                                                withPortal
                                                            />
                                                        </div>

                                                        {this.state.errorformenfant == true ?
                                                            <Alert severity="error">
                                                                <AlertTitle>Erreur</AlertTitle>
                                                                {this.state.errormessageenfant}
                                                            </Alert>
                                                            :
                                                            null
                                                        }


                                                        {this.state.idselectenfant !== -1 ?
                                                            <div className="d-flex justify-content-center">
                                                                <Button className="btn btn-success" style={{
                                                                    margin: '10px',
                                                                    width: '150px',
                                                                    borderRadius: '25px'
                                                                }} onClick={() => this.updateson()}>Modifier</Button>
                                                            </div>
                                                            :
                                                            <div className="d-flex justify-content-center">
                                                                <Button className="btn btn-success" style={{
                                                                    margin: '10px',
                                                                    width: '150px',
                                                                    borderRadius: '25px'
                                                                }} onClick={() => this.addson()}>Ajouter enfant</Button>
                                                            </div>
                                                        }

                                                        {this.state.listenfant.length > 0 ?
                                                            <div className="table">
                                                                <table className="table-responsive">
                                                                    <thead>
                                                                    <tr>
                                                                        <th style={{textAlign: 'left'}}>Prénoms</th>
                                                                        <th style={{textAlign: 'center'}}>Date de
                                                                            naissance
                                                                        </th>
                                                                        <th style={{textAlign: 'center'}}>Modifier</th>
                                                                        <th style={{textAlign: 'center'}}>Supprimer</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.listenfant.map((row) => (

                                                                        <tr>

                                                                            <td style={{textAlign: 'left'}}>{row.prenomenfant}</td>
                                                                            <td style={{textAlign: 'center'}}>{row.datenaisanceenfant}</td>
                                                                            <td style={{textAlign: 'center'}}>
                                                                                <button type="submit"
                                                                                        className="btn btn-primary btn-circle btn-xl"
                                                                                        style={{borderRadius: '25px'}}
                                                                                        onClick={() => this.selectson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                                    <span><EditIcon/></span></button>
                                                                            </td>
                                                                            <td style={{textAlign: 'center'}}>
                                                                                <button type="submit"
                                                                                        className="btn btn-danger btn-circle btn-xl"
                                                                                        style={{borderRadius: '25px'}}
                                                                                        onClick={() => this.deleteson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                                    <span><DeleteIcon/></span></button>
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {parseInt(this.state.idtypeuser) === 4 ?

                                                    <div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Attestation de compte à jour (URSSAF)</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangeURSSAF}/>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Fiche de poste</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangeFichedeposte}/>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>KBIS</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangeKBIS}/>
                                                        </div>

                                                    </div>

                                                    :

                                                    null

                                                }

                                                {this.state.idtypeuser === '2' ?

                                                    <div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Piece d'identité ou titre de séjour *</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangePITJ}/>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Carte vitale</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangeCVitale}/>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>CV *</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangecv}/>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Diplôme</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangediplome}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Expériences *</label>
                                                            <textarea rows={2} type="text" className="form-control"
                                                                      value={this.state.experience}
                                                                      onChange={(e) => this.setState({experience: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <Form.Group>
                                                                <Form.Check type={"checkbox"}>
                                                                    <Form.Check.Label>Référence</Form.Check.Label>
                                                                    <Form.Check.Input
                                                                        type={"checkbox"}
                                                                        defaultChecked={this.state.reference}
                                                                        onClick={(e) => {
                                                                            this.setState({reference: e.target.checked});
                                                                        }}
                                                                    />
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '25px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Permis de conduire</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangepermis}/>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <Form.Group>
                                                                <Form.Check type={"checkbox"}>
                                                                    <Form.Check.Label>Véhicule</Form.Check.Label>
                                                                    <Form.Check.Input
                                                                        type={"checkbox"}
                                                                        defaultChecked={this.state.vehicule}
                                                                        onClick={(e) => {
                                                                            this.setState({vehicule: e.target.checked});
                                                                        }}
                                                                    />
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Garde *</label>
                                                            <div>
                                                                <Form.Select style={{
                                                                    height: '30px',
                                                                    paddingTop: '0px',
                                                                    paddingLeft: '10px'
                                                                }} value={this.state.typedegarde}
                                                                             onChange={this.selectusertypegarde}>
                                                                    {this.state.listtypegarde.map((typ) => (
                                                                        <option
                                                                            value={typ.idgarde}>{typ.typegarde}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Heure de garde *</label>
                                                            <textarea rows={2} type="text" className="form-control"
                                                                      value={this.state.heuregarde}
                                                                      onChange={(e) => this.setState({heuregarde: e.target.value})}/>
                                                        </div>

                                                        <div className="form-group" style={{
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <label>Casier judiciaire *</label>
                                                            <input type="file"
                                                                   accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                                   className="form-control"
                                                                   onChange={this.onFileChangecj}/>
                                                            <a className="texte-clignote"
                                                               href="https://casier-judiciaire.justice.gouv.fr/"
                                                               target="_blank">Cliquez ici pour faire une demande de
                                                                casier judiciaire</a>
                                                        </div>

                                                    </div>

                                                    :

                                                    null

                                                }

                                                {this.state.idtypeuser === '3' ?

                                                    <div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Type de garde de l'enfant *</label>
                                                            <div>
                                                                <Form.Select style={{
                                                                    height: '30px',
                                                                    paddingTop: '0px',
                                                                    paddingLeft: '10px'
                                                                }} value={this.state.userenfantgarde}
                                                                             onChange={this.selectusertypegardenfant}>
                                                                    {this.state.listtypegardeenfant.map((tpe) => (
                                                                        <option
                                                                            value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Type de contrat de garde de l'enfant *</label>
                                                            <div>
                                                                <Form.Select style={{
                                                                    height: '30px',
                                                                    paddingTop: '0px',
                                                                    paddingLeft: '10px'
                                                                }} value={this.state.usercontratgarde}
                                                                             onChange={this.selectusertypecontratgarde}>
                                                                    {this.state.listcontratgarde.map((tce) => (
                                                                        <option
                                                                            value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>


                                                    </div>


                                                    :

                                                    null

                                                }

                                                {this.state.idtypeuser === '4' ?
                                                    <div>

                                                        {/*
                                      <div className="form-group" style={{margin:'10px'}}>
                                        <label >Type de garde de l'enfant</label>
                                          <div>
                                            <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.userenfantgarde} onChange={this.selectusertypegarde}>
                                              {this.state.listtypegardeenfant.map((tpe) => (
                                                <option value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                              ))}
                                            </Form.Select>
                                          </div>
                                      </div>
                                      */}

                                                        <div className="form-group" style={{margin: '10px'}}>
                                                            <label>Type de contrat de garde de l'enfant</label>
                                                            <div>
                                                                <Form.Select style={{
                                                                    height: '30px',
                                                                    paddingTop: '0px',
                                                                    paddingLeft: '10px'
                                                                }} value={this.state.usercontratgarde}
                                                                             onChange={this.selectusertypecontratgarde}>
                                                                    {this.state.listcontratgarde.map((tce) => (
                                                                        <option
                                                                            value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Informations
                                                        supplémentaires {parseInt(this.state.idtypeuser) !== 2 ? " concernant la garde de l'enfant *" : ""}</label>
                                                    <textarea rows={2} type="text" className="form-control"
                                                              value={this.state.infosup}
                                                              onChange={(e) => this.setState({infosup: e.target.value})}/>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <label>Comment avez-vous connu NannyLou?</label>
                                                    <div>
                                                        <Form.Select style={{
                                                            height: '30px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '10px'
                                                        }} value={this.state.connaisancennl}
                                                                     onChange={this.selectconnaissance}>
                                                            {this.state.listconnaissance.map((cns) => (
                                                                <option
                                                                    value={cns.idconnaissance}>{cns.nnlreseau}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>

                                                {parseInt(this.state.idtypeuser) === 2 ?

                                                    <div className="form-group" style={{margin: '10px'}}>
                                                        <Form.Group>
                                                            <Form.Check type={"checkbox"}>
                                                                <Form.Check.Label>Je consens à valider ce formulaire
                                                                    afin que ma candidature soit traitée par
                                                                    NannyLou.</Form.Check.Label>
                                                                <Form.Check.Input
                                                                    type={"checkbox"}
                                                                    defaultChecked={this.state.validationformulaire}
                                                                    onClick={(e) => {
                                                                        this.setState({validationformulaire: e.target.checked});
                                                                    }}
                                                                />
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </div>

                                                    :

                                                    <div className="form-group" style={{margin: '10px'}}>
                                                        <Form.Group>
                                                            <Form.Check type={"checkbox"}>
                                                                <Form.Check.Label>Je consens à valider ce formulaire
                                                                    afin que mon inscription soit traité par
                                                                    NannyLou.</Form.Check.Label>
                                                                <Form.Check.Input
                                                                    type={"checkbox"}
                                                                    defaultChecked={this.state.validationformulaire}
                                                                    onClick={(e) => {
                                                                        this.setState({validationformulaire: e.target.checked});
                                                                    }}
                                                                />
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </div>

                                                }

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <Form.Group>
                                                        <Form.Check type={"checkbox"}>
                                                            <Form.Check.Label>Je consens à recevoir des newsletters et
                                                                des communications de la part de
                                                                NannyLou.</Form.Check.Label>
                                                            <Form.Check.Input
                                                                type={"checkbox"}
                                                                defaultChecked={this.state.newsletters}
                                                                onClick={(e) => {
                                                                    this.setState({newsletters: e.target.checked});
                                                                }}
                                                            />
                                                        </Form.Check>
                                                    </Form.Group>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <Form.Group>
                                                        <Form.Check type={"checkbox"}>
                                                            <Form.Check.Label>Je consens à recevoir des offres
                                                                commerciales de vos partenaires (liés à la petite
                                                                enfance).</Form.Check.Label>
                                                            <Form.Check.Input
                                                                type={"checkbox"}
                                                                defaultChecked={this.state.offre}
                                                                onClick={(e) => {
                                                                    this.setState({offre: e.target.checked});
                                                                }}
                                                            />
                                                        </Form.Check>
                                                    </Form.Group>
                                                </div>

                                                <div className="form-group" style={{margin: '10px'}}>
                                                    <Form.Group>
                                                        <Form.Check type={"checkbox"}>
                                                            <Form.Check.Label>Je reconnais avoir pris connaissance et
                                                                j'accepte les conditions générales
                                                                d'utilisation.</Form.Check.Label>
                                                            <Form.Check.Input
                                                                type={"checkbox"}
                                                                defaultChecked={this.state.cgu}
                                                                onClick={(e) => {
                                                                    this.setState({cgu: e.target.checked});
                                                                }}
                                                            />
                                                        </Form.Check>
                                                    </Form.Group>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Container>

                                </BrowserView>

                                <MobileView>
                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Vous êtes *</label>
                                        <div>
                                            <Form.Select
                                                style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                value={this.state.idtypeuser} onChange={this.selectusertypeutilisateur}>
                                                {this.state.listtypeutilisateur.map((typ) => (
                                                    <option
                                                        value={typ.idtypeutilisateur}>{typ.nomtypeutilisateur}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    {parseInt(this.state.idtypeuser) === 2 ?
                                        <div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Nom</label>
                                                <input type="text" className="form-control" value={this.state.nom}
                                                       onChange={(e) => this.setState({nom: e.target.value})}/>
                                            </div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Prénom(s)</label>
                                                <input type="text" className="form-control" value={this.state.prenoms}
                                                       onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {parseInt(this.state.idtypeuser) === 3 ?
                                        <div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Nom</label>
                                                <input type="text" className="form-control" value={this.state.nom}
                                                       onChange={(e) => this.setState({nom: e.target.value})}/>
                                            </div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Prénom(s)</label>
                                                <input type="text" className="form-control" value={this.state.prenoms}
                                                       onChange={(e) => this.setState({prenoms: e.target.value})}/>
                                            </div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Nationalité ou titre de séjour français *</label>
                                                <input type="text" className="form-control"
                                                       value={this.state.nationalite}
                                                       onChange={(e) => this.setState({nationalite: e.target.value})}/>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {parseInt(this.state.idtypeuser) === 4 ?
                                        <div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Nom</label>
                                                <input type="text" className="form-control" value={this.state.nom}
                                                       onChange={(e) => this.setState({nom: e.target.value})}/>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Numero SIRET</label>
                                                <input type="text" className="form-control"
                                                       value={this.state.numerosiret}
                                                       onChange={(e) => this.setState({numerosiret: e.target.value})}/>
                                            </div>

                                        </div>
                                        :
                                        null
                                    }

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Email *</label>
                                        <input type="email" className="form-control" aria-describedby="emailHelp"
                                               value={this.state.mail}
                                               onChange={(e) => this.setState({mail: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Mot de passe *</label>
                                        <input type="password" className="form-control" value={this.state.motdepasse}
                                               onChange={(e) => this.setState({motdepasse: e.target.value})}/>
                                    </div>

                                    {this.state.idtypeuser === '2' ?
                                        <div className="form-group" style={{margin: '10px'}}>
                                            <label>Date de naissance * </label>
                                            <DatePicker
                                                maxDate={new Date()}
                                                selected={dateselect}
                                                onChange={(date) => this.selectdate(date)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                locale="fr"
                                                dateFormat="dd/MM/yyyy"
                                                dropdownMode="select"
                                                withPortal
                                            />
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Téléphone</label>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="FR"
                                            value={this.state.telephone}
                                            onChange={this.phonenumber}
                                            id="phonenumber"
                                        />
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Adresse *</label>
                                        <textarea rows={2} type="text" className="form-control"
                                                  value={this.state.adresse}
                                                  onChange={(e) => this.setState({adresse: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Code postale *</label>
                                        <input type="text" className="form-control" value={this.state.codepostale}
                                               onChange={(e) => this.setState({codepostale: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Ville *</label>
                                        <input type="text" className="form-control" value={this.state.ville}
                                               onChange={(e) => this.setState({ville: e.target.value})}/>
                                    </div>

                                    {parseInt(this.state.idtypeuser) === 4 ?

                                        <div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Attestation de compte à jour (URSSAF)</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangeURSSAF}/>
                                            </div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Fiche de poste</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control"
                                                       onChange={this.onFileChangeFichedeposte}/>
                                            </div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>KBIS</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangeKBIS}/>
                                            </div>

                                        </div>

                                        :

                                        null

                                    }

                                    {this.state.idtypeuser === '2' ?
                                        <div>
                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Piece d'identité ou titre de séjour *</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangePITJ}/>
                                            </div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Carte vitale</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangeCVitale}/>
                                            </div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>CV *</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangecv}/>
                                            </div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Diplôme</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangediplome}/>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Expériences *</label>
                                                <textarea rows={2} className="form-control"
                                                          value={this.state.experience}
                                                          onChange={(e) => this.setState({experience: e.target.value})}/>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <Form.Group>
                                                    <Form.Check type={"checkbox"}>
                                                        <Form.Check.Label>Référence</Form.Check.Label>
                                                        <Form.Check.Input
                                                            type={"checkbox"}
                                                            defaultChecked={this.state.reference}
                                                            onClick={(e) => {
                                                                this.setState({reference: e.target.checked});
                                                            }}
                                                        />
                                                    </Form.Check>
                                                </Form.Group>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <Form.Group>
                                                    <Form.Check type={"checkbox"}>
                                                        <Form.Check.Label>Permis</Form.Check.Label>
                                                        <Form.Check.Input
                                                            type={"checkbox"}
                                                            defaultChecked={this.state.permis}
                                                            onClick={(e) => {
                                                                this.setState({permis: e.target.checked});
                                                            }}
                                                        />
                                                    </Form.Check>
                                                </Form.Group>
                                            </div>

                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '25px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Permis de conduire</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangepermis}/>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <Form.Group>
                                                    <Form.Check type={"checkbox"}>
                                                        <Form.Check.Label>Véhicule</Form.Check.Label>
                                                        <Form.Check.Input
                                                            type={"checkbox"}
                                                            defaultChecked={this.state.vehicule}
                                                            onClick={(e) => {
                                                                this.setState({vehicule: e.target.checked});
                                                            }}
                                                        />
                                                    </Form.Check>
                                                </Form.Group>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Garde *</label>
                                                <div>
                                                    <Form.Select
                                                        style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                        value={this.state.typedegarde}
                                                        onChange={this.selectusertypegarde}>
                                                        {this.state.listtypegarde.map((typ) => (
                                                            <option value={typ.idgarde}>{typ.typegarde}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Heure de garde *</label>
                                                <textarea rows={2} type="text" className="form-control"
                                                          value={this.state.heuregarde}
                                                          onChange={(e) => this.setState({heuregarde: e.target.value})}/>
                                                {/*
                                            <div className="container" style={{marginTop:"20px", marginBottom:"20px"}}>
                                              <div className="row">
                                                <div className="col">De</div>
                                                  <div className="col"><input type="text" className="form-control" value={this.state.heuregardede} onChange={(e) => this.setState({ heuregardede: e.target.value})} style={{width:"70px",marginTop:"-5px", marginLeft:"-60px"}}/></div>
                                                  <div className="col">à</div><div className="col"><input type="text" className="form-control" value={this.state.heuregardea} onChange={(e) => this.setState({ heuregardea: e.target.value})} style={{width:"70px",marginTop:"-5px", marginLeft:"-60px"}}/></div>
                                                </div>
                                              </div>
                                            </div>
                                            */}
                                            </div>
                                            <div className="form-group" style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                <label>Casier judiciaire *</label>
                                                <input type="file"
                                                       accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                                                       className="form-control" onChange={this.onFileChangecj}/>
                                                <a className="texte-clignote"
                                                   href="https://casier-judiciaire.justice.gouv.fr/" target="_blank">Cliquez
                                                    ici pour faire une demande de casier judiciaire</a>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {this.state.idtypeuser === '3' ?
                                        <div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Prénom(s) de l'enfant *</label>
                                                <input type="text" className="form-control"
                                                       value={this.state.prenomsenfant}
                                                       onChange={(e) => this.setState({prenomsenfant: e.target.value})}/>
                                            </div>
                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Date de naissance enfant *</label>
                                                <DatePicker
                                                    maxDate={new Date()}
                                                    selected={dateselectenfant}
                                                    onChange={(date) => this.selectdateenfant(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    locale="fr"
                                                    dateFormat="dd/MM/yyyy"
                                                    dropdownMode="select"
                                                    withPortal
                                                />
                                            </div>
                                            {this.state.errorformenfant == true ?
                                                <Alert severity="error">
                                                    <AlertTitle>Erreur</AlertTitle>
                                                    {this.state.errormessageenfant}
                                                </Alert>
                                                :
                                                null
                                            }


                                            {this.state.idselectenfant !== -1 ?
                                                <div className="d-flex justify-content-center">
                                                    <Button className="btn btn-success" style={{
                                                        margin: '10px',
                                                        width: '150px',
                                                        borderRadius: '25px'
                                                    }} onClick={() => this.updateson()}>Modifier</Button>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-center">
                                                    <Button className="btn btn-success" style={{
                                                        margin: '10px',
                                                        width: '150px',
                                                        borderRadius: '25px'
                                                    }} onClick={() => this.addson()}>Ajouter enfant</Button>
                                                </div>
                                            }

                                            {this.state.listenfant.length > 0 ?
                                                <div className="table">
                                                    <table className="table-responsive">
                                                        <thead>
                                                        <tr>
                                                            <th style={{textAlign: 'left'}}>Prénoms</th>
                                                            <th style={{textAlign: 'center'}}>Date de naissance</th>
                                                            <th style={{textAlign: 'center'}}>Modifier</th>
                                                            <th style={{textAlign: 'center'}}>Supprimer</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.listenfant.map((row) => (

                                                            <tr>

                                                                <td style={{textAlign: 'left'}}>{row.prenomenfant}</td>
                                                                <td style={{textAlign: 'center'}}>{row.datenaisanceenfant}</td>
                                                                <td style={{textAlign: 'center'}}>
                                                                    <button type="submit"
                                                                            className="btn btn-primary btn-circle btn-xl"
                                                                            style={{borderRadius: '25px'}}
                                                                            onClick={() => this.selectson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                        <span><EditIcon/></span></button>
                                                                </td>
                                                                <td style={{textAlign: 'center'}}>
                                                                    <button type="submit"
                                                                            className="btn btn-danger btn-circle btn-xl"
                                                                            style={{borderRadius: '25px'}}
                                                                            onClick={() => this.deleteson(row.prenomenfant, row.datenaisanceenfant)}>
                                                                        <span><DeleteIcon/></span></button>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        null
                                    }

                                    {this.state.idtypeuser === '3' ?
                                        <div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Type de garde de l'enfant *</label>
                                                <div>
                                                    <Form.Select
                                                        style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                        value={this.state.userenfantgarde}
                                                        onChange={this.selectusertypegardenfant}>
                                                        {this.state.listtypegardeenfant.map((tpe) => (
                                                            <option
                                                                value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </div>

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Type de contrat de garde de l'enfant *</label>
                                                <div>
                                                    <Form.Select
                                                        style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                        value={this.state.usercontratgarde}
                                                        onChange={this.selectusertypecontratgarde}>
                                                        {this.state.listcontratgarde.map((tce) => (
                                                            <option
                                                                value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </div>


                                        </div>


                                        :

                                        null

                                    }

                                    {this.state.idtypeuser === '4' ?

                                        <div>

                                            {/*
                                          <div className="form-group" style={{margin:'10px'}}>
                                            <label >Type de garde de l'enfant</label>
                                              <div>
                                                <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.userenfantgarde} onChange={this.selectusertypegardenfant}>
                                                  {this.state.listtypegardeenfant.map((tpe) => (
                                                    <option value={tpe.idtypedegarde}>{tpe.typegardeenfant}</option>
                                                  ))}
                                                </Form.Select>
                                              </div>
                                          </div>
                                          */}

                                            <div className="form-group" style={{margin: '10px'}}>
                                                <label>Type de contrat de garde de l'enfant</label>
                                                <div>
                                                    <Form.Select
                                                        style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                        value={this.state.usercontratgarde}
                                                        onChange={this.selectusertypecontratgarde}>
                                                        {this.state.listcontratgarde.map((tce) => (
                                                            <option
                                                                value={tce.idtypecontrat}>{tce.typecontratgarde}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </div>


                                        </div>


                                        :

                                        null

                                    }

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Informations
                                            supplémentaires {parseInt(this.state.idtypeuser) !== 2 ? " concernant la garde de l'enfant *" : ""}</label>
                                        <textarea rows={2} type="text" className="form-control"
                                                  value={this.state.infosup}
                                                  onChange={(e) => this.setState({infosup: e.target.value})}/>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Comment avez-vous connu NannyLou?</label>
                                        <div>
                                            <Form.Select
                                                style={{height: '30px', paddingTop: '0px', paddingLeft: '10px'}}
                                                value={this.state.connaisancennl} onChange={this.selectconnaissance}>
                                                {this.state.listconnaissance.map((cns) => (
                                                    <option value={cns.idconnaissance}>{cns.nnlreseau}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <label>Informations supplémentaires</label>
                                        <textarea rows={2} type="text" className="form-control"
                                                  value={this.state.adresse}
                                                  onChange={(e) => this.setState({adresse: e.target.value})}/>
                                    </div>

                                    {parseInt(this.state.idtypeuser) === 2 ?

                                        <div className="form-group" style={{margin: '10px'}}>
                                            <Form.Group>
                                                <Form.Check type={"checkbox"}>
                                                    <Form.Check.Label>Je consens à valider ce formulaire afin que ma
                                                        candidature soit traitée par NannyLou.</Form.Check.Label>
                                                    <Form.Check.Input
                                                        type={"checkbox"}
                                                        defaultChecked={this.state.validationformulaire}
                                                        onClick={(e) => {
                                                            this.setState({validationformulaire: e.target.checked});
                                                        }}
                                                    />
                                                </Form.Check>
                                            </Form.Group>
                                        </div>

                                        :

                                        <div className="form-group" style={{margin: '10px'}}>
                                            <Form.Group>
                                                <Form.Check type={"checkbox"}>
                                                    <Form.Check.Label>Je consens à valider ce formulaire afin que mon
                                                        inscription soit traité par NannyLou.</Form.Check.Label>
                                                    <Form.Check.Input
                                                        type={"checkbox"}
                                                        defaultChecked={this.state.validationformulaire}
                                                        onClick={(e) => {
                                                            this.setState({validationformulaire: e.target.checked});
                                                        }}
                                                    />
                                                </Form.Check>
                                            </Form.Group>
                                        </div>

                                    }

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                                <Form.Check.Label>Je consens à recevoir des newsletters et des
                                                    communications de la part de NannyLou.</Form.Check.Label>
                                                <Form.Check.Input
                                                    type={"checkbox"}
                                                    defaultChecked={this.state.newsletters}
                                                    onClick={(e) => {
                                                        this.setState({newsletters: e.target.checked});
                                                    }}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                                <Form.Check.Label>Je consens à recevoir des offres commerciales de vos
                                                    partenaires (liés à la petite enfance).</Form.Check.Label>
                                                <Form.Check.Input
                                                    type={"checkbox"}
                                                    defaultChecked={this.state.offre}
                                                    onClick={(e) => {
                                                        this.setState({offre: e.target.checked});
                                                    }}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    </div>

                                    <div className="form-group" style={{margin: '10px'}}>
                                        <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                                <Form.Check.Label>Je reconnais avoir pris connaissance et j'accepte les
                                                    conditions générales d'utilisation.</Form.Check.Label>
                                                <Form.Check.Input
                                                    type={"checkbox"}
                                                    defaultChecked={this.state.cgu}
                                                    onClick={(e) => {
                                                        this.setState({cgu: e.target.checked});
                                                    }}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    </div>

                                </MobileView>
                            </div>

                            :

                            null

                        }


                        {this.state.errorform == true ?

                            <Alert severity="error">
                                <AlertTitle>Erreur</AlertTitle>
                                {this.state.errormessage}
                            </Alert>


                            :

                            null

                        }

                        {this.state.loadershow == true ?


                            <Loader type="spinner-cub" bgColor={'#FF7E5A'} title={"Enregistrement en cours ..."}
                                    color={'#FF7E5A'} size={100}/>
                            :
                            null
                        }
                        <div className="d-flex justify-content-center">

                            <Button className="btn" style={{
                                background: '#FF7E5A',
                                border: 0,
                                borderRadius: '8px',
                                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                height: 48,
                                marginTop: '20px',
                                marginBottom: '50px', margin: '10px', width: '150px'
                            }} onClick={() => this.verifieradresse()}>Inscription</Button>
                        </div>
                    </div>

                    :

                    <div className='App'>
                        <p style={{fontSize: isBrowser ? '1.5rem' : '1rem'}}>Votre inscription a bien été effectué.</p>
                        <p style={{fontSize: isBrowser ? '1.5rem' : '1rem'}}>Nous vous souhaitons la Bienvenue.</p>
                        <p style={{fontSize: isBrowser ? '1.5rem' : '1rem'}}>Vous allez bientôt recevoir un mail.</p>
                        <p style={{fontSize: isBrowser ? '1.5rem' : '1rem'}}>Merci de bien vérifier dans vos spams ou
                            courriers indésirables.</p>
                        <p style={{fontSize: isBrowser ? '1.5rem' : '1rem'}}>L'équipe NannyLou.</p>
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <Button className="btn" style={{
                            background: '#FF7E5A',
                            border: 0,
                            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                            height: 48,
                            width: 200,
                            borderRadius: '8px'
                        }} onClick={() => this.validation()} variant="success">OK</Button>
                    </div>

                }

            </div>

        );
    }

}

export default Inscription;
