import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import Fade from 'react-reveal/Fade';
import {isBrowser} from "react-device-detect";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import * as ls from "local-storage";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class Entreprises extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
      tarif:0.00,
      listtarif:[],
      lienimage: process.env.PUBLIC_URL +'images/',
    };
        
  }
    
  async componentDidMount() {

      document.title = "Pour les entreprises"
        
    };

    paiement = (prixnet,tariftype) => {  
      this.setState({ tarif: prixnet})

      var typeuser = ls.get('type');

      if (typeuser === null){

        alert("Veuillez-vous inscrire ou se connecter.");

        document.location.href = '/login';
       
      }

      ls.set('tariftype', tariftype);
      ls.set('montant', prixnet);
      ls.set('typeoffre', 'normal');

      if(tariftype == 'PONCTUEL'){
        window.open(this.state.listtarif.lienponctuel);
      }

      if(tariftype == 'PREMIUM'){
        window.open(this.state.listtarif.lienpremium);
      }

      if(tariftype == 'DELUXE'){
        window.open(this.state.listtarif.liendeluxe);
      }
      
    }
  
    componentWillMount() {
      window.scrollTo(0, 0);
    }
    
  render() {

          return (
            
              
                <div className="App" style={{paddingTop:isBrowser?"90px":"90px",overFlowX: "hidden", backgroundColor:"#FFF4FB"}}>

                    

                    <img src={this.state.lienimage + "entreprise-header.png"} style={{width:"100%", borderRadius:"0% 0% -50% -50%"}} className="imageentrepriseavantage"></img>

                    <div className="titlegardeentreprise" style={{marginTop:"-20px"}}>Garde d'enfants professionnels</div>

                    {/* 
                    <div style={{color:"black",fontSize:isBrowser?"32px":"20px", margin:"20px"}}>Si vous souhaitez proposer à vos collaborateurs, partenaires et/ou clients un service de garde d'enfants immédiat, fiable et en continu. Nous vous invitons à vous inscrire sur NannyLou, la plateforme de référence pour les services de garde d'enfants.</div>
                    <div style={{color:"black",fontSize:isBrowser?"32px":"20px", margin:"20px"}}>Grâce à un système de géolocalisation, NannyLou vous propose un réseau qualifié composé exclusivement de professionnels passionnés, expérimentés, agréés, diplômés et/ou certifiés à proximité de votre lieu de travail.</div>
                    <div style={{color:"black",fontSize:isBrowser?"32px":"20px", margin:"20px"}}>Vous pourrez une fois la prestation réalisée noter les Nannies sollicitées.</div>
                    */}
                    
                    <div  style={{color:"#6C6C6C", fontSize: "1.2em",textAlign:"left", marginLeft:"10%", marginRight:"10%", width:"80%"}}>
                        <p>Si vous souhaitez proposer à vos collaborateurs, partenaires et/ou clients un service de garde d'enfants immédiat, fiable et en continu. Nous vous invitons à vous inscrire sur NannyLou, la plateforme de référence pour les services de garde d'enfants.</p>
                        <p>Grâce à un système de géolocalisation, NannyLou vous propose un réseau qualifié composé exclusivement de professionnels passionnés, expérimentés, agréés, diplômés et/ou certifiés à proximité de votre lieu de travail.</p>
                        <p>Vous pourrez une fois la prestation réalisée noter les Nannies sollicitées.</p>
                    </div>

                    <div className={isBrowser?"cadreavantageentreprise":""}>

                      <Fade bottom>
                      {/*<div className="titleparents" style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"20px", textAlign:'center'}}>Vos avantages</div>*/}
                      <div className="avantagesparents">
                          <div className="container">

                        
                          <div className="container" style={{zIndex:9999}}>

                              <div className="row">

                                <div className="col-sm">

                                  <div className="row" style={{marginTop:"40px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#7B4EFB", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Recruter des professionnels diplômés, experimentés et certifiés.</p>
                                        <span></span><br/>
                                        </div>
                                    </div>
                                  </div>

                                  <div className="row" style={{marginTop:isBrowser?"20px":"0px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#7B4EFB", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Élargir votre secteur géographique via l'application.</p>
                                        </div>
                                    </div>

                                  </div>

                                  
                                </div>

                                <div className="col-sm">

                                  <div className="row" style={{marginTop:"40px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#7B4EFB", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"100px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Gagner du temps et de l'argent.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#7B4EFB", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Trouver une prestation de garde instantanément pour votre clientèle: imprévu, horaire décalé ou de nuit.</p>
                                        </div>
                                    </div>

                                  </div>

                                 
                                </div>

                              </div>

                          </div>
                          
                          </div>

                      </div>
                      </Fade>

                      </div>

                      <Fade bottom>

                        <div className="tarifsparentsdiv" >

                          <p className="tarifsparents" style={{color:"#003459", fontWeight:"500", fontSize:"32px", textAlign:'center'}}>Nos tarifs dépendent de vos besoins.</p>  
                          <p className="tarifsparents" style={{color:"#003459", fontWeight:"500", fontSize:"32px", textAlign:'center', marginTop:"-20px"}}>Merci de nous contacter par:</p> 
                          
                          <div>
                            <button className='btncontactinfo'style={{marginTop:"20px", width:isBrowser?"40%":"80%",textAlign:"center"}} onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@nannylou.com";}}><MailOutlineIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>contact@nannylou.com</button>
                          </div>

                          <div >
                            <button className='btncontactinfo 'style={{marginTop:"40px", width:isBrowser?"40%":"80%",textAlign:"center"}} onClick={(e) => {e.preventDefault(); window.location.href="tel:+330768755054";}}><PhonelinkRingIcon style={{fontSize:'20', marginRight:'20px', color:"#20A4DD"}}/>+33 07 68 75 50 54</button>
                          </div>

                          <div style={{marginBottom:"0px", backgroundColor:"#E2F6FF", height:"50px"}}>
                            
                          </div>
                          
                          {/*                  
                          <div className='listedata' >

                            <Card style={{ width: '20rem', height: isBrowser ? '700px' : '700px', borderRadius:'25px', margin:'25px' }} className="cardcolortext">                                               
                                                                                          
                            <Card.Body className="cardlist">
                                                    
                                <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"left", margin:"10px"}} >PONCTUEL</Card.Title>
                                <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px'}}>{this.state.listtarif.tarifentrepriseponctuel} €/demande</Card.Text>
                                
                                <div className='row text-white d-flex '>

                                    <p style={{fontSize:"20px", color:"black", textAlign:"left",margin:'10px'}} >Abonnement ponctuel (autonome)</p>
                                    <span></span><br/>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.listtarif.tarifentrepriseponctuel}€/demande</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>tarif horaire de la nounou (de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className='boutonpayer' onClick={() => this.paiement(this.state.listtarif.tarifentrepriseponctuel,'PONCTUEL')}>
                                        <p  className="textepayer" style={{textAlign:"center"}}>PAYER</p>
                                    </div>

                                </div>
                                
                              </Card.Body>

                            </Card>   

                            <Card style={{ width: '20rem', height: isBrowser ? '700px' : '700px', borderRadius:'25px', margin:'25px' }} className="cardcolortext">                                               
                                                                                          
                              <Card.Body className="cardlist">
                                                    
                              <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"left", margin:"10px"}}>PREMIUM</Card.Title>
                              <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px'}}>{this.state.listtarif.tarifentreprisestandard} €/mois</Card.Text>
                                
                                <div className='row text-white d-flex'>

                                    <p style={{fontSize:"20px", color:"black", textAlign:"left",margin:'10px'}}>Abonnement premium (accompagnement)</p>
                                    
                                    <span></span><br/>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.listtarif.tarifentreprisestandard}€/mois</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Engagement sur 12 mois</p>
                                          </div> 
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>tarif horaire de la nounou (de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Accompagnement et conseil sans administratif</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Suivi et remplacement nounou si besoin</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className='boutonpayer' onClick={() => this.paiement(this.state.listtarif.tarifentreprisestandard,'PREMIUM')}>
                                        <p  className="textepayer" style={{textAlign:"center"}}>PAYER</p>
                                    </div>
                                  
                                </div>
                                
                              </Card.Body>

                            </Card>   

                            <Card style={{ width: '20rem', height: isBrowser ? '700px' : '700px', borderRadius:'25px', margin:'25px' }} className="cardcolortext">                                               
                                                                                          
                              <Card.Body className="cardlist">
                                                    
                              <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"left", margin:"10px"}}>DELUXE</Card.Title>
                              <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px'}}>{this.state.listtarif.tarifentrepriseluxe} € /mois</Card.Text>
                                
                                <div className='row text-white d-flex '>
                                    
                                    <p style={{fontSize:"20px", color:"black", textAlign:"left",margin:'10px'}}>Abonnement de luxe (Nous faisons tout pour vous)</p>
                                    <span></span><br/>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.listtarif.tarifentreprisestandard}€/mois</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Engagement sur 12 mois</p>
                                          </div> 
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>tarif horaire de la nounou (de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Accompagnement et conseil avec administratif</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Suivi et remplacement nounou si besoin</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                      <div>
                                          
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Fiche de paye</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className='boutonpayer' onClick={() => this.paiement(this.state.listtarif.tarifentrepriseluxe,'DELUXE')}>
                                        <p  className="textepayer" style={{textAlign:"center"}}>PAYER</p>
                                    </div>
                                      
                                </div>
                                
                              </Card.Body>

                            </Card>   

                            </div>

                            */}

                          </div>

                        </Fade>

                </div>
                
          );
        }
    
}

export default Entreprises;
